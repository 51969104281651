import React from 'react';
//PAQUETERIAS
import NotificationSystem from 'react-notification-system';
import { useEffect, useMemo, useState, useRef} from 'react'
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { get_pos_sector_vtr, get_route_list, get_sectors } from '../../../services/businesspartners/businesspartners';
import AlertResponse from '../../../main_components/alerts/alertResponse';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import { update_null_identifiers } from '../../../main_components/methods_v2/auxiliar_funtion';
import { get_sales_in_real_time } from '../../../services/reports/reports';
import "./real_sales_mapbox.css";
import { BREADCRUMBS } from '../../../main_components/pagination/breadcrumbs';
import MODAL_TABLE from '../../../main_components/modal/modal_Table';
import { SEARCH_FILTERS } from './tools/searchfilter';
import { TABLES_SALES } from './tools/tables';
import { SYMBOLOGY_MAP } from './tools/symbol';
import {Autocomplete, TextField} from '@mui/material';

export const REAL_SALES_MAPBOX = () => {
      
    const [markers, setmarkers]=useState([]);
    const [markers2, setmarkers2]=useState([]);
    const [data, setdata]=useState([]);
    const [fecha, setfecha]=useState(null);
    const [routedata, setroutedata]=useState([]);
    const [open, setopen]=useState(false);
    const notificationSystemRef = useRef(); 
    const [modalT, setmodalT] = useState(false);
    const [total, settotal]=useState(0.0);
    const [total_rows, settotal_rows] = useState("");
    const [datasector, setdatasector]=useState([]);
    const [dataosect, setdataosect] = useState([]);
    const [idsec, setidsec] = useState(0);
    const [dataposs, setdataposs] = useState([]);
    const [datasv, setdatasv] = useState([]);
    const mapRef = useRef(null);
    const mapInstance = useRef(null);
    const [mapZoom, setMapZoom] = useState(13);
    const [mapCenter, setMapCenter] = useState([data[0]?.lng || -97.07318829119332, data[0]?.lat || 18.85020875064652]); // Centro inicial
    let addedMarkers = [];  
    const [idroute, setidroute] = useState("");
    const [bandera, setbandera] = useState(false);
    const [idpoint, setidpoint] = useState(null);
   
    

    useEffect(()=>{
        handleroute();
        sectors();
    },[]);
    
const update_identifiers =
[
  { ordenvisita: 'string' },
  { code: 'string' },
  { date: 'String' },
  { name: 'string' },
  { ticket: 'string' },
  { economic_number: 'string'},
  { scan: 'string' },
  { saletype: 'string' },
  // { marker: 'string' }
];

const update_identifiers2 =
[
  { code: 'string' },
  { name: 'string' },
  { lat: 'String' },
  { lng: 'String' }
];
const handleroute =async () => {
    try {
      var rf_token=await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_route_list();
            setroutedata(d === null ? [] : d);
            console.log(d);    
          }catch(err){
            console.log(err);
            alertas("Error al obtener información de rutas", false);
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    }
}
const sectors = async() =>{

  try{
    var rf_t = await refresh_token();
    if(rf_t){
      setTimeout(async() => {
        try{ 
          const d = await get_sectors();
          console.log(d);
          setdatasector(d?.data?.sectors);
          setdataosect(d?.data?.sectors);

        }catch(err){  
          console.log(err);
        }
      }, 1000);
    }
  }catch(err){
    console.log(err);
  }
}
function handlesumatotal(pos){
  var tt = 0;
  pos?.map((item)=>{
    item?.sales?.map((it)=>{
      tt = tt + it.total;
    })
  })
  settotal(tt);
}
const handleSubmit = async () => {

    setmodalT(true);
    setopen(true);
    setdata([]);
    setmarkers2([]);
    setmarkers([]);
    setdatasv([]);  
    setdataposs([]);
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {   
          try{
            var d = await get_sales_in_real_time(idroute, fecha?.format('YYYY-MM-DD'));
            const jsonordenado = d === null ? [] : d.data.points_of_sale.sort((a, b) => {
            handlesumatotal(d===null?[]:d.data.points_of_sale);
            const aDate = new Date(a.sales[0].date);
            const bDate = new Date(b.sales[0].date);
              return aDate - bDate;
            });
            const jsn = jsonordenado.flatMap(item => {
              return item.sales.map(sale => {
                return {
                  ...item,
                  sales: [sale],
                }
              })
            });
            const jsonew = jsn.map((item, index) => {
              return { ...item, ordenvisita: (index+1)};
            });
            var ndt = update_null_identifiers(jsonew, update_identifiers);
            newjson(ndt === null ? [] : ndt);
            setdata(ndt === null ? [] :ndt);
            setbandera(true);
            setmodalT(false);
            setopen(false);
          }catch(err){
            console.log(err);
            setopen(false);
            setdata([]);
            setmodalT(false);
            console.log("error al obtener data de rutas");
          }
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    } 
}
function newjson(n){
    const puntosventa = n.map(obj => {
      return { 
        code: obj.code,
        name: obj.name,
        lat: obj.lat, 
        lng: obj.lng, 
        ordenvisita: obj.ordenvisita,
        tipo:1,
      };
    });
    const allSales = n.reduce((acc, obj) => {
    return acc.concat(obj.sales.map(item =>
        ({ 
          ticket: item.ticket,
          name_pos: item.name_pos,
          lat: item.lat, 
          lng: item.lng, 
          code_pos: item.code_pos,
          ordenvisita: obj.ordenvisita,
          scan: item.scan,
          total: item.total,
          total_returns: item.total_returns,
          id_sale_type_fk: item.id_sale_type_fk,
          saletype: item.saletype,
          tipo: item.saletype==="NO VENTA" || item.saletype=== "OTRO"?3:(obj.cabinets.length===0?5:(item.scan===null||""?4:2)),
          cabinets: obj.cabinets,
          details: item.details,
        })));
    }, []);
    const result = puntosventa.concat(allSales);
    setMapCenter([result[0]?.lng ? result[0]?.lng : -97.07318829119332, result[0]?.lat ? result[0]?.lat : 18.85020875064652]);
    setmarkers(result);
    setmarkers2(result);
}

function alertas(msj,status){

    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
} 
const handlesubsect = async() => {
  if(idsec === 0 || idsec === null || idsec === ""){
    alertas("No ha seleccionado un Sector", false);
    return null;
  }
  try{
    setmodalT(true);
    var rff = await refresh_token();
    if(rff){
      setTimeout(async() => {
        try{
          const d = await get_pos_sector_vtr(idsec);
          setdataposs(d?.data?.points_of_sale);
          handlejoinmarker(d?.data?.points_of_sale);
          setmodalT(false);
        }catch(err){
          console.log(err);
          setmodalT(false);
        }
      }, 1000);
    }
  }catch(err){
    console.log(err);
    setmodalT();
  }
}
const handlejoinmarker = (dat) =>{

  const unmatchedPoints = dat.filter(itemDat => 
    !markers2.some(itemMarker => itemMarker.code === itemDat.code)
  );
   const transformedPoints = unmatchedPoints.map(item => ({
    code: item.code,
    lat: item.lat,
    lng: item.lng,
    name: item.name,
    ordenvisita: 0,
    tipo: 9 
  }));
  console.log(markers2);
  console.log(transformedPoints);
  const d = [...markers2, ...transformedPoints]
  setmarkers(d);
  setmarkers2(d);
  setdatasv(transformedPoints);
}

const zoom = (e, v)=>{
  const map = mapInstance.current;
  if(v===null){
    if (map) {
      map.flyTo({
        center: [data[0]?.lng || -97.07318829119332, data[0]?.lat || 18.85020875064652],
        zoom: 12,     
        speed: 1.2,          
        curve: 1.42          
      });
    }
  }else{
    if (map) {
      map.flyTo({
         center: [v?.lng, v?.lat],   
         zoom: 18,     
         speed: 1.2,          
         curve: 1.42          
      });
    } 
    
  }
 
}
  
  useEffect(() => {

    mapboxgl.accessToken = 'pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA';
    const map = new mapboxgl.Map({
      container: mapRef.current, 
      style: 'mapbox://styles/mapbox/streets-v12',
      center: mapCenter, 
      zoom: mapZoom
    })
    mapInstance.current = map; 
    
    map.on('moveend', () => {
      setMapCenter([map.getCenter().lng, map.getCenter().lat]);
      setMapZoom(map.getZoom());
    });
    

    map.on('load', async ()=>{
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.ScaleControl());
        if(data?.length===0){
            return null;    
        }
        markers.forEach(item=>{
          const popupContent = `
            <div style="font-size: 16px">
            ${
              item?.tipo === 1 || item?.tipo ===9 ?
              `<p>${item.code} - ${item.name}</p>`:
              `<p>Ticket: ${item.ticket}</p>`
            }
            </div>`;
          const marker =  new mapboxgl.Marker({
            
            color: item?.tipo === 1 ? "#e0a8ff":
                     item?.tipo === 3 ? "#ffecac": 
                        item?.tipo === 4 ? "#88b4dc":
                            item?.tipo === 5 ? "#ffbcb4":
                                item?.tipo === 9 ? "#5c5c5c":
                                    "#a1ffc9",
            scale: 1.3
          })
          .setLngLat([item?.lng, item?.lat])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
          .addTo(map)
          const labelElement = document.createElement('div');
          labelElement.textContent = item.ordenvisita;
          labelElement.className = 'marker-label'; 
          marker.getElement().appendChild(labelElement);
          addedMarkers.push(marker);
        });
      });
      return () => {
        map.remove();
      };
  }, [markers, data]);

    
    return (
        <div>
          <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
            <MODAL_TABLE
                open={true}
                message={""}
                modalGeneral={false}
                modalT={modalT}
              ></MODAL_TABLE> 

        <BREADCRUMBS niveles={
        [
          {label:"REPORTE VENTAS EN TIEMPO REAL", path: null}, 
        ]
      }
        cards_dashboard={true}
          total_rows={total_rows !== "" ? total_rows : 0}
          filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
          visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
          total={data?.length} 
      ></BREADCRUMBS>
      <div className='search-filters-realtime-sales'>
        <SEARCH_FILTERS
          fecha={fecha}
          setfecha={setfecha}
          idroute={idroute}
          setidroute={setidroute}
          routedata={routedata}
          handleSubmit={handleSubmit}
          datasector={datasector}
          setdatasector={setdatasector}
          idsec={idsec}
          setidsec={setidsec}
          dataosect={dataosect}
          markers={markers}
          setmarkers={setmarkers}
          markers2={markers2}
          datasv={datasv}
          handlesubsect={handlesubsect}
          total={total}
        ></SEARCH_FILTERS>
      </div>
      <div className='sales_in_realtime_mapbox'>
        <SYMBOLOGY_MAP
          data={data}
          datasv={datasv}
          setdata={setdata}
          markers={markers}
          setmarkers={setmarkers}
          markers2={markers2}
        ></SYMBOLOGY_MAP>
        <div className='input-search-points-map-89'>
            <Autocomplete
                id="second-warehouse-autocomplete"
                options={markers.filter(marker => marker.tipo == 9 || marker.tipo == 1)}
                getOptionLabel={(option) => option?.ordenvisita + "° " +option.code +" - "+ option.name }
                value={markers.find(wh => wh.code === idpoint) || null}
                onChange={(event, newValue) => {
                  zoom(event, newValue);
                  setidpoint(newValue ? newValue.code : null);
                }}
                style={{ width: "auto" }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Punto de Venta"
                    required
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    style={{borderRadius: "5px" }}
                  />
                )}
              />
        </div>
        <div className="marker-realtime-sales" ref={mapRef} style={{ height: '80vh', width: '100%' }} />      
      </div>
      <div className="TableSalesTimeReal">
        <TABLES_SALES 
          data={data}
          setdata={setdata}
          handleSubmit={handleSubmit}
          datasv={datasv}
          setdatasv={setdatasv}
          alertas={alertas}  
          setmodalT={setmodalT}
          newjson={newjson}
          markers={markers}
          setmarkers={setmarkers}
          markers2={markers2}
          handlejoinmarker={handlejoinmarker}
          dataposs={dataposs}
        ></TABLES_SALES>
      </div>
    </div>
  )
}
