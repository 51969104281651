import backgroundImage from "../../../imgComponents/patch/vacante.png";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const generatePDF = async (data) => {
  console.log(data);
  const content = document.createElement('div');
  content.style.position = 'absolute';
  content.style.top = '-9999px'; 
  content.style.width = '800px';
  content.style.minWidth = '800px';
  content.style.padding = '10px';
  content.style.margin = '0 auto';
  content.style.fontFamily = 'sans-serif';
  content.style.letterSpacing = '0.5px';
  content.innerHTML = `
    <div style="margin-top: 0px; width: 730px; padding-left: 300px; color: black">
      <p style="font-size: 16px; text-align: center;line-height: 1;">DISTRIBUIDORA AUTORIZADA "HOLANDA" SOLICITA:</p>
      <h1 style="font-weight: bold; font-size: 40px; text-align: center; line-height: 1; padding-bottom: 16px">
        ${data?.title}
      </h1>
      <p style="font-size: 16px;text-align: justify;"><strong>Descripción del puesto:</strong> ${data?.job_description}</p>
      <p style="font-size: 16px;"><strong>Requisitos:</strong></p>
      <ul>
        ${data?.requirements
          ?.split('\n')
          .map((req) => `<li style="font-size: 16px;">${req}</li>`)
          .join('')}
      </ul>
      <p style="font-size: 16px;"><strong>Beneficios:</strong></p>
      <ul>
        ${data?.benefits
          ?.split('\n')
          .map((benefit) => `<li style="font-size: 16px;">${benefit}</li>`)
          .join('')}
      </ul>
      <p style="font-size: 16px;"><strong>Contacto:</strong> ${data?.contact_info}</p>
    </div>
  `;

  document.body.appendChild(content);
  try {
    const canvas = await html2canvas(content, {
      scale: 2,
      backgroundColor: null
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(backgroundImage, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${data.title}_Job_Offer.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
  } finally {
    // Remover el contenedor del DOM
    document.body.removeChild(content);
  }
};