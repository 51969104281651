//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { get_supplier,delete_business_partner } from "../../../services/businesspartners/businesspartners";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//ESTILOS
// import "../componentsCFDI/estilo.css";
// import "./tableclientes.css";
// import "../componentsCFDI/modal.css";
//IMAGENES/ICONOS
import SPECIAL_ACTIONS from "./tools/special_actions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import SubTable_V2P from "../tools/subTable_v2";
import "./tableclientes.css";
import { update_null_identifiers } from "../../../main_components/methods_v2/auxiliar_funtion";

const SUPPLIER_v2 = () => {
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(1);
  const [isLoding, setIsLoding] = useState(false);
  const [menssage, setmenssage] = useState("Consultando proveedoes...");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [error_data, seterror_data] = useState([]);
  const [success_data, setsuccess_data] = useState([]);
  
  const [total_rows, settotal_rows] = useState("");

  const handle_supplier = async (msjAler) => {
    try {
      var dt=await get_supplier();
      setmodalT(false);
      var ndt = update_null_identifiers(dt?.data?.business_partner, update_identifiers);
      setData(ndt);
      setIsLoding(false);
      setmodalT(false);

      if (msjAler?.length > 1) {
        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse msj={msjAler} view={true}></AlertResponse>
          ),
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
      }
    } catch (err) {
      setData([]);
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={JSON.stringify(err.response.data)}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
      setIsLoding(false);
      
      setmodalT(false);
      
    }
  }
 
  useEffect(() => {
    handle_supplier();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handle_supplier(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };

  const getRowStyles = (row, position) => {
    if (error_data.includes(row.original.id)) {
      return {
        backgroundColor: 'rgb(216 115 115)',
        color: 'white',
        textAlign: position + " !important"
      };
    } else if (success_data.includes(row.original.id)) {
      return {
        backgroundColor: 'rgb(140 198 140)',
        color: 'white',
        textAlign: position + " !important"
      };
    }
    return {
      textAlign: "center !important"
    };
  };

  const isAnyRowPinned = (rows) => {
    return rows.some(row => row.getIsPinned());
  };

  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
      },
      {
        id: "business_partner",
        header: "Razón Social",
        accessorKey: "business_partner",
      },
      {
        id: "rfc",
        header: "RFC",
        accessorKey: "rfc",
      },
      {
        id: "business_partner_type",
        header: "Tipo de Socio",
        accessorKey: "business_partner_type",
        // Filter: SelectColumnFilter,
      },
      {
        id: "email",
        header: "Correo Electrónico",
        // Filter: SelectColumnFilter,
        accessorKey: "email",
        accessorFn: (d) => {
          return d.email?.length > 1 ? "SI" : "NO";
        },
      },
      {
        id: "credit_days",
        header: "Días de Crédito",
        accessorKey: "credit_days",
      },
      {
        id: "outer_code",
        header: "Código Externo",
        accessorKey: "outer_code",
      },
      {
        id: "account_number",
        header: "Cuenta",
        accessorKey: "account_number",
      },
      {
        id: "code_accounting_account",
        header: "Código de la cuenta contable",
        accessorKey: "code_accounting_account",
      },
    ],
    []
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  const update_identifiers =
[
  { code: 'string' },
  { business_partner: 'string' },
  { rfc: 'String' },
  { business_partner_type: 'string' },
  { email: 'string' },
  { credit_days: 'string'},
  { outer_code: 'string' },
  { account_number: 'string' },
  { code_accounting_account: 'string' }
];


  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <BREADCRUMBS
        niveles={[{ label: "CONSULTAR PROVEEDORES", path: null }]}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}  
      ></BREADCRUMBS>

      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div >
        <div >
          
          <div className="new_table_v2">
          <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              onStateChange={handleStateChange}
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{ showGlobalFilter: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
                      muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyRowProps={({ row, table }) => {
                const { density } = table.getState();
                const heightStyle = {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69}px`
                    : undefined,
                };
      
                const colorStyle = error_data.includes(row.original.id)
                  ? { backgroundColor: 'red', color: 'white' }
                  : success_data.includes(row.original.id)
                    ? { backgroundColor: 'green', color: 'white' }
                    : {};
      
                return {
                  sx: {
                    ...heightStyle,
                    ...colorStyle,
                  },
                };
              }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <SPECIAL_ACTIONS
                         handle_business_partner={handle_supplier}
                      ></SPECIAL_ACTIONS>
                    </div>
                  ),
                  enableHiding: true,
                  muiTableBodyCellProps: ({ row }) => ({
                    style: getRowStyles(row),
                  }),
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  muiTableBodyCellProps: ({ row }) => ({
                    style: getRowStyles(row),
      
                  }),
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  muiTableBodyCellProps: ({ row, table }) => {
                    settotal_rows(table)
                    const disableActions = isAnyRowPinned(table.getPrePaginationRowModel().rows);
                    return {
                      style: {
                        ...getRowStyles(row),
                        pointerEvents: disableActions ? 'none' : 'auto',
                        color: disableActions ? 'grey' : 'inherit',
                      },
                    };
                  },
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                  muiTableBodyCellProps: ({ row }) => ({
                    style: getRowStyles(row),
      
                  }),
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
               renderRowActionMenuItems={({ row, closeMenu }) => [
                 <RENDER_ROW_ACTION_MENU_ITEMS
                   closeMenu={closeMenu}
                   setmodalT={setmodalT}
                   setmenssage={setmenssage}
                   setmodalGeneral={setmodalGeneral}
                   row={row}
                   handleSubmit={handle_supplier}
                   setreconsult={setreconsult}
                 />
               ]}
               renderToolbarInternalActions={({ table }) => (
                 <RENDER_TOOLBAR_INTERNAL_ACTIONS
                   table={table} 
                   file_name={"Consultar Proveedores"}
                   settotal_rows={settotal_rows}
                   update_identifiers={update_identifiers}
                />
               )
               }
               renderDetailPanel={({ row }) => {
                return(
                  <SubTable_V2P id={row?.original?.id}></SubTable_V2P>
                  )     
              }}
              renderTopToolbarCustomActions={({ }) => (
                <div className="provee-table">
                  <div className="expen-1a" style={{ paddingTop: "10px" }}>
                    <select
                      id="canceladas-si-no"
                      onChange={(event) => setCancel(event.target.value)}
                    >
                      <option>Habilitados/Deshabilitados</option>
                      <option value={1}>Habilitados</option>
                      <option value={0}>Deshabilitados</option>
                    </select>
                  </div>

                  <div className="expen-2a">
                    <button className="button" onClick={handle_supplier} type="button">
                      Buscar
                    </button>
                  </div>
                </div> 
              )}
            />
          </div>
          {/* <div className="TableCOnsultaClientes">
            <TableContainer
              exportEx={true}
              columns={columns}
              data={data}
              nametable={"Tabla de Proveedores"}
              exportar={true}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SUPPLIER_v2;