import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomDialog } from "react-st-modal";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { pagination_v2 } from '../../../main_components/pagination/pagination_v2';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import GRAPH from "./pie_chart_with_customized_label"
import { get_routes } from '../../../services/cashclosing/cashclosing';

import { get_types_of_sales } from '../../../services/configuration/configuration';
import { ValidationTextField } from '../../../main_components/lable/labe';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
const SEARCH_FILTERS = (props) => {
  const [businesspartners_data, setbusinesspartners_data] = useState([]);
  const [pointsofsale_data, setpointsofsale_data] = useState([]);
  const [routes, setroutes] = useState([]);
  const [routes_data, setroutes_data] = useState([]);
  const [type_sales_data, settype_sales_data] = useState([]);
  const [options, setOptions] = useState([]);
  const [restriction_data, setrestriction] = useState(props?.restriction_dt);
  const [route_select, setroute_select] = useState(props?.select_route);
  const [typer_sale_select, settyper_sale_select] = useState(props?.select_vendor);
  const [id_shearch, setid_shearch] = useState(props?.id_shearch_dt);
  const [returns, setreturns] = useState(props?.sale_returns);


  console.log(
    {
      fi: props.fi,
      ff: props.ff,
      route: route_select,
      user: typer_sale_select,

    }
  )

  useEffect(() => {
    setid_shearch(props.id_shearch_dt)
    setrestriction(props.restriction_dt);
    setreturns(props.sale_returns)
  }, [props.id_shearch_dt, props.restriction_dt, props.sale_returns])



  const handle_get_routes = async (a, b) => {
    try {
      try {
        const dt = await get_routes();
        console.log(dt);
        const data = dt.data;
        console.log(data?.map(d => { return { route: d.route } }));
        setroutes(data?.map(d => { return { route: d.route } }));
        setroutes_data(data)
        console.log(data)
        console.log(data?.map(d => { return { users: d.users } })[0]?.users)
        settype_sales_data(data?.map(d => { return { users: d.users } })[0]?.users);
        settyper_sale_select(data?.map(d => { return { users: d.users } })[0]?.users[0]?.id);
        props?.setname_vendor(data?.map(d => { return { users: d?.users } })[0]?.users[0]?.fullusername)

        props?.setselect_vendor(data?.map(d => { return { users: d?.users } })[0]?.users[0]?.id);

        props?.setname_route(data[0]?.route)


      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setroutes([]);
    }
  };




  useEffect(() => {
    console.log(routes_data)
    console.log(routes_data.filter(d => d.route == route_select)[0]?.users)
    settype_sales_data(routes_data.filter(d => d.route == route_select)[0]?.users)
    settyper_sale_select(routes_data.filter(d => d.route == route_select)[0]?.users[0]?.id);
    props?.setselect_vendor(routes_data?.filter(d => d?.route == route_select)[0]?.users[0]?.id);
    props?.setname_vendor(routes_data?.filter(d => d?.route == route_select)[0]?.users[0]?.fullusername)
    console.log(routes_data?.filter(d => d?.route == route_select)[0]?.route)
    props?.setname_route(routes_data?.filter(d => d?.route == route_select)[0]?.route)
  }, [route_select])

  useEffect(() => {
    props?.setname_vendor(routes_data.filter(d => d.route == route_select)[0]?.users.filter((d) => d.id === typer_sale_select)[0].fullusername)
  }, [typer_sale_select])



  useEffect(() => {
    handle_get_routes();
    // handle_get_types_of_sales();
    // handle_businesspartners();
    // handle_pointsofsale();
  }, []);


 

  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Inicial"
          value={props.fi}
          format='DD/MM/YYYY'
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 135 }}
          label="Fecha Final"
          format='DD/MM/YYYY'
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>
      <FormControl sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Ruta</InputLabel>
        <Select
          label="Ruta"
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={route_select}
          sx={{ height: '35px' }}
          onChange={(e) => {
            setroute_select(e.target.value);
            props.setselect_route(e.target.value);

          }}
        >
          {routes?.map((route) => (
            <MenuItem key={route.route} value={route.route}>
              {route.route}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Vendedor</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          label="Vendedor"
          value={typer_sale_select}
          sx={{ height: '35px' }}
          onChange={(e) => {
            settyper_sale_select(e.target.value);
            props.setselect_vendor(e.target.value);

          }}
        >
          {type_sales_data?.map((sale) => (
            <MenuItem key={sale.id} value={sale.id}>
              {sale.fullusername}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', width: "100%" }}
            onClick={(e) => {
              props.handleSubmit()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>

      <FormControl required sx={{ width: 100 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='Filtrar por ventas'
            sx={{ width: "100%", backgroundColor: 'rgb(158, 90, 175)', fontSize: "11.5px", marginLeft: "5px" }}
            onClick={(e) => {
              props.handle_filter_product_sales()
            }}
          >
            <FilterAltIcon></FilterAltIcon>{props?.open ? "Todo" : "Ventas"}
          </Button>
        </Box>
      </FormControl>


    </Box>
  );
};

export default SEARCH_FILTERS;
