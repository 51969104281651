import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import "./map.css";
import ForkRightIcon from '@mui/icons-material/ForkRight';
import RouteIcon from '@mui/icons-material/Route';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import 'dayjs/locale/es-mx';import {
  MenuItem,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, TextField } from '@mui/material';


export const MAP_DELIVERIES = (props) => {
  
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const [data, setData] = useState([]);
  const [geometry_address, setgeometry_address] = useState([]);
  // const [type_dc, setype_dc]=useState("driving");
  const [type_dc, setype_dc]=useState("walking");
  const [anima, setanima]=useState(true);
  const [notvist, setnotvist]=useState(false);
  const [datap, setdatap] = useState([]);
  const [datar, setdatar] = useState([]);
  const [filter, setfilter] = useState("");
  const [idpoint, setidpoint] = useState(null);

  useEffect(() => {
    props?.data[0]?.p_details ?  ordenamiento(props?.data[0]?.p_details, "p") : setdatap([]);
    props?.data[0]?.r_details ?  ordenamiento(props?.data[0]?.r_details, "r") : setdatar([]);
  }, [props?.data])


  const parseTime = (time) => {
    const [hours, minutes] = time.split(":");
    return new Date(0, 0, 0, hours, minutes);
  };

  
  const ordenamiento = (d, t) =>{
    const sortedData = d
    .sort((a, b) => parseTime(a.hour) - parseTime(b.hour))
    .map((item, index) => ({
      ...item,
      color: t==="p"? "red": "blue",
      order: index + 1 
    }));
    t==="p"? setdatap(sortedData): setdatar(sortedData);
  }
  
  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA';
    const map = new mapboxgl.Map({
      container: mapRef.current, 
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [data[0]?.lng ? data[0]?.lng : -97.07318829119332, data[0]?.lat ? data[0]?.lat : 18.85020875064652], 
      zoom: 12
    })
    mapInstance.current = map; 
  
    if (filter === "" || filter === null || (datap?.length <= 0 && datar?.length <= 0)) {
      return;
    }
    map.on('load', async ()=>{
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.ScaleControl());
        
        var dp = [];
        if(filter === "preventa"){
          dp = datap;
        }else if(filter === "reparto"){
          dp = datar;
        }else if(filter === "ambos"){
            console.log("entra");
            dp = [...datap, ...datar];
        }

        get_coordenadas(filter==="preventa"? datap : datar);
        
        dp.forEach(item=>{
          const marker =  new mapboxgl.Marker({
            color: item?.color,
          })
          .setLngLat([item?.longitude, item?.latitude])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<div style="font-size: 16px"><p>${item.code_pos} - ${item.name_pos}</p><p>Hora: ${item.hour}</p></div>`))
          .addTo(map)
          const labelElement = document.createElement('div');
          labelElement.textContent = item.order;
          labelElement.className = 'marker-label'; 
          marker.getElement().appendChild(labelElement);
        });
        
      });
      return () => {
        map.remove();
      };
}, [datap, datar, filter]);

  
const get_coordenadas = (dt) =>{
  var urlcoordenadas = "";
  const lotes = chunkArray(dt, 24);
  const lotesConLoop = loopChunks(lotes);

  const ddd = Promise.all(lotesConLoop?.map(async(item)=>{
    urlcoordenadas = "";
    item.map((d, i)=>{    
      if(item.length-1 === i){
        urlcoordenadas = urlcoordenadas + `${d?.longitude}%2C${d?.latitude}`;
      }else{
        urlcoordenadas = urlcoordenadas + `${d?.longitude}%2C${d?.latitude}%3B`;
      }
    })
    const datageomey = await get_geometry(urlcoordenadas);  
    return datageomey;
  })).then(results => {
    return results.flat();
  });
  
  ddd.then(geometryData => {
    setgeometry_address(geometryData);
  });
  return ddd
};


function chunkArray(array, chunkSize) {
  const chunks = [];
   for (let i = 0; i < array.length; i += chunkSize) {
     chunks.push(array.slice(i, i + chunkSize));
   }
   return chunks;
}
function loopChunks(chunks) {
  for (let i = 1; i < chunks.length; i++) {
      const lastElement = chunks[i - 1][chunks[i - 1].length - 1]; 
      chunks[i].unshift(lastElement);
  }
  return chunks;
}
const get_geometry = async(d)=>{
  const url = `https://api.mapbox.com/directions/v5/mapbox/${type_dc}/${d}?alternatives=false&continue_straight=false&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA`;
  try {
     const response = await axios.get(url);
     const dtt = response?.data?.routes[0]?.geometry?.coordinates;
     return dtt;
   } catch (error) {
     console.log(error);
  }
}
const CustomActionMenu = () =>{
  return (
    <div className="controlss-pers controlss-pers-map-rec">
      <span title="Trazar Ruta"><ForkRightIcon className="button-icon-mapb-pers" onClick={()=>markers_normal()}></ForkRightIcon></span> 
      <span title="Seguimiento Ruta"><RouteIcon className="button-icon-mapb-pers2" onClick={()=>{anima===true?animacion():props?.alertas("Espera termine el proceso")}}></RouteIcon></span>  
      <span title="Eliminar Ruta"><DirectionsOffIcon className="button-icon-mapb-pers3" onClick={()=>delete_layer()}></DirectionsOffIcon></span> 
   </div>
  
  )
}
const delete_layer = () =>{
  const map = mapInstance.current;
  if (map && map.getStyle()) {
    const layerId = 'route'; 
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
    if (map.getSource(layerId)) {
      map.removeSource(layerId);
    }
  }
  setanima(true);
}
const markers_normal = () =>{
  const map = mapInstance.current;
  map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
               type: 'LineString',
               coordinates: geometry_address.map(item => [item[0], item[1]])
              }
            }
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
           'line-color': filter==="preventa"?'#ffb195':'#53c9fd',
            'line-width': 8
          }
       });
}
const animacion = ()=>{
  setanima(false);
  const map = mapInstance.current;
  if (map) {

    if (map.getLayer('route')) {
      map.removeLayer('route');
    }
    if (map.getSource('route')) {
      map.removeSource('route');
    }
    map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': filter==="preventa"?'#ffb195':'#53c9fd',
        'line-width': 8,
      },
    });

    const coordinates = geometry_address.map(item => [item[0], item[1]]);
    let i = 0;
    let animationFrameId;
    const drawLine = () => {
      if (i < coordinates.length) {
        const source = map.getSource('route');
        if (!source) return;
        const data = source._data;
        data.geometry.coordinates.push(coordinates[i]);
        source.setData(data);
        i++;
        animationFrameId = requestAnimationFrame(drawLine);
      } else {
        setanima(true);
      }
    };
    drawLine();    
  }
}

const zoom = (e, v)=>{

  const map = mapInstance.current;
  console.log(v);
  if(v===null){
    if (map) {
      map.flyTo({
        center: [-97.07318829119332, 18.85020875064652],
        zoom: 12,     
        speed: 1.2,          
        curve: 1.42          
      });
    }
  }else{
    if (map) {
      map.flyTo({
         center: [v?.longitude, v?.latitude],  
         zoom: 17,     
         speed: 1.2,          
         curve: 1.42          
      });
    } 
    
  }
 
}

let addedMarkers = [];  
const [markersnot, setmarkernot] = useState([]);

const point_not_visit_map = () =>{  
  if(props?.data[0]?.points_not_visit){
    const dp = props?.data[0]?.points_not_visit;
    console.log(dp);
    const map = mapInstance.current;
    dp.forEach(item => {
      const marker = new mapboxgl.Marker({
        color: "black",
      })
      .setLngLat([item?.longitude, item?.latitude])
      .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<div style="font-size: 16px"><p>${item.code} - ${item.name}</p></div>`
      ))
      .addTo(map);
      const labelElement = document.createElement('div');
      labelElement.className = 'marker-label'; 
      marker.getElement().appendChild(labelElement);

      addedMarkers.push(marker);
    })
    console.log(addedMarkers);
    setmarkernot(addedMarkers);
  }
}

const removeAddedMarkers = () => {

  console.log(markersnot);

  markersnot.forEach(marker => {
    marker.remove();
  });
  setmarkernot([]);
};

useEffect(() => {
  if(notvist===true){
    point_not_visit_map();
  }else{
    removeAddedMarkers();
  }
}, [notvist])




 return (
  <>
   <div className='cont-map-box'>
         
          <div className="control-pers-mapb">
              <CustomActionMenu></CustomActionMenu>
          </div>
          <div className='control-type'>
            {
              props?.multiple ?
              <FormControl sx={{ width: 150 }}>
                <InputLabel id="demo-simple-select-required-label" style={{marginLeft: "12px", marginTop: "10px"}}>Vista</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={props?.multimapa}
                    sx={{ height: '35px', m: 1.5}}
                    label="Vista"
                    onChange={(e) => {props?.setmultimapa(e.target.value)}}
                  >
                    <MenuItem key={1} value={false}>Individual</MenuItem>
                    <MenuItem key={2} value={true}>Multiple</MenuItem>
                  </Select>
              </FormControl>:<></>
            }
            <FormControl sx={{ width: 150 }}>
                <InputLabel id="demo-simple-select-required-label" style={{marginLeft: "12px", marginTop: "10px"}}>Tipo</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={filter}
                    sx={{ height: '35px', m: 1.5}}
                    label="Tipo*"
                    onChange={(e) => {setfilter(e.target.value); setnotvist(false);}}
                  >
                    <MenuItem key={1} value={"preventa"}>Preventa</MenuItem>
                    <MenuItem key={2} value={"reparto"}>Reparto</MenuItem>
                    {
                      props?.multimapa===true?<></>:<MenuItem key={3} value={"ambos"}>Ambos</MenuItem>  
                    }
                  </Select>
              </FormControl>
            <FormControl sx={{ width: 150 }}>
                <InputLabel id="demo-simple-select-required-label" style={{marginLeft: "12px", marginTop: "10px"}}>Sin Visitar</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={notvist}
                    disabled={filter===""?true:false}
                    sx={{ height: '35px', m: 1.5}}
                    label="Sin Visitar"
                    onChange={(e) => {setnotvist(e.target.value)}}
                  >
                    <MenuItem key={1} value={false}>NO</MenuItem>
                    <MenuItem key={2} value={true}>SI</MenuItem>
                
                  </Select>
              </FormControl>
              {
                props?.multimapa === false?
              <div className='autocomp-inpt-mapbox'>
              <Autocomplete
                id="second-warehouse-autocomplete"
                options={filter==="preventa"?datap:datar}
                getOptionLabel={(option) => option.order + "°  " + option.code_pos +" - "+ option.name_pos }
                value={(filter==="preventa"?datap:datar).find(wh => wh.id === idpoint) || null}
                onChange={(event, newValue) => {
                  zoom(event, newValue);
                  setidpoint(newValue ? newValue.id : null);
                }}
                style={{ width: 250 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Punto de Venta"
                    required
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    style={{borderRadius: "5px" }}
                  />
                )}
              />
            </div>:<></>
          }    
          </div>
         <div ref={mapRef} style={{ height: '80vh', width: '100%' }} /> 
    </div>
  </>
    
  )
}
