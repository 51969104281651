import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from "react";
export const CONFIGMAP = (props) => {

  useEffect(() => {
    if(props?.t==="normal"){
      props?.setpoint_initial("default");
    }
  }, [props?.t])

  return (
    <>
    <div className="forms">
        <div className="">
        <TextField
          select
          label="Tipo"
          type={props?.type}
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true, }}
          sx={{ m: 1.5, width: "95%" }}
          value={props?.t}
          onChange={(event) => {props?.sett(event.target.value)}}
        >
          <option key={1} value={"normal"}>
            {"Ruta Normal"}
          </option>
          <option key={2} value={"optima"}>
            {"Ruta Optima"}
          </option>
        </TextField>
        </div>
        <div>
          <TextField
            select
            value={props?.point_initial}
            disabled={props?.t==="normal"?true:false}
            label="Punto inicial"
            InputLabelProps={{ shrink: true, }}
            SelectProps={{ native: true, }}
            sx={{ m: 1.5, width: "95%" }}
            onChange={(event) => props?.setpoint_initial(event.target.value)}
          >
            <option key={1} value={"default"}>
              {"Por defecto"}
            </option>
            <option key={2} value={"fc"} >
              {"Fricongelados"}
            </option>
          </TextField>
        </div>
        <div>
          <TextField
            select
            value={props?.type_dc}
            label="Perfil enrutamiento"
            InputLabelProps={{ shrink: true, }}
            SelectProps={{ native: true, }}
            sx={{ m: 1.5, width: "95%" }}
            onChange={(event) => props?.setype_dc(event.target.value)}
          >
            <option key={1} value={"driving"}>
              {"Rutas automotrices"}
            </option>
            <option key={2} value={"walking"} >
              {"Rutas peatonales"}
            </option>
            <option key={3} value={"cycling"}>
              {"Rutas en bicicleta"}
            </option>
          </TextField>
        </div>
        <div className="save-seccion" title="Aplicar Cambios">
          <SaveIcon onClick={()=>props?.config_run(props.t, props?.point_initial)}></SaveIcon>
        </div>
    </div>
    </>
  )
}
