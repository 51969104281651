import { useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { get_cabinets_models, get_cabinets_warehouse, get_status_cabinets, post_cabinets, get_cabinets_brands } from "../../../services/assets/assets";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { PHOTO } from "./photo";
import { CustomDialog } from "react-st-modal";

function CreateFreez(props) {

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  


const dialog = useDialog();
const [bandera, setbandera]=useState(true);
//variables de congeladores
const [economic_number, seteconomic_number]=useState(null);
const [fixed_asset, setfixed_asset]=useState(null);
const [serial_number, setserial_number]=useState(null);
const [capitalization_date, setcapitalization_date]=useState(formattedDate);
const [note, setnote]=useState("");
const [id_warehouse, setid_warehouse]=useState(8);
const [id_cabinets_state, setid_cabinets_state]=useState(3);
const [id_cabinets_brand, setid_cabinets_brand]=useState(null);
const [id_cabinets_model, setid_cabinets_model]=useState(null);
console.log(id_cabinets_model);
const [lending, setlending]=useState(false);
const [production_year, setproduction_year]=useState(null);

const [datawarehouse, setdatawarehouse]=useState([]);
const [datamodels, setdatamodels]=useState([]);
const [datanamebrandmodels, setdatanamebrandmodels]=useState([]);

const [databrand, setdatabrand]=useState([]);

const [datastatus, setdatastatus]=useState([]);

useEffect(() => {
  hablealmacen();
  handlemodelos();
  handlestatus();
}, [])

const handlemodelos = async () => {
  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_cabinets_models();
          var d2 = await get_cabinets_brands();
          setdatamodels(d === null ? [] : d.data.models);
          setdatanamebrandmodels(d === null ? [] : d2.data.brands);
        }catch(err){
          setdatamodels([]);
        }     
      }, 1000);
    }else{}
  } catch (error) {
    console.error(error);
  }
}
const hablealmacen = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_cabinets_warehouse();
          setdatawarehouse(d === null ? [] : d.data.warehouses); 
        }catch(err){
          setdatawarehouse([]);
        }
                 
      }, 1000);
    }else{} 
  } catch (error) {
    console.error(error);
    
  }
}


const handlestatus = async () =>{
  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_status_cabinets();
          setdatastatus( d === null ? [] : d.data.cabinet_status); 
        }catch(err){
          setdatastatus([]);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.error(error);
  }
}


const createFreezzer = async () =>{


if(economic_number===null ||fixed_asset===null ||serial_number===null ||capitalization_date===null|| id_warehouse===null||
  id_cabinets_state===null ||id_cabinets_model===null ||production_year===null){
    props.alertas("Valores Faltantes", false);
    return null;
  }

  props.reconsultar(true);
  setbandera(false);

 var data = {
  "economic_number": economic_number, //String = numero economico
  "fixed_asset": fixed_asset, //String = numero de activo fijo
  "serial_number": serial_number, //String
  "capitalization_date": capitalization_date, // Date
  "note": note, //String | null
  "id_warehouse": parseInt(id_warehouse),//int
  "id_cabinets_state": parseInt(id_cabinets_state),//int | null   
  "id_cabinets_model": parseInt(id_cabinets_model),//int | null 
  "lending": lending==="true" || lending===true ? true : false,//Boolean = ¿Congelador prestado?   
  "production_year": production_year, //String
}

  try {  
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
        var d = await post_cabinets(data);
        console.log(d);
        props.alertas("Congelador creado con éxito", true);
        props.reconsultar(false);
        dialog.close();
        props.handleSubmit();
        }catch (err){
          catchErrorModul("Congeladores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
     
          console.log("error post", err);
          setbandera(true);
          props.reconsultar(false);
          if(err.response.status===500){
            props.alertas("No se pudo crear el congelador, vuelva a intertarlo", false);
          }else{
            props.alertas(JSON.stringify(err?.response?.data?.errorMessage), false);
          }
       } 
      }, 1000);
    }else{

    }
  }catch (error) {
     console.log("error post", error);
     setbandera(true);
    
  } 
} 


function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div style={{"textAlign":"center"}}>
      <div className="form-frezz">
          <div className="gridfrez1">
            <div className="frez1">
            <TextField
              label="Número Economico"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={economic_number}
              onChange={(event) => seteconomic_number(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="frez2">
            <TextField
              label="Número Activo Fijo"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={fixed_asset}
              onChange={(event) => setfixed_asset(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="frez3">
            <TextField
              label="Número Serie"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={serial_number}
              onChange={(event) => setserial_number(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridfrez2">
            <div className="frez4">
            <label className="label-date">Fecha Capitalización</label>
            <input
                  type="text"
                  className="fi" 
                  required
                  value={capitalization_date}
                  disabled={true}
                  style={{"width":"100%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                placeholder="Fecha"
                onChange={(event) => setcapitalization_date(event.target.value)}
            ></input>
            </div>
            <div className="frez5">
            <TextField
                  select
                  label="En comodato"
                  disabled={!bandera}
                  value={lending}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setlending(event.target.value)}  
                 >
                  <option key={1} value={false}>
                      {"No"}
                  </option>                 
                  <option key={2} value={true}>
                      {"SI"}
                  </option>
             </TextField>
            </div>
            <div className="frez6">
            <TextField
              label="Año de producción"
              required
              type="number"
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={production_year}
              onChange={(event) => setproduction_year(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridfrez3">
              <div className="frez7">
                  <TextField
                  select
                  label="Almacén"
                  value={id_warehouse}
                  disabled={true}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_warehouse(event.target.value)}  
                 >
                  {
                    datawarehouse?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.warehouse_name}
                  </option>
                    ))
                  }
             </TextField>
              </div>
              <div className="frez8">
                  <TextField
                  select
                  label="Estado Congelador"
                  value={id_cabinets_state}
                  disabled={true}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_state(event.target.value)}  
                 >
                 {
                    datastatus?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.description}
                       </option>
                    ))
                  }
             </TextField>
              </div>
              <div className="frez9">
                  <TextField
                  select
                  label="Marca Congelador"
                  disabled={!bandera}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => {setid_cabinets_brand(event.target.value);
                    console.log(event.target.value);
                    setdatabrand(datamodels.filter((d)=>{
                      console.log(d.id_brand_fk)
                      return d.name_brand===event.target.value}))
                    console.log(datamodels.filter((d)=>d.name===event.target.value))
                  }}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona una marca"}
                  </option>
                  {
                    datanamebrandmodels?.map(item=>(
                      <option key={item.name} value={item.name}>
                      {item.name}
                       </option>
                    ))
                  }
             </TextField>
              </div>

            
          </div>
          <div className="gridfrez4">
              <div className="frezn0">
                  <TextField
                  select
                  label="Modelo Congelador"
                  disabled={!bandera}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_model(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona Congelador"}
                  </option>
                  {
                    databrand?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.name_model}
                       </option>
                    ))
                  }
             </TextField>
              </div>
            <div className="frez10">
            <TextField
              label="Nota"
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={note}
              onChange={(event) => setnote(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            {/* <div className="frez111">
              
              <button 
                onClick={()=>{
                  // setshowcamara(true);
                  take_photo();
                }}
              >
                <CameraAltIcon></CameraAltIcon>
              </button>
            </div> */}
           
            {/* <div className="frez12">
            <TextField
                  select
                  label="send_to_dms"
                  disabled={!bandera}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setsend_to_dms(event.target.value)}  
                 >
                  <option key={1} value={false}>
                      {"No"}
                  </option>                 
                  <option key={2} value={true}>
                      {"SI"}
                  </option>
             </TextField>
            </div>
            <div className="frez13">
            <TextField
                  select
                  label="id_delivery_record_dms"
                  disabled={!bandera}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_delivery_record_dms(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Seleccione"}
                  </option>                 
             </TextField>
            </div> */}
          </div>
          <div className="grid-photos-web">
          {/* <div className="camera-component">
            
            <div>
              <canvas ref={canvasRef} width="320" height="240" style={{ display: "none" }} />
            </div>
            <div>
              <button onClick={startCamera}>Activar Cámara</button>
              <button onClick={takePhoto}>Tomar Foto</button>
            </div>
            {photo && (
              <div>
                <h3>Foto tomada:</h3>
                <img src={photo} alt="Captura" />
              </div>
            )}
          </div> */}
          </div>
            
           <Box>
            {
              bandera===true?
              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={createFreezzer}
          >
            Registrar
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "30ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateFreez;
