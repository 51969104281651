import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
} from '@mui/material';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';

const SEARCH_FILTERS = (props) => {
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Inicial"
          value={props?.fi}
          onChange={(newValue) => props.setfi(newValue)}
          format='DD/MM/YYYY'
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Final"
          value={props?.ff}
          onChange={(newValue) => props.setff(newValue)}
           format='DD/MM/YYYY'
        />
      </LocalizationProvider>
      <FormControl required sx={{ width: 100 }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => props.handleSubmit()}
        >
          CONSULTAR
        </Button>
      </FormControl>
    </Box>
  );
};

export default SEARCH_FILTERS;
