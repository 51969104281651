export const inf_waybill = [

    {
        label: "",
        accessor: "Serie",
        sm: 10,
        dfl: true,
        type: "select",
        data_select: [{id:1,name:"CPA", value:"CPA"},{id:2,name:"CPE", value:"CPE"}],
        name_select: "Tipo Serie",
    },

    {
        label: "",
        accessor: "Tansporte Internacional",
        sm: 10,
        dfl: true,
        type: "select",
        data_select: [{id:1,name:"SI", value:true},{id:2,name:"NO", value:false}],
        name_select: "Tansporte Internacional",
    },
    {
        label: "Relacionar",
        accessor: "enable",
        sm: 10,
        dfl: true,
        type: "button",
        data_select: [{id:1,name:"SI", value:true},{id:2,name:"NO", value:false}],
        name_select: "Tipo Select",
    },
    {
        label: "Buscar Socio de negocios",
        accessor: "businessparnert",
        sm: 20,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
    },
    {
        label: "RFC",
        accessor: "businessparnert2",
        sm: 10,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
    },

    
];



