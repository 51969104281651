import dayjs from 'dayjs';

export const updated_form_data = (name: any, value: any,formData: any) => {
    console.log(!isNaN(Number(value)))
    console.log(name)
    console.log(value)

    const update_form = {
        ...formData,
        [name]: value, 
        description: name === 'term' ? (Number(value) === 0 ? 'Contado' : `CRÉDITO ${Number(value)} DÍA${Number(value) > 1 ? 'S' : ''}`) : formData.description,
    };
    console.log(update_form)
    return update_form;
};

export const validate_data = (name: string, value: any) => {
    var error = '';

    switch (name) {
        case 'term':
            if (value === undefined || value === null) {
                error = 'El campo es obligatorio';
            } else if (value < 0) {                     
                error = 'El valor debe ser un número positivo';
            }
            
            break;
        // Puedes agregar más casos de validación aquí
    }

    return error;
};


export const processed_format = (formData: any) => {
    const dataToSubmit = { ...formData };
    delete dataToSubmit.id;  // Elimina la propiedad id


    Object.keys(dataToSubmit).forEach((key) => {
        switch (key) {
        case 'date_paid':
            // Formatear la fecha a "YYYY-MM-DD"
            dataToSubmit[key] = dayjs(dataToSubmit[key]).format('YYYY-MM-DD');
            break;

        case 'description':
            // Convertir la descripción a mayúsculas
            dataToSubmit[key] = dataToSubmit[key].toUpperCase();
            break;

        // Agrega más casos según las necesidades de tu formulario
        default:
            // No hacer nada si no es un campo que necesita procesamiento especial
            break;
        }
    });

    return dataToSubmit;
};