import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import axios from "axios";
import { useEffect, useRef, useState } from "react";

export const MAPBOX = (datam) => {

const [data, setdata]=useState(datam?.markers);
const mapRef = useRef(null);
const mapInstance = useRef(null);
const [agr, setagr] = useState(false);
const markersArray = [];
const [anima, setanima]=useState(true);

const ruta = () =>{
    const map = mapInstance.current;
    map.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                 type: 'LineString',
                 coordinates: geometry_address.map(item => [item[0], item[1]])
                }
              }
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
             'line-color': '#21BFE9',
              'line-width': 8
            }
         });
  }

useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA';
    const map = new mapboxgl.Map({
      container: mapRef.current, 
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [data[0]?.lng ? data[0]?.lng : -97.07318829119332, data[0]?.lat ? data[0]?.lat : 18.85020875064652], 
      zoom: 10
    })
    mapInstance.current = map; 

  if(data?.length>0){

    map.on('load', async ()=>{
      map.addControl(new mapboxgl.NavigationControl());
      map.addControl(new mapboxgl.FullscreenControl());
      get_coordenadas();
      data.forEach(item=>{
        const marker =  new mapboxgl.Marker({
           color: item.tipo===1 ? "#e1a3ff":(item.tipo===3?"#ffecac":(item.tipo===4?"#82b4db":(item.tipo===5?"#ffbcb3":"#a8fccc"))) ,
           // draggable: true,
         })
        .setLngLat([item?.lng, item?.lat])
         .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(`${item.code} - ${item.name}`))
         .addTo(map)
         const labelElement = document.createElement('div');
         labelElement.textContent = item.ordenvisita;
         labelElement.className = 'marker-label'; 
         marker.getElement().appendChild(labelElement);
       })
     });
    return () => {
      map.remove();
    };
  } 
}, [data]);

function chunkArray(array, chunkSize) {
  const chunks = [];
   for (let i = 0; i < array.length; i += chunkSize) {
     chunks.push(array.slice(i, i + chunkSize));
   }
   return chunks;
}
function loopChunks(chunks) {
  for (let i = 1; i < chunks.length; i++) {
      const lastElement = chunks[i - 1][chunks[i - 1].length - 1]; 
      chunks[i].unshift(lastElement);
  }
  return chunks;
}

const [geometry_address, setgeometry_address] = useState([]);

const get_coordenadas = () =>{

  var urlcoordenadas = "";
  const lotes = chunkArray(data, 24);
  const lotesConLoop = loopChunks(lotes);

  const ddd = Promise.all(lotesConLoop?.map(async(item)=>{
    urlcoordenadas = "";
    item.map((d, i)=>{    
      if(item.length-1 === i){
        urlcoordenadas = urlcoordenadas + `${d?.lng}%2C${d?.lat}`;
      }else{
        urlcoordenadas = urlcoordenadas + `${d?.lng}%2C${d?.lat}%3B`;
      }
    })
    const datageomey = await get_geometry(urlcoordenadas);  
    return datageomey;
  })).then(results => {
    return results.flat();
  });
  ddd.then(geometryData => {
    setgeometry_address(geometryData);
  })
};
const get_geometry = async(d)=>{
  const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${d}?alternatives=false&continue_straight=false&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA`;
  try {
     const response = await axios.get(url);
     const dtt = response?.data?.routes[0]?.geometry?.coordinates;
     return dtt;
   } catch (error) {
     console.log(error);
  }
}

const delete_layer = () =>{
  const map = mapInstance.current;
  if (map && map.getStyle()) {
    const layerId = 'route'; 
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
    if (map.getSource(layerId)) {
      map.removeSource(layerId);
    }
  }

  setanima(true);

}
const animacion = ()=>{
    setanima(false);
    const map = mapInstance.current;
    if (map) {
  
      if (map.getLayer('route')) {
        map.removeLayer('route');
      }
      if (map.getSource('route')) {
        map.removeSource('route');
      }
      map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [],
          },
        },
      });
      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#21BFE9',
          'line-width': 8,
        },
      });
  
      const coordinates = geometry_address.map(item => [item[0], item[1]]);
      let i = 0;
      let animationFrameId;
      const drawLine = () => {
        if (i < coordinates.length) {
          const source = map.getSource('route');
          if (!source) return; // Asegúrate de que la fuente existe antes de intentar acceder a sus datos
          const data = source._data;
          data.geometry.coordinates.push(coordinates[i]);
          source.setData(data);
          i++;
        animationFrameId = requestAnimationFrame(drawLine);  
        } else {
          setanima(true);
        }
      };
      drawLine();    
    }
  }
  

  return (
    <div>
        <button onClick={()=>ruta()}>Ruta</button>
        <button onClick={()=>animacion()}>Seguimiento</button>
        <div ref={mapRef} style={{ height: '80vh', width: '90%', margin: "0 auto"}} />  
    </div>
  )
}
