import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import "./component_route.css";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {Autocomplete, Checkbox, ListItemText, MenuItem, TextField} from '@mui/material';
import {Button} from '@mui/material';
import { get_route_list } from '../../services/businesspartners/businesspartners';
import { pagination } from '../../main_components/pagination/pagination';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import SearchIcon from '@mui/icons-material/Search';
import { TABLE } from './tools/table';
import CloseIcon from '@mui/icons-material/Close';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { SUMMARY } from './tools/sumary';
import NotificationSystem from 'react-notification-system';
import AlertResponse from '../../main_components/alerts/alertResponse';
import { update_null_identifiers } from '../../main_components/methods_v2/auxiliar_funtion';
import { SEACRH_FILTER } from './tools/search_filter';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';

export const COMPONENT_ROUTE = () => {

    const mapRef = useRef(null);
    const mapInstance = useRef(null);
    const [data, setdata] = useState([]);
    const [datasum, setdatasum] = useState([]);
    const [dataux, setdataaux] = useState([]);
    const [dataproc2, setdataproc2] = useState([]);
    const [idpoint, setidpoint] = useState(null);
    const [type_dc, setype_dc]=useState("walking");
    const [datapoints, setdatapoints] = useState([]);
    const [dataroute, setdataroute] = useState([]);
    const [listgroup, setlistgroup] = useState([]);
    const [listsectors, setlistsectors] = useState([]);
    const [listsectorsaux, setlistsectorsaux]= useState([]);
    const notificationSystemRef = useRef();
    const [geometry_address, setgeometry_address] = useState([]);
    const [type, settype] = useState("Filter");
    const [cash, setcash] = useState(true);
    const [year, setyear] = useState("");
    const [size, setsize] = useState(0);
    const [works_days, setworks_days] = useState(1);
    const [cardinal, setcardinal] = useState("nseo");
    const [points_fn_flag, setpoints_fn_flag] = useState(false);
    const [type_pos, settype_pos] = useState(["TODAS"]);
    const [routes, setroutes] = useState(["TODAS"]);  
    const [modalT, setmodalT] = useState(false);
    const [showmap, setshowmap] = useState(true);
    const [processtwo, setprocesstwo] = useState(false);
    const [mapZoom, setMapZoom] = useState(12);
    const [mapCenter, setMapCenter] = useState([data[0]?.lng || -97.07318829119332, data[0]?.lat || 18.85020875064652]); // Centro inicial
    let addedMarkers = [];  
    const [listselect, setlistselect] = useState([]);
    const [showsumm, setshowsumm] = useState(false);
    const [showgroupsymb, setshowgroupsymb] = useState(true);
    const [showsecsymb, setshowsecsym] = useState(true);

    var dord = listgroup?.sort((a, b) => a.group - b.group);
    var dords = listsectors?.sort((a, b) => a.sector - b.sector);


    useEffect(() => {
      handleroute();
      handlepoints();
    }, [])

    const handlepoints = async()=>{
      try{
        setmodalT(true);
        const d = await pagination(3, "pointofsales", null);
        setdatapoints(d);
        setmodalT(false);    
      }catch(err){
        setmodalT(false);
        console.log(err);
      }
    }
    const handleroute = async() =>{
      try{
        const d = await get_route_list();
        setdataroute(d?.data?.parametersList);
      }catch(err){
        console.log(err);
      }
    }
    
  const handlesubmit = async() =>{

    var r = "";
    var t = "";

    if(routes[0]==="TODAS"){
      r = null;
    }else{
      const listaOrdenada = routes.sort((a, b) => a - b);
      r = listaOrdenada.join(',');
    }

    if(type_pos[0]==="TODAS"){
      t = null;
    }else{
      t = type_pos.join(',');
    }


    const url = `https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com/development/pointsofsale/sectors-new-order`;
    try{

          setdata([]);
          setlistsectors([]);
          setlistsectorsaux([]);
          setlistgroup([]);
          setdataaux([]);
          setlistselect([]);

          setprocesstwo(false);
          setmodalT(true);
          const response = await axios.get(url, {
            params: {
              size: size.toString(),
              works_days: works_days.toString(),
              order_cardinal_points: cardinal,
              process_flag: true,
              points_fn_flag: points_fn_flag,
              bp_type: t,
              routes: r
            },
            headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
              },
          });
          setdataproc2(response?.data ? response?.data : []);
          join_points(response?.data ? response?.data : [], false);
          setmodalT(false);
      }catch(err){
          console.log(err);
          setmodalT(false);
      }
  }

  const join_points = (data, bandera) =>{
    const updatedData = data.map((item) => {
      const matchingPoint = datapoints.find(point => point.code === item.code);
      if (matchingPoint) {
        return {
          ...item, 
          name: matchingPoint.name,
          visit_days: matchingPoint.visit_days,
          frequency: matchingPoint.frequency,
          route: matchingPoint.route
        };
      }
      return item;
    });
    const colorCount = {};
    updatedData.forEach(item => {
      const color = item.color;
      const group = item.group;
      if (color && group) {
        if (colorCount[color]) {
          colorCount[color].count++;
        } else {
          colorCount[color] = {
            count: 1,
            group: group
          };
        }
      }
    });
    const colorObjects = Object.entries(colorCount).map(([color, { count, group }]) => ({
      color,
      count,
      group
    }));

    if(bandera===true){
      
      const sectorcount = {};
      updatedData.forEach(item => {
        const color_two = item.color_two;
        const sector = item.sector;
        const group = item.group;

        if (color_two && sector) {
          if (sectorcount[sector]) {
            sectorcount[sector].count++;
          } else {
            sectorcount[sector] = {
              count: 1,
              color_two: color_two,
              group: group
            };
          }
        }
      });
      const sectorObjects = Object.entries(sectorcount).map(([sector, { count, color_two, group }]) => ({
        color_two,
        count,
        sector,
        group
      }));

      console.log(sectorObjects);

      setlistsectors(sectorObjects);
      setlistsectorsaux(sectorObjects);
    }

    setlistgroup(colorObjects);
    setdata(updatedData);
    setdataaux(updatedData);
    setMapCenter([updatedData[0]?.lon || -97.07318829119332, updatedData[0]?.lat || 18.85020875064652]);
    
  }
    
  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA';
    const map = new mapboxgl.Map({
      container: mapRef.current, 
      style: 'mapbox://styles/mapbox/streets-v12',
      center: mapCenter, 
      zoom: mapZoom
    })
    mapInstance.current = map; 
    
    map.on('moveend', () => {
      setMapCenter([map.getCenter().lng, map.getCenter().lat]);
      setMapZoom(map.getZoom());
    });

    map.on('load', async ()=>{
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.ScaleControl());
        
        // get_coordenadas(data);

        if(data?.length===0){
            return null;    
        }

        data.forEach(item=>{

          const listsectorforgroup = listsectors.filter(it=>it.group ===item.group); 
          const popupContent = `
            <div style="font-size: 16px">
              <p>${item.code} - ${item.name}</p>
              <p><span>Ruta:</span> ${item.route ? item?.route : "N/A"}</p>
              <p><span>Día de Visita:</span> ${item.visit_days ? item?.visit_days : "N/A"}</p>
              <p><span>Frecuencia:</span> ${item.frequency ? item?.frequency : "N/A"}</p>
              <p><span>Grupo:</span> ${item.group}</p>
              <p><span>Sector:</span> ${item.sector ? item.sector : "N/A"}</p>
              <div class="save-config-points">
                <select id="grupo-select-${item.code}" style="height: 35px;">
                    <option value="all">${processtwo?"Seleccione Sector":"Seleccione Grupo"}</option>
                  ${processtwo? 
                    listsectorforgroup?.map((groupItem) =>
                      `<option value="${groupItem.sector}">${groupItem.sector}</option>`
                    ).join(''):
                    listgroup?.map((groupItem) => 
                      `<option value="${groupItem.color}">${groupItem.group}</option>`
                    ).join('')
                }
                </select>
                ${processtwo?
                  `<button class="button-ad-sector-map button-select-route-89" id="botonadd-${item.code}">
                    Nuevo
                  </button>`
                  :``
                }
                <button class="button-select-route-89" id="boton-${item.code}" style="margin-top: 10px;">Guardar</button>
              </div>
            </div>`;

          const marker =  new mapboxgl.Marker({
            color: processtwo ? item?.color_two : item?.color
          })
          .setLngLat([item?.lon, item?.lat])
          .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
          .addTo(map)
          const labelElement = document.createElement('div');
          labelElement.textContent = item.order;
          labelElement.className = 'marker-label'; 
          marker.getElement().appendChild(labelElement);

          let isEventAdded = false;
          let isEventAdded2 = false;

          marker.getPopup().on('open', () => {
            const button = document.getElementById(`boton-${item.code}`);
            const buttonadd = document.getElementById(`botonadd-${item.code}`);

            if (button && !isEventAdded) {  
              button.removeEventListener('click', handleButtonClick);
              button.addEventListener('click', () => {
                const selectedValue = document.getElementById(`grupo-select-${item.code}`).value;
                handleButtonClick(item, selectedValue, processtwo)
              });
              isEventAdded = true;
            }
            if (buttonadd && !isEventAdded2){
              buttonadd.removeEventListener('click', add_sector_new);
              buttonadd.addEventListener('click', ()=>{
                add_sector_new(item, processtwo)
              })
            }
            isEventAdded2 = true;

          });

          addedMarkers.push(marker);

        });
        const draw = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
            trash: true
          }
        });
        map.addControl(draw);
        map.on('draw.create', updateArea);
        map.on('draw.delete', updateArea);
        map.on('draw.update', updateArea);
        function updateArea(e) {
          const data = draw.getAll();
          if (data.features.length > 0) {
            agrup(data);
          }else{
            setvertices([]);
          }
        }
      });
      return () => {
        map.remove();
      };
  }, [data, dataux]);

//funcion para polygono
const[vertices, setvertices]=useState([]);
const agrup = (d) =>{
   console.log(d?.features[0]?.geometry?.coordinates[0]);
   var dat = d?.features[0]?.geometry?.coordinates[0]?.map(item=>{
        return {
            lat: item[1],
            lon: item[0]
        }
   });
   conjuntospp(dat);
}
function conjuntospp(dat){
  const filteredCoordinates = data.filter((item) => {
     return isPointInPolygon([item.lat, item.lon], dat);
  });
  console.log(filteredCoordinates);
  setvertices(filteredCoordinates);
} 

function isPointInPolygon(point, polygon) {
  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lat, yi = polygon[i].lon;
    const xj = polygon[j].lat, yj = polygon[j].lon;

    const intersect = ((yi >= point[1]) !== (yj >= point[1]))
      && (point[0] <= (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
    if (intersect) {
      isInside = !isInside;
    }
  }
  return isInside;
}
const handleButtonClick = async(item, select, band) => {
  if(band){
    const col = listsectors.filter(item=>item.sector === select);
    dataux?.map(i=>{
      if(i.code === item?.code){
        i.color_two = col[0].color_two;
        i.sector = col[0].sector;
      }
    });
    join_points(dataux, true);
  }else{
    const col = listgroup.filter(item=>item.color === select);
    dataux?.map(i=>{
      if(i.code === item?.code){
        i.color = select;
        i.group = col[0].group;
      }
    });
   join_points(dataux, false);
  } 
  
};

const add_sector_new = (item)=>{

  const colorPalette = [
    '#000000', '#800080', '#000080', '#0000FF', '#008080', '#00FFFF',
    '#008000', '#00FF00', '#FFFF00', '#800000', '#FF0000', '#808080', 
    '#fd53f8', '#faa437', '#fa3769', "#FFD633", "#D633FF", "#FFD6D6",
    "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#33FFF5", "#FF8C33",
    "#8C33FF", "#FF3333", "#33FF33", "#337FFF", "#FF33FF", "#33FFCC",
    "#FFB533", "#B533FF", "#FF6666", "#66FF66", "#6666FF", "#FF66CC",
    "#66FFB3", "#FF9933", "#9933FF", "#FF9999", "#99FF99", "#9999FF",
    "#FF99FF", "#99FFCC", "#FFCC33", "#CC33FF", "#FFCCCC", "#CCFFCC",
    "#CCCCFF", "#FFCCFF", "#CCFF99"
  ];
  const objetoConMayorSector = listsectors.reduce((max, item) => {
    return Number(item.sector) > Number(max.sector) ? item : max;
  }, listsectors[0]);
  const sectornew = Number(objetoConMayorSector?.sector) + 1;
  dataux?.map(i=>{
    if(i.code === item?.code){
        i.color_two = colorPalette[Number(objetoConMayorSector?.sector) + 1];
        i.sector = sectornew;
      }
    });
  join_points(dataux, true);

}

const get_coordenadas = (dt) =>{
    var urlcoordenadas = "";
    const lotes = chunkArray(dt, 24);
    const lotesConLoop = loopChunks(lotes);
  
    const ddd = Promise.all(lotesConLoop?.map(async(item)=>{
      urlcoordenadas = "";
      item.map((d, i)=>{    
        if(item.length-1 === i){
          urlcoordenadas = urlcoordenadas + `${d?.lon}%2C${d?.lat}`;
        }else{
          urlcoordenadas = urlcoordenadas + `${d?.lon}%2C${d?.lat}%3B`;
        }
      })
      const datageomey = await get_geometry(urlcoordenadas);  
      return datageomey;
    })).then(results => {
      return results.flat();
    });
    
    ddd.then(geometryData => {
      setgeometry_address(geometryData);
    });
    return ddd
  };
function chunkArray(array, chunkSize) {
  const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

function loopChunks(chunks) {
  for (let i = 1; i < chunks.length; i++) {
      const lastElement = chunks[i - 1][chunks[i - 1].length - 1]; 
      chunks[i].unshift(lastElement);
  }
  return chunks;
  }
const get_geometry = async(d)=>{
    const url = `https://api.mapbox.com/directions/v5/mapbox/${type_dc}/${d}?alternatives=false&continue_straight=false&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoiaXZhbmFndWlsYXIiLCJhIjoiY20zMGJoN2xuMGp0eTJwcTB4cHI5bGF6OSJ9.eCm-LTQv1QzDjsjhL3f4zA`;
    try {
       const response = await axios.get(url);
       const dtt = response?.data?.routes[0]?.geometry?.coordinates;
       return dtt;
     } catch (error) {
       console.log(error);
    }
}
const save_route  =async () =>{
  const url = `https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com/development/pointsofsale/sectors-new-order`;
  var datasend ={
    points: data
  }
  try{    
        setprocesstwo(true);
        setmodalT(true);
        const response = await axios.post(url, datasend, {
          params: {
            size: size.toString(),
            works_days: works_days.toString(),
            process_flag: false,
            points_fn_flag: points_fn_flag,
          },
          headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
        });
        setdataproc2(response?.data ? response?.data : []);
        join_points(response?.data ? response?.data : [], true);
        setmodalT(false);
    }catch(err){
        console.log(err);
        setmodalT(false);
        setprocesstwo(false);
    }
}
const resetmap = () =>{
  processtwo ? join_points(dataproc2, true) : join_points(dataproc2, false);
}
const filter_map_group = (item) =>{

  let newListSelect;
  if (listselect.includes(item?.group)) {
    newListSelect = listselect.filter(i => i !== item?.group);
  } else {
    newListSelect = [...listselect, item?.group];
  }
  setlistselect(newListSelect);
  if (newListSelect.length === 0) {
    setdata(dataux);        
    join_points(dataux, true);
  } else {
    const d = dataux?.filter(i => newListSelect.includes(i.group));
    setdata(d);
  }
  if (processtwo) {

    console.log(newListSelect);
    if(newListSelect.length === 0){
      setlistsectors(listsectorsaux);
    }else{
      const s = listsectorsaux?.filter(i => newListSelect.includes(i.group));  
      setlistsectors(s);
    }
  }
}
const zoom = (e, v)=>{

  const map = mapInstance.current;
  console.log(v);
  if(v===null){
    if (map) {
      map.flyTo({
        center: [data[0].lon || -97.07318829119332, data[0].lat || 18.85020875064652],
        zoom: 12,     
        speed: 1.2,          
        curve: 1.42          
      });
    }
  }else{
    if (map) {
      map.flyTo({
         center: [v?.lon, v?.lat],  
         zoom: 18,     
         speed: 1.2,          
         curve: 1.42          
      });
    } 
    
  }
 
}

const filter_map_sector = (item) =>{
  const d = dataux?.filter(i=>i.sector == item.sector);
  setdata(d);
}  
function alertas(msj, status) {

  if (status === true) {
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br',
      autoDismiss: 10,
    });
  } else {
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br',
      autoDismiss: 60,
    });
  }
}

const update_identifiers = [
  { code: 'string' },
  { name: 'string' },
  { route: 'string' },
  { Enero: 'number' },
  { Febrero: 'number' },
  { Marzo: 'number' },
  { Abril: 'number' },
  { Mayo: 'number' },
  { Junio: 'number' },
  { Julio: 'number' },
  { Agosto: 'number' },
  { Septiembre: 'number' },
  { Octubre: 'number' },
  { Noviembre: 'number' },
  { Diciembre: 'number' },
  { Enero_l: 'number' },
  { Febrero_l: 'number' },
  { Marzo_l: 'number' },
  { Abril_l: 'number' },
  { Mayo_l: 'number' },
  { Junio_l: 'number' },
  { Julio_l: 'number' },
  { Agosto_l: 'number' },
  { Septiembre_l: 'number' },
  { Octubre_l: 'number' },
  { Noviembre_l: 'number' },
  { Diciembre_l: 'number' }
];

const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const handlesubmitsummary = async()=>{
  try{

    if(year === "" || year === null){
      alertas("No ha proporcionado el año de consulta", false);
      return null;
    }

    var dat ={};
    if(type==="Filter"){
      if(data?.length >0){
        const codes = data?.map(item=> item.code);
        dat = {
          POS_code_list: codes
        }
      }else{
        alertas("No hay registros de puntos de venta", false);
        return null;
      }
    }
    if(type === "Polygone"){
      if(vertices?.length > 0){
        const codes = vertices.map(item => item.code);
        dat = {
          POS_code_list: codes
        }
      }else{
        alertas("No ha seleccionado ningún punto de venta en el polygono en el mapa", false);
        return null;
      }
    }
    
    setmodalT(true);
    setdatasum([]);
    const url = `https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com/prod/annual-summary-by-pos/code_list`;
    const response = await axios.post(url, dat,{
      params:{
        year: year
      },
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    const datas = (response?.data?.Points_of_sale_consulted ? response?.data?.Points_of_sale_consulted : [] );
    var ndt = update_null_identifiers(datas, update_identifiers);
    
    ndt.forEach(obj => {
      let total = 0;
      meses.forEach(mes => {
        if (obj.hasOwnProperty(mes)) {
          total += obj[mes];
        }
      });
      obj.total = total;
    });
    setmodalT(false);  
    setdatasum(ndt);
  }catch(err){  
    setmodalT(false);
    console.log(err);
  }
}
const filters_custom = (
  <div className="custom-year-search">
    <FormControl required sx={{width: 100, marginRight: "20px"}}>
      <TextField
        label="Año"
        value={year}
        type={"number"}
        required
        sx={{ width: "100%", m: 1.5 }}
        onChange={(event) => {
          const value = event.target.value;
          if (value === '' || (/^\d{0,4}$/).test(value)) {
            setyear(value);
          }
        }}
        inputProps={{ min: 1, pattern: "[0-9]*", maxLength: 4 }}
        InputLabelProps={{ shrink: true }}
      ></TextField>
    </FormControl>

    <FormControl required sx={{ width: 150 }}>
    <InputLabel id="demo-simple-select-required-label" style={{marginLeft: "12px", marginTop: "10px"}}>Referencia</InputLabel>
    <Select
      labelId="demo-simple-select-required-label"
      id="demo-simple-select-required"
      value={type}
      sx={{ height: '35px', m: 1.5}}
      label="Referencia"
      onChange={(e) => settype(e.target.value)}
    >
      <MenuItem key={0} value={"Filter"}>Filtro</MenuItem>
      <MenuItem key={1} value={"Polygone"}>Polígono</MenuItem>
    </Select>
  </FormControl>
  <FormControl required sx={{ width: "auto" }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => handlesubmitsummary()}
        >
         <SearchIcon titleAccess='Buscar'/>
        </Button>
  </FormControl> 
  {
    datasum?.length > 0 ? 
    <FormControl required sx={{ width: 150 }}>
    <InputLabel id="demo-simple-select-required-label" style={{marginLeft: "12px", marginTop: "10px"}}>Tipo</InputLabel>
    <Select
      labelId="demo-simple-select-required-label"
      id="demo-simple-select-required"
      value={cash}
      sx={{ height: '35px', m: 1.5}}
      label="Tipo"
      onChange={(e) => setcash(e.target.value)}
    >
      <MenuItem key={0} value={true}>Pesos</MenuItem>
      <MenuItem key={1} value={false}>Litros</MenuItem>
    </Select>
  </FormControl>:<></>
  }
  </div>
);


const [lote_polyn, setlote_polyn] = useState(false);
const [listsectorforgroup2, setlistsectorforgroup2] = useState([]);

useEffect(() => {
  if (vertices.length > 0) {
    
    const sameGroup = vertices.every(vertex => vertex.group === vertices[0].group);
    
    if(sameGroup) {
      setlote_polyn(true);
      const listsectorforgroup = listsectors.filter(it=>it.group ===vertices[0].group); 
      console.log(listsectorforgroup);
      setlistsectorforgroup2(listsectorforgroup);
    }else {
      setlote_polyn(false);
      alertas("No se puede realizar el movimiento con puntos de venta que sean de diferente Grupo", false)
    }
  }
}, [vertices]);


const sectorloteagroup = (id) =>{
  const col = listsectors.filter(item=>item.sector === id);

  if(vertices?.length > 0){
    dataux?.map(i=>{
      vertices?.map(j=>{
        if(i.code === j?.code){
          console.log("enyra");
          i.color_two = col[0].color_two;
          i.sector = Number(col[0].sector);
        }
      })    
    });
    setvertices([]);
    setlote_polyn(false);
    join_points(dataux, true);
  }
}



    return (
      <>
         <MODAL_TABLE
            open={true}
            message={""}
            modalGeneral={false}
            modalT={modalT}
          ></MODAL_TABLE>
             <BREADCRUMBS niveles={
                [
                  { label: "LOGÍSTICA DE RUTAS", path: null },
                ]
              }
              ></BREADCRUMBS>  
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <SEACRH_FILTER
            size={size}
            setsize={setsize}
            works_days={works_days}
            setworks_days={setworks_days}
            cardinal={cardinal}
            setcardinal={setcardinal}
            points_fn_flag={points_fn_flag}
            setpoints_fn_flag={setpoints_fn_flag}
            type_pos={type_pos}
            settype_pos={settype_pos}
            routes={routes}
            setroutes={setroutes}
            dataroute={dataroute}
            handlesubmit={handlesubmit}
            showmap={showmap}
            setshowmap={setshowmap}
            setdata={setdata}
            dataux={dataux}
            join_points={join_points}
            setshowsumm={setshowsumm}
            showsumm={showsumm}
            setlistselect={setlistselect}
            resetmap={resetmap}
            save_route={save_route}
            processtwo={processtwo}
            datapoints={datapoints}
            setprocesstwo={setprocesstwo}
          
          ></SEACRH_FILTER>
          <br></br> 
        <div className='route-89-config' style={showmap===true?{"display":""}:{"display":"none"}}>
          <div className='symbology-route'>
            <h2>Grupos {showgroupsymb===true?<ArrowDropUpIcon className="showhidemap" onClick={()=>setshowgroupsymb(false)}/>:<ArrowDropDownIcon className="showhidemap" onClick={()=>setshowgroupsymb(true)}/>}</h2>
            <div style={showgroupsymb===true?{display:""}:{display:"none"}}>
              {
                dord.map(item=>{   
                  const isSelected = listselect.includes(item?.group);
                  return (
                    <div className="content-symbology-info">
                      <div className="circle" 
                        style={{backgroundColor: item?.color}}
                        onClick={()=>{filter_map_group(item)}}  
                      >
                      {
                          isSelected===true?<CloseIcon className='close-icon-map'></CloseIcon>:<></>
                      }
                      </div>
                      <p>Grupo {item?.group} - </p> 
                      <p>Total: {item?.count}</p>
                  </div> 
                  )
                })
              }
            </div>
            
          </div>
        {
          processtwo ?
          <div className="content-symbology-sectors-filter">
            <h2>Sectores {showsecsymb===true?<ArrowDropUpIcon className="showhidemap" onClick={()=>setshowsecsym(false)}/>:<ArrowDropDownIcon className="showhidemap" onClick={()=>setshowsecsym(true)}/>}</h2>
            <div style={showsecsymb===true?{display:""}:{display:"none"}}>
            {
              dords.map(item=>(
                <div className="content-symbology-info">
                    <div className="circle" 
                      style={{backgroundColor: item?.color_two}}
                      onClick={()=>filter_map_sector(item)}  
                    ></div>
                    <p>Sector {item?.sector} - </p> 
                    <p>Total: {item?.count}</p>
                </div> 
              ))
            }
            </div>
          </div>:<></>
        }
        <div className='input-search-points-map-89'>
            <Autocomplete
                id="second-warehouse-autocomplete"
                options={data}
                getOptionLabel={(option) => option.code +" - "+ option.name }
                value={data.find(wh => wh.code === idpoint) || null}
                onChange={(event, newValue) => {
                  zoom(event, newValue);
                  setidpoint(newValue ? newValue.code : null);
                }}
                style={{ width: "auto" }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Punto de Venta"
                    required
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    style={{borderRadius: "5px" }}
                  />
                )}
              />
        </div>
        <div className='show-points-dv'>
            <p><span>Puntos de venta: </span>{data.length}</p>
            <p><span>Poligono: </span>{vertices?.length}</p>
            {
              lote_polyn === true?
              <div className=''>
                <select id="grupo-select" 
                  style={{ height: "35px" }}
                  onChange={(e)=>{sectorloteagroup(e.target.value)}}
                >
                  <option value="all">{processtwo ? "Seleccione Sector" : "Seleccione Grupo"}</option>
                  {
                    processtwo 
                      ? listsectorforgroup2?.map((groupItem) => (
                          <option key={groupItem.sector} value={groupItem.sector}>
                            {groupItem.sector}
                          </option>
                        ))
                      : listgroup?.map((groupItem) => (
                          <option key={groupItem.color} value={groupItem.color}>
                            {groupItem.group}
                          </option>
                        ))
                  }
                </select>
              </div>:<div></div>
            }
        </div>
        

        <div ref={mapRef} style={{ height: '80vh', width: '100%' }} />
        </div>  
        <div className='sumary-map-route89'>
          {
            showmap===true?<></>:
              showsumm===true?
              <SUMMARY 
                datasum={datasum} 
                setdatasum={setdatasum}
                alertas={alertas} 
                setmodalT={setmodalT} 
                cash={cash}
                filters_custom={filters_custom}
              ></SUMMARY>
              :<TABLE data={data} setdata={setdata}></TABLE>
          }
        </div>
         
      </>  
    )
  }
