//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
//COMPONENTES
import Rutas from "../../dataDahsboard/routes.json";
import { refresh_token } from "../../main_components/tokens/tokenrefresh.jsx";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
import "./products_dashboard.css";
//IMAGENES/ICONOS
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { dashboard1_products } from "../../services/reports/dashboard";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardProducts_v2 = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [selectRutas, setselectRutas] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);


  const[cant, setcant]=useState([]);
  const[cants, setcants]=useState([]);
  const[cantp, setcantp]=useState([]);
  const[clien, setclien]=useState([]);
  const[merc, sermerc]=useState([]);
  const[vent, setvent]=useState([]);
  const[gans, setgans]=useState([]);
  const[ganp, setgap]=useState([]);

  
  const handleSubmit = async() => {
     setOpen(true);
     setMenssage("Consultando...");
     setIsLoading(true);
     console.log(fi);
     console.log(ff);
     setData([]);
     try{
       var rf_token = await refresh_token();
       if(rf_token===true){
         setTimeout(async() => {
           try{
             const d = await dashboard1_products(fi, ff, selectRutas);
             const data = d === null ? [] : d.data;
             const data2 = (data.filter(dash=>dash.product!=="Grand Total"));
             setcant(data2?.map(obj=>obj.quantity).filter(d => d !== null));
             setcants(data2?.map(obj=>obj.quantity_secondary_sale).filter(d=> d !== null));
             setcantp(data2?.map(obj=>obj?.quantity_primary_sale).filter(d=> d !== null));
             setclien(data2?.map(obj=>obj?.customers).filter(d=>d!==null));
             sermerc(data2?.map(obj=>obj?.market).filter(d=>d!==null));
             setvent(data2?.map(obj=>obj?.primary_sale).filter(d=>d!==null));
             setgans(data2?.map(obj=>obj?.profit_secondary_sale).filter(d=>d!==null));
             setgap(data2?.map(obj=>obj?.profit_primary_sale).filter(d=>d!==null));

             setData(data2);
             setOpen(false);
             setMenssage("");
             setIsLoading(false);
           }catch(err){
             console.log("error", err);
             setData([]);
             setOpen(false);
             setMenssage("");
             setIsLoading(false);
           }
        }, 1000);
       }else{}
     }catch(err){
       console.log(err);
     }
  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };
  

  const update_identifiers =
    [
      { product: 'string' },
      { quantity: 'number' },
      { quantity_secondary_sale: 'number' },
      { quantity_primary_sale: 'number' },
      { customers: 'number' },
      { market: 'number' },
      { primary_sale: 'number'},
      { profit_secondary_sale: 'number' },
      { profit_primary_sale: 'number' }
    ];

  const columns = useMemo(
    () => [
      { 
        header: "Producto",
        accessorKey: "product",
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.quantity)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(cant)}</div>
            </div>
          )
        }, 
      },
      {
        header: "Cantidad Secundaria",
        accessorKey: "quantity_secondary_sale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.quantity_secondary_sale)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(cants)}</div>
            </div>
          )
        }, 
      },
      {
        header: "Cantidad Primaria",
        accessorKey: "quantity_primary_sale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.quantity_primary_sale)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(cantp)}</div>
            </div>
          )
        }, 
      },

      {
        header: "Clientes",
        accessorKey: "customers",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.customers)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(clien)}</div>
            </div>
          )
        }, 
      },
      {
        header: "Mercado",
        accessorKey: "market",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.market)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)?.toFixed(2)}</div>
              <div>{calculate_total(merc)}</div>
            </div>
          )
        }, 
      },
      {
        header: "Venta",
        accessorKey: "primary_sale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.primary_sale)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(vent))
              }</div>
            </div>
          )
        }, 
      },
      {
        id:"profit_secondary_sale",
        header: "Ganancia Secundaria",
        accessorKey: "profit_secondary_sale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.profit_secondary_sale)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gans))
              }</div>
            </div>
          )
        },
       },
       {
         id:"profit_primary_sale",
         header: "Ganancia Primaria",
         accessorKey: "profit_primary_sale",
         muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
         Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.profit_secondary_sale)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(ganp))
              }</div>
            </div>
          )
        },
       },
    ],
    [cant,cants,cantp,clien,merc,vent,gans,ganp]
  );

  return (
    <div >
      <div>
    <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="">
        <div className="new_table_v2">
        <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={
           { 
             showGlobalFilter: true, 
             columnVisibility:{
              profit_secondary_sale:sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?true:false,
              profit_primary_sale: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?true:false
             }
           }
         }
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 80
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderTopToolbarCustomActions={({ }) => (
          <div className="gridproductonewdash">
            <div className="expen-1 color-1">
              <input
                type="text"
                className="fExpense"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
                style={{height:"40px"}}
              ></input>
            </div>

        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            style={{height:"40px"}}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-3 color-5 topSelect">
          <TextField
            select
            label="Rutas"
            value={selectRutas}
            sx={{ width: "100%" }}
            onChange={handleRutas}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {Rutas.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </TextField>
        </div>

        <div className="expen-4 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={()=>handleSubmit()}
          >
            Buscar
          </Button>
        </div>
      </div>
        )}
        renderRowActionMenuItems={({ row, closeMenu }) => [
           <RENDER_ROW_ACTION_MENU_ITEMS
             closeMenu={closeMenu}
             row={row}
             handleSubmit={handleSubmit}
             setreconsult={setreconsult}
             refresh_token={refresh_token}
             verData={true}
             id_detail={row.original.product}
             datei={fi}
             datef={ff}
             route={selectRutas} 
           />
         ]}               
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Detalles de Ventas Productos"}
             update_identifiers={update_identifiers}
          />
        )}
      />
        </div>
      </div>
    </div>
  );
};

export default NewDashboardProducts_v2;
