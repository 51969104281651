import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, CircularProgress, Button } from '@mui/material';

// Definir tipos para las props
interface SEARCH_FILTERSProps {
    loading: boolean;
    value_select: any;
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    handle_seach: () => void;
}





const SEARCH_FILTERS: React.FC<SEARCH_FILTERSProps> = (props) => {
    const { value_select, loading, handleChange } = props;

    const data_select= [{id:1,name:"SI", value:true},{id:2,name:"NO", value:false}];


    return (
        <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: 10, alignItems: 'flex-start' }}>
            {/* Primer Select */}
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-required-label">Habilitados</InputLabel>
                <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    sx={{ height: '35px' }}
                    value={value_select} // value debe ser string, number o boolean
                    onChange={(e)=>handleChange(e.target.value)}
                >
                    {loading && (
                        <MenuItem disabled>
                            <CircularProgress size={20} thickness={4} />
                        </MenuItem>
                    )}
                    {!loading && data_select.map((select_option: any, index: number) => (
                        <MenuItem key={`${select_option.id}-${index}`} value={select_option.value}>
                            {select_option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Botón de Buscar */}
            <FormControl sx={{ width: '100%' }}>
                <Button onClick={(e)=>props?.handle_seach()}>Buscar</Button>
            </FormControl>
        </Box>
    );
};

export default SEARCH_FILTERS;
