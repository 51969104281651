//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import "../../components_cfdi/estilo.css";
import "../componentSectors/bonuses.css";
import "../../components_cfdi/modal.css";
import "./pointofsale.css";
//IMAGENES/ICONOS
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import NotificationSystem from "react-notification-system";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { pagination } from "../../main_components/pagination/pagination";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import CreatePointOfSale from "./CreatePointOfSale";
import { CustomDialog } from "react-st-modal";
import CREATE from "../../imgComponents/create.png";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
import { UPDATEADDRESS } from "./tools/update_address";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const POINTS_OF_SALE_V2 = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");

  const update_identifiers =
    [
      { code: 'string' },
      { outer_code: 'string' },
      { special_number_folio: 'string' },
      { name: 'string' },
      { businessPartner: 'string' },
      { rfc: 'string' },
      { channel: 'string' },
      { subchannel: 'string' },
      { route: 'string' },
      { sectors: 'string' },
      { conditionPay: 'string' },
      { generates_debt: 'string' },
      { frequency: 'string' },
      { date_register: 'string' },
      { visit_days: 'string' },
      { price_list: 'string' },
      { classification: 'string' },
      { number_oxxo_store: 'string' },
      { lat: 'string' },
      { lng: 'string' },
      { email: 'string' },
      { phone: 'string' },
      { street: 'string' },
      { outdoor_number: 'string' },
      { inside_number: 'string' },
      { neighborhood: 'string' },
      { zip_code: 'string' },
      { city: 'string' },
      { town: 'string' },
      { state: 'string' },
      { country: 'string' },
      { reference: 'string' },
      { address_type: 'string' },
      { congelador_unilever: 'string' },
      { Unilever: 'string' },
      { congeladorpropios: 'string' },
      { Propio: 'string' },

    ];



  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }

  const handleSubmit = async (query_filters_flag, query_filters_data) => {
    setData([]);
    setopen(true);
    setmodalT(true);
    setIsLoading(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (query_filters_flag) {
              const d = await pagination(3, "pointofsales", null);
              setquery_filters(query_filters_data);
            } else {
              var prueba = await pagination(3, "pointofsales", null);
              var ndt = update_null_identifiers(prueba, update_identifiers);
              setData(ndt);
              setopen(false);
              setIsLoading(false);
              setmodalT(false);
            }
          } catch (err) {
            console.error(err);
            setopen(false);
            setIsLoading(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (error) { }
  };


  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const columns = useMemo(
    () => [
      {
        header: "Código",
        accessorKey: "code",
        id: "code",
      },
      {
        header: "Código Unilever",
        accessorKey: "outer_code",
        id: "outer_code",
      },
      {
        header: "Folio Especial",
        accessorKey: "special_number_folio",
        id: "special_number_folio",
      },
      {
        header: "Nombre",
        accessorKey: "name",
        id: "name",
      },
      {
        header: "Socio de Negocios",
        accessorKey: "businessPartner",
        id: "businessPartner",
      },
      {
        header: "RFC Socio Negocios",
        accessorKey: "rfc",
        id: "rfc",
      },
      {
        header: "Canal",
        accessorKey: "channel",
        id: "channel",
        filterVariant: 'select',
      },
      {
        header: "Subcanal",
        accessorKey: "subchannel",
        id: "subchannel",
        filterVariant: 'select',
      },
      {
        filterVariant: 'select',
        header: "Ruta",
        accessorKey: "route",
        id: "route",
      },
      {
        header: "Sectores",
        accessorKey: "sectors",
        id: "sectors",
      },
      {
        filterVariant: 'select',
        header: "Pago",
        accessorKey: "conditionPay",
        id: "conditionPay",
      },
      {
        header: "Deuda",
        accessorKey: "generates_debt",
        accessorFn: (d) => {
          return d.generates_debt === true ? "SI" : "NO";
        },
        id: "generates_debt",
      },
      {
        filterVariant: 'select',
        header: "Frecuencia",
        accessorKey: "frequency",
        id: "frequency",
      },
      {
        header: "Alta",
        accessorKey: "date_register",
        id: "date_register",
      },

      {
        header: "Días de visita",
        accessorKey: "visit_days",
        id: "visit_days",
      },

      {
        header: "Lista de precios",
        accessorKey: "price_list",
        id: "price_list",
      },

      {
        header: "Clasificación",
        accessorKey: "classification",
        id: "classification",
      },

      {
        header: "Número de Tienda",
        accessorKey: "number_oxxo_store",
        id: "number_oxxo_store",
      },

      {
        header: "Latitud",
        accessorKey: "lat",
        id: "lat",
      },
      {
        header: "Longitud",
        accessorKey: "lng",
        id: "lng",
      },

      {
        header: "Correo Electrónico",
        accessorKey: "email",
        id: "email",
      },

      {
        header: "Número Telefónico",
        accessorKey: "phone",
        id: "phone",
      },
      {
        header: "Calle",
        accessorFn: (d) => {
          return d.address?.street;
        },
        id: "street",
        accessorKey: "street"
      },
      {
        header: "Número Exterior",
        accessorFn: (d) => {
          return d.address?.outdoor_number;
        },
        id: "outdoor_number",
        accessorKey: "outdoor_number"
      },
      {
        header: "Número Interior",
        accessorKey: "inside_number",
        accessorFn: (d) => {
          return d.address?.inside_number;
        },
        id: "inside_number",
      },
      {
        header: "Colonia",
        accessorKey: "neighborhood",
        accessorFn: (d) => {
          return d.address?.neighborhood;
        },
        id: "neighborhood",
      },
      {
        header: "Código postal",
        accessorKey: "zip_code",
        accessorFn: (d) => {
          return d.address?.zip_code;
        },
        id: "zip_code",
      },

      //aqui 
      {
        header: "Ciudad",
        accessorKey: "city",
        accessorFn: (d) => {
          return d.address?.city;
        },
        id: "city",
      },
      {
        header: "Municipio",
        accessorKey: "town",
        accessorFn: (d) => {
          return d.address?.town;
        },
        id: "town",
      },
      {
        header: "Estado",
        accessorKey: "state",
        accessorFn: (d) => {
          return d.address?.state;
        },
        id: "state",
      },
      {
        header: "País",
        accessorKey: "country",
        accessorFn: (d) => {
          return d.address?.country;
        },
        id: "country",
      },
      {
        header: "Referencia",
        accessorKey: "reference",
        accessorFn: (d) => {
          return d.address?.reference;
        },
        id: "reference",
      },
      {
        header: "Tipo de dirección",
        accessorKey: "address_type",
        accessorFn: (d) => {
          return d.address?.address_type;
        },
        id: "address_type",
      },
      {
        header: "No. Congeladores Unilever",
        accessorKey: "congelador_unilever",
        accessorFn: (d) => {
          if (d.cabinets && d.cabinets.length > 0) {
            const filteredCabinets = d.cabinets.filter((cabinet) => cabinet.unilever !== undefined && cabinet.unilever !== null);

            const allUnileverValues = filteredCabinets.map((cabinet) => cabinet.unilever.split(','));

            const flattenedUnileverValues = [].concat(...allUnileverValues);

            const nonEmptyUnileverValues = flattenedUnileverValues.filter((value) => value.trim() !== '');

            const countUnilever = nonEmptyUnileverValues.length;

            return countUnilever;
          }

          return 0;
        },
        id: "congelador_unilever",
      },


      {
        header: "Congeladores Unilever",
        accessorKey: "Unilever",
        accessorFn: (d) => {
          if (d.cabinets && d.cabinets.length > 0) {
            const unileverValue = d?.cabinets[0]?.unilever;

            const formattedUnilever = unileverValue ? unileverValue?.replace(/,\s*/g, ', ') : "";


            const unileverArray = d?.cabinets?.map((cabinet) => cabinet?.unilever?.replace(/,\s*/g, ', '));

            return formattedUnilever;
          }

          return "";
        },

        id: "Unilever",
      },

      {
        header: "No. Congeladores Propios",
        accessorKey: "congeladorpropios",
        accessorFn: (d) => {
          if (d.cabinets && d.cabinets.length > 0) {
            const filteredCabinets = d.cabinets.filter((cabinet) => cabinet.propio !== undefined && cabinet.propio !== null);

            const allUnileverValues = filteredCabinets.map((cabinet) => cabinet.propio.split(','));
            const flattenedUnileverValues = [].concat(...allUnileverValues);
            const nonEmptyUnileverValues = flattenedUnileverValues.filter((value) => value.trim() !== '');
            const countUnilever = nonEmptyUnileverValues.length;
            return countUnilever;
          }

          return 0;
        },
        id: "congeladorpropios",
      },
      {
        header: "Congeladores Propios",
        accessorKey: "propio",
        accessorFn: (d) => {
          if (d.cabinets && d.cabinets.length > 0) {
            const unileverValue = d?.cabinets[1]?.propio;

            const formattedUnilever = unileverValue ? unileverValue?.replace(/,\s*/g, ', ') : "";


            const unileverArray = d?.cabinets?.map((cabinet) => cabinet?.propio?.replace(/,\s*/g, ', '));

            return formattedUnilever;
          }

          return "";
        },
        id: "propio",
      },

    ],
    []
  );

  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])


  const actualizarcsv = async()=>{
    await CustomDialog(
      <div>
        <UPDATEADDRESS
          dataorigen = {data}
          handleSubmit={handleSubmit}
        
        />
      </div>,
      {
        className: "custom-modal-2",
        title: "Detalles de punto de venta",
        showCloseIcon: true,
        isCanClose: false,
      })
  }
  

  return (
    <>

      <div className="">
        <div >
          <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>
            <NotificationSystem
              ref={notificationSystemRef}
            ></NotificationSystem>
           
            <MODAL_TABLE
              open={true}
              message={menssage}
              modalGeneral={modalGeneral}
              modalT={modalT}
            ></MODAL_TABLE>
            <>
              <BREADCRUMBS niveles={
                [
                  { label: "CONSULTAR PUNTOS DE VENTA", path: null },
                  { label: "PUNTOS DE VENTA SIN SECTOR", path: "/consult_point_of_sale_without_sector_v2" },
                ]
              }
                cards_dashboard={true}
                total_rows={total_rows !== "" ? total_rows : 0}
                filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
                visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
                total={data?.length}
              ></BREADCRUMBS>
              <div className="new_table_v2">
                <MaterialReactTable
                  columns={columns}
                  data={data}
                  enableGrouping
                  enablePinning
                  enableFacetedValues
                  enableStickyHeader
                  enableStickyFooter
                  enableRowPinning
                  enableColumnFilterModes
                  enableRowActions
                  onStateChange={handleStateChange}
                  enableRowSelection
                  getRowId={(row) => row?.id}
                  onRowSelectionChange={setRowSelection}
                  enableTooltips={false}
                  localization={MRT_Localization_ES}
                  state={{ rowSelection, isLoading: isLoading }}
                  enableColumnResizing
                  enableColumnPinning
                  enableColumnOrdering
                  enableExpandAll={false}
                  enableColumnDragging={false}
                  initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                  muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                  muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                  rowVirtualizerInstanceRef
                  rowVirtualizerOptions={{ overscan: 5 }}
                  columnVirtualizerOptions={{ overscan: 2 }}
                  muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                  positionToolbarAlertBanner="bottom"
                  paginationDisplayMode='pages'
                  rowPinningDisplayMode='sticky'
                  layoutMode="grid"
                  muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                  muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                  muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                  displayColumnDefOptions={{
                    'mrt-row-pin': {
                      Header: () => (
                        <div>
                          <img
                            title="Registrar Punto de Venta"
                            src={CREATE}
                            style={{ "cursor": "pointer" }}
                            className=""
                            onClick={async () => {
                              const result = await CustomDialog(
                                <CreatePointOfSale
                                  handleSubmit={handleSubmit}
                                  setmodalT={setmodalT}
                                  alertas={alertas}
                                  data={data}
                                />,
                                {
                                  className: "custom-modal-3",
                                  title: "Registrar Punto de Venta",
                                  showCloseIcon: true,
                                  isCanClose: false,
                                }
                              );
                            }}
                          ></img>
                        </div>
                      ),
                      enableHiding: true,
                    },
                    'mrt-row-actions': {
                      enableHiding: true,
                    },
                    'mrt-row-expand': {
                      enableHiding: true,
                    },
                    'mrt-row-select': {
                      enableHiding: true,
                    }
                  }}
                  muiTableBodyRowDragHandleProps={({ table }) => ({
                    onDragEnd: () => {
                      const { draggingRow, hoveredRow } = table.getState();
                      if (hoveredRow && draggingRow) {
                        data.splice(
                          hoveredRow.index,
                          0,
                          data.splice(draggingRow.index, 1)[0],
                        );
                        setData([...data]);
                      }
                    },
                  })}
                  muiTableBodyRowProps={
                    ({ row, table }) => {
                      settotal_rows(table);
                      const { density } = table.getState();
                      return {
                        sx: {
                          height: row.getIsPinned()
                            ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                            }px`
                            : undefined,
                        },
                      };
                    }
                  }
                  renderTopToolbarCustomActions={({ }) => (
                    <div className="button-upload-csv-address" onClick={()=>actualizarcsv()}>
                      <UploadFileIcon></UploadFileIcon>
                        Actualizacion por lote
                    </div>
                    
                  )}
                  renderRowActionMenuItems={({ row, closeMenu }) => [
                    <RENDER_ROW_ACTION_MENU_ITEMS
                      closeMenu={closeMenu}
                      setmodalT={setmodalT}
                      setmenssage={setmenssage}
                      setmodalGeneral={setmodalGeneral}
                      row={row}
                      handleSubmit={handleSubmit}
                      setreconsult={setreconsult}
                      refresh_token={refresh_token}
                      alertas={alertas}
                    />
                  ]}
                  renderToolbarInternalActions={({ table, index }) => (
                    <RENDER_TOOLBAR_INTERNAL_ACTIONS
                      table={table}
                      file_name={"Consultar Puntos de Venta"}
                      update_identifiers={update_identifiers}
                      settotal_rows={settotal_rows}
                      query_filters={query_filters}
                      query_filters_dt={query_filters_dt}
                      handle_get_consult={handleSubmit}
                      key={index}
                      handle_success_filter={handle_success_filter}
                      share_filter={true}
                      total_rows={total_rows !== "" ? total_rows : 0}
                    />
                  )
                  }
                />
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default POINTS_OF_SALE_V2;
