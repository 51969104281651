import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import lupa from "../../../imgComponents/lupa.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import CREATE_UPDATE from "./create_update";
import { delete_producst_line } from "../../../services/inventory/inventory";
import PRODUCTS_V2 from "../../products/products _v2";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    sessionStorage.setItem("id_product_line", props?.row.original.id);
    console.log(props?.row?.original?.id);


    const DeleteSector =async () =>{
        props.setmodalT(true);
        
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await delete_producst_line(props?.row?.original.id);
                   console.log(d);
                   props?.handle_alert("Linea de Productos Eliminada", true);
                   props.setmodalT(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.handle_alert("Error, no se pudo eliminar la Linea de Productos", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la Linea de Productos?','Eliminar Linea de Productos','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Linea de Productos"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Linea de Productos
            </MenuItem>
            <MenuItem
                style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS_LINE")==="ROLE_INVENTORY_PRODUCTS_LINE"?"":"none"}}
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    const result = await CustomDialog(
                    <CREATE_UPDATE
                      name_line={props?.row.original.name_line}
                      code={props?.row.original.code}
                      description={props?.row.original.description}
                      enable={props?.row.original.enable}
                      method={"UPDATE"}
                      id={props?.row.original.id}
                      name_supplier={props?.row.original.business_partner}
                      id_supp={props?.row.original.id_business_partner_provider_fk}
                      setmodalT={props?.setmodalT}
                    ></CREATE_UPDATE>,
                    {
                        className: "custom-modal",
                        title: "ACTUALIZAR LINEA DE PRODUCTOS",
                        showCloseIcon: true,
                    },
                    );
                    {
                        props?.handle_reconsult(result);
                    }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Linea de Productos"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Linea de Productos
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <div>
                            <PRODUCTS_V2 subtabla={true} id={props?.row.original.id}/>
                        </div>
                    ,
                    {
                        className: "custom-modal-3",
                        title: "PRODUCTOS DE LA LINEA",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={lupa}
                        title="Productos de la Linea"
                        className=""
                    ></img>
                </ListItemIcon>
                Productos de la Linea
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
