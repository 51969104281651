import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ValidationTextField } from '../../../../main_components/lable/labe';

const SEARCH_FILTERS = (props) => {

  const [restriction_data, setrestriction] = useState(props.restriction_dt);
  const [id_shearch, setid_shearch] = useState("");

  useEffect(() => {
    setrestriction(props.restriction_dt);
  }, [props.restriction_dt,])





  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 150 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 150 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>

      <FormControl sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Criterio</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          label="Criterio"
          value={restriction_data}
          sx={{ height: '35px' }}
          onChange={(e) => {
            setrestriction(e.target.value);
            if (e.target.value === "SC") {
              setid_shearch("");
              props.setBP(null);
              props.setrestriction_dt("SC")
              props.setRFC(null)
            }
          }}
        >
          <MenuItem value={"SN"}>SOCIO DE NEGOCIOS</MenuItem>
          <MenuItem value={"RF"}>RFC</MenuItem>
          <MenuItem value={"SC"}>S/C</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: 200, display: restriction_data === "SN" ? "inline-block" : "none" }}>
        <ValidationTextField
          label="Socio de Negocios"
          placeholder="Socio de Negocios"
          variant="outlined"
          id="validation-outlined-input"
          sx={{ m: .3, width: "100%" }}
          name="town"
          InputLabelProps={{
            shrink: true,
          }}
          value={id_shearch}
          onChange={(event) => {
            setid_shearch(event?.target?.value);
            props.setrestriction_dt("SN")
            props.setBP(event?.target?.value)
            props.setRFC(null)
          }} />
      </FormControl>
      <FormControl sx={{ width: 200, display: restriction_data === "RF" ? "inline-block" : "none" }}>
        <ValidationTextField
          label="RFC"
          placeholder="RFC"
          variant="outlined"
          id="validation-outlined-input"
          sx={{ m: .3, width: "100%" }}
          name="town"
          InputLabelProps={{
            shrink: true,
          }}
          value={id_shearch}
          onChange={(event) => {
            setid_shearch(event?.target?.value);
            props.setrestriction_dt("RF")
            props.setBP(null);
            props.setRFC(event?.target?.value)
          }} />
      </FormControl>

      <FormControl required sx={{ width: 80 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "100%" }}
            onClick={(e) => {
              props.handle_get_sales()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>

      </FormControl>
      <FormControl required sx={{ width: 200 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', width: "100%", background: "green" }}
          >
            Total: ${props.total_rows?.toFixed(2)}
          </Button>
        </Box>

      </FormControl>

    </Box>
  );
};

export default SEARCH_FILTERS;
