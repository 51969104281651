//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import { get_producst_line } from "../../services/inventory/inventory";
import CREATE_UPDATE from "./tools/create_update";

import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const PRODUCTS_LINE_V2 = () => {

  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const dialog = useDialog();
  const notificationSystemRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");

  const handleSubmit = async (a, b) => {
    setmodalT(true);
    setData([]);

    setMenssage("Consultando impuestos...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_producst_line();
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt);
            if (a) {
              handle_alert(b, a)
            }
            setmodalT(false);
          } catch (err) {
            setData([]);
            setMenssage("");
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_products = async (id) => {
    sessionStorage.setItem("id_product_line", id);
    window.location = "/products";
  };
  useEffect(() => {
    handleSubmit();
  }, []);
  const handle_reconsult = (result) => {
    if (result?.confirm) {
      handleSubmit(result?.confirm, result.msj);
    } else if (result?.confirm === false) {
      handle_alert(result.msj, result.confirm);
    }
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "name_line",
        header: "Nombre de la linea",
        accessorKey: "name_line",
        size: 250
      },
      {
        id: "description",
        header: "Descripción",
        accessorKey: "description",
        size: 300
      },
      {
        id: "business_partner",
        header: "Proveedor",
        accessorKey: "business_partner",
        size: 300
      },
      {
        id: "enable",
        header: "Activo",
        accessorKey: "enable",
        muiTableBodyCellProps: {
          align: "center"
        },
        accessorFn: (d) => {
          return d.enable === true ? "Si" : "NO";
        },
      },
    ],
    []
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const update_identifiers =
    [
      { code: 'string' },
      { name_line: 'string' },
      { description: 'string' },
      { business_partner: 'string' },
      { enable: 'string' }
    ];

  return (
    <div >
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>

      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR LINEA DE PRODUCTOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          onStateChange={handleStateChange}
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Registrar Linea de productos"
                    src={CREATE}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <CREATE_UPDATE
                          method={"CREATE"}
                          setmodalT={setmodalT}
                        ></CREATE_UPDATE>,
                        {
                          className: "modalTItle",
                          title: "Registrar Linea de productos",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                      {
                        handle_reconsult(result);
                      }
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              handle_reconsult={handle_reconsult}
              handle_alert={handle_alert}
            // alertas={alertas}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Linea de Productos"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

    </div>
  );
};

export default PRODUCTS_LINE_V2;
