//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';

//COMPONENTES

//ESTILOS



function SUMMARY_CASHCLOSING_V2(props) {
  const [sale_type_flag, setsale_type_flag] = useState("");
  const [sale_type_number, setsale_type_number] = useState(0);

  const summary_cashclosing = [
    {
      sale_type: "Venta Primaria de Contado",
      imported: props?.vPContadoTotal,
      return: props?.vPSContadoDevolucion,
      bonus: props?.vPSContadoBonificacion,
      net_sale: props?.vPContadoTotal - (props?.vPSContadoDevolucion + props?.vPSContadoBonificacion),
      paid: props?.vPContadoPagado,
      debt: props?.vPContadoAdeudo,
      total: props?.vPContadoTotal - (props?.vPSContadoDevolucion + props?.vPSContadoBonificacion),
    },
    {
      sale_type: "Venta Primaria de Crédito",
      imported: props?.vPCTotal,
      return: props?.vPSDevolucion,
      bonus: props?.vPSBonificacion,
      net_sale: props?.vPCTotal - (props?.vPSDevolucion + props?.vPSBonificacion),
      paid: props?.vPCPagado,
      debt: props?.vPCAdeudo,
      total: props?.vPCTotal - (props?.vPSDevolucion + props?.vPSBonificacion),
    },
    {
      sale_type: "Venta Secundaria de Contado",
      imported: props?.vSContadoTotal,
      return: props?.vSSContadoDevolucion,
      bonus: props?.vSSContadoBonificacion,
      net_sale: props.vSContadoTotal.toFixed(2) - (props.vSSContadoDevolucion + props.vSSContadoBonificacion),
      paid: props?.vSContadoPagado,
      debt: props?.vSContadoAdeudo,
      total: props.vSContadoTotal.toFixed(2) - (props.vSSContadoDevolucion + props.vSSContadoBonificacion),
    },
    {
      sale_type: "Venta Secundaria de Crédito",
      imported: props.vSCTotal,
      return: props.vSSDevolucion,
      bonus: props.vSSBonificacion,
      net_sale: props.vSCTotal - (props.vSSDevolucion + props.vSSBonificacion),
      paid: props.vSCPagado,
      debt: props.vSCAdeudo,
      total: props.vSCTotal - (props.vSSDevolucion + props.vSSBonificacion),
    },
    {
      sale_type: "Venta Obsequio",
      imported: props.obTotal,
      return: props.obDevolucion,
      bonus: props.obBonificacion,
      net_sale: props.obTotal - (props.obDevolucion + props.obBonificacion),
      paid: props.obPagado,
      debt: props.obAdeudo,
      total: 0,
    },
    {
      sale_type: "Total",
      imported: props.vPContadoTotal + props.vPCTotal + props.vSContadoTotal + props.vSCTotal + props.obTotal,
      return: props.vPSContadoDevolucion + props.vPSDevolucion + props.vSSContadoDevolucion + props.vSSDevolucion + props.obDevolucion,
      bonus: props.vPSContadoBonificacion + props.vPSBonificacion + props.vSSContadoBonificacion + props.vSSBonificacion + props.obBonificacion,
      net_sale: props.vPContadoTotal - (props.vPSContadoDevolucion + props.vPSContadoBonificacion) + (props.vPCTotal - (props.vPSDevolucion + props.vPSBonificacion)) +
        (props.vSContadoTotal - (props.vSSContadoDevolucion + props.vSSContadoBonificacion)) + (props.vSCTotal - (props.vSSDevolucion + props.vSSBonificacion) +
          (props.obTotal - (props.obDevolucion + props.obBonificacion))),
      paid: props.vPContadoPagado + props.vPCPagado + props.vSContadoPagado + props.vSCPagado + props.obPagado,
      debt: props.vPContadoAdeudo + props.vPCAdeudo + props.vSContadoAdeudo + props.vSCAdeudo + props.obAdeudo,
      total: (props.vPContadoTotal - (props.vPSContadoDevolucion + props.vPSContadoBonificacion) + (props.vPCTotal - (props.vPSDevolucion + props.vPSBonificacion) +
        (props.vSContadoTotal - (props.vSSContadoDevolucion + props.vSSContadoBonificacion))) + (props.vSCTotal - (props.vSSDevolucion + props.vSSBonificacion))),
    }
  ]
  const columns = useMemo(
    () => [
      {
        header: "Tipo de Venta",
        accessorKey: "sale_type",
        size: 80,
        accessorFn: (d) => {
          return (
            <div onClick={(e) => {
              if (d.sale_type === "Venta Primaria de Contado") {
                if (sale_type_number === 1) {
                  setsale_type_flag("");
                  props?.handleFIlterSalePrimaryC();
                } else {
                  props?.handleFIlterSalePrimaryC();
                  setsale_type_flag(d.sale_type);
                  setsale_type_number(1);
                }
              } else if (d.sale_type === "Venta Primaria de Crédito") {
                if (sale_type_number === 2) {
                  setsale_type_flag("");
                  props?.handleFIlterSalePrimaryCredit();
                } else {
                  props?.handleFIlterSalePrimaryCredit();
                  setsale_type_flag(d.sale_type);
                  setsale_type_number(2);
                }
              }
              else if (d.sale_type === "Venta Secundaria de Contado") {

                if (sale_type_number === 3) {
                  setsale_type_flag("");
                  props?.handleFIlterSaleSecundaryC();
                } else {
                  props?.handleFIlterSaleSecundaryC();
                  setsale_type_flag(d.sale_type);
                  setsale_type_number(3);
                }
              }
              else if (d.sale_type === "Venta Secundaria de Crédito") {

                if (sale_type_number === 4) {
                  setsale_type_flag("");
                  props?.handleFIlterSaleSecundaryCredit();
                } else {
                  props?.handleFIlterSaleSecundaryCredit();
                  setsale_type_flag(d.sale_type);
                  setsale_type_number(4);
                }
              }
              else if (d.sale_type === "Venta Obsequio") {

                if (sale_type_number === 5) {
                  setsale_type_flag("");
                  props?.handleFIlterDebit();
                } else {
                  props?.handleFIlterDebit();
                  setsale_type_flag(d.sale_type);
                  setsale_type_number(5);
                }
              }
            }}
              style={{ cursor: "pointer", backgroundColor: d.sale_type === sale_type_flag ? "yellowgreen" : "white" }}
            >{d.sale_type}</div>
          );
        }
      },
      {
        header: "Importe",
        accessorKey: "imported",
        size: 120,
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
      },
      {
        header: "Devolución",
        accessorKey: "return",
        size: 80,
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
      },
      {
        header: "Bonificación",
        accessorKey: "bonus",
        size: 80,
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
      },
      {
        header: "Venta Neta",
        size: 80,
        accessorKey: "net_sale",
        muiTableBodyCellProps:{
          align: "right"
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
      },
      {
        header: "Pagado",
        accessorKey: "paid",
        size: 80,
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
      },
      {
        header: "Adeudo",
        accessorKey: "debt",
        size: 80,
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
      },
      {
        header: "Total de Ventas",
        accessorKey: "total",
        size: 80,
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
      },

    ],

    [props?.vPContadoTotal, props?.vPSContadoDevolucion, props?.vPSContadoBonificacion, sale_type_flag]
  );

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={summary_cashclosing}
        enableColumnActions={false}
        enableColumnFilters
        enablePagination
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        state={{ isLoading: props.isLoading }}
        muiCircularProgressPropsave_datas={{ color: 'secondary', thickness: 5, size: 55 }}
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
     
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        muiTableBodyRowProps={{ hover: false }}
      />

    </div>

  );
}

export default SUMMARY_CASHCLOSING_V2;
