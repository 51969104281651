//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import dayjs from 'dayjs';

//COMPONENTES
import { get_movements } from "../../services/inventory/inventory";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SET_MOVEMENTS from "./tools/create_movements";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";

import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import SPECIAL_ACTIONS from "./tools/special_actions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import SEARCH_FILTERS from "./tools/search_filter";
import { formattedCurrentDate } from "../../main_components/date/day";
import "../../components_cfdi/invoice/consult_invoice/container_v2.css"
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";
const MOVEMENTS_v2 = (props) => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [total_rows, settotal_rows] = useState("");

  const dialog = useDialog();

  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedCurrentDate));

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    setMenssage("Productos...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_movements(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'));
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setmodalT(false);
            setMenssage("");
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const columns = useMemo(
    () => [

      {
        id: "movement_date",
        header: "Partida",
        accessorKey: "movement_date",
      },
      {
        id: "warehouse_name",
        header: "Almacén",
        accessorKey: "warehouse_name",
      },
      {
        id: "movement_type",
        header: "Tipo",
        accessorKey: "movement_type",
      },
      {
        id: "note",
        header: "Nota",
        accessorKey: "note",
      },
      {
        id: "fullusername",
        header: "Usuario",
        accessorKey: "fullusername",
      },
      {
        id: "sale",
        header: "Venta",
        accessorKey: "sale",
      },
      {
        id: "returns",
        header: "Devoluciones",
        accessorKey: "returns",
      },
    ],
    []
  );

  const update_identifiers =
    [
      { movement_date: 'string' },
      { warehouse_name: 'string' },
      { movement_type: 'String' },
      { fullusername: 'string' },
      { sale: 'string' },
      { returns: 'string' }
    ];

    
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);

   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const filters_custom = (
      <SEARCH_FILTERS
              fi={fi}
              setfi={setfi}
              ff={ff}
              setff={setff}
              handleSubmit={handleSubmit}
      ></SEARCH_FILTERS>
    );


  return (
    <div className="new_table_v2">
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <BREADCRUMBS niveles={
        [
          { label: "MOVIMIENTO DE PRODUCTOS", path: null },
        ]
      }
      ></BREADCRUMBS>
        <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
      <div className="new_table_v2">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          onStateChange={handleStateChange}
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Crear Movimiento de Producto"
                    src={CREATE}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <SET_MOVEMENTS></SET_MOVEMENTS>,
                        {
                          className: "custom-modal",
                          title: "Crear Movimiento de Producto",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => 
          {
            if(minimenu===false){
              return(
                <>
                {filters_custom}
                </>
              )
            }
          }}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <SPECIAL_ACTIONS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              row={row}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              copy={props.copy}
            // alertas={alertas}
            />
          ]}
          renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Movimiento de Productos"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
          }
        />
      </div>
    </div>
  );
};

export default MOVEMENTS_v2;
