import { Box, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { get_route_list } from '../../../services/businesspartners/businesspartners';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { put_competition_unilever_points_of_sale } from '../../../services/unilever/unilever';
export const DETAILS_TICKETS = (props) => {
  
  const dataoriginal = props?.row?.original?.concourse_data;
  const [data, setData] = useState(() => JSON.parse(JSON.stringify(dataoriginal)));
  const dialog = useDialog();
  const [dataroute, setdataroute] = useState([]); 
  const [route, setroute] = useState(props?.row?.original?.route);

  const handleupdatename = (item, key)=>{
    const updatedData = [...data];
    updatedData[key].ticket = item;
    setData(updatedData);
  }
  const handleupdateamount = (item, key)=>{
    const updatedData = [...data];
    updatedData[key].amount = item;
    updatedData[key].error = false;
    setData(updatedData);
  }
  
  const validate = (item, key) =>{
  
    const newAmount = parseFloat(item);
    const updatedData = [...data];
    updatedData[key].amount = parseFloat(newAmount);
    updatedData.forEach((entry) => (entry.error = false));  
    for (let i = 1; i < updatedData.length; i++) {
      if (updatedData[i].amount <= updatedData[i - 1].amount) {
        updatedData[i].error = true;
      }
    }
    setData(updatedData);

  }


  const handleroute = async() =>{
    try{
        const r = await get_route_list();
        setdataroute(
          r?.data?.parametersList?.sort((a, b) => Number(a.route) - Number(b.route))
        );
    }catch(err){
      console.log(err);
    }
  }

  const handleupdate = async() =>{

    data?.map((item, index)=>{
      validate(item?.amount, index);
    })
    const err = data.some((item) => item.error === true);
    if(err){
      props.alertas("Error verifica los montos de los tickets", false);
    }else{
      const newData = data.map(({ error, ...rest }) => rest);
      var dat = {
        point_sale_code: props?.row?.original?.point_sale_code,
        route: route,
        concourse_data: newData
      }
      props?.setmodalT(true);    
      try{
        const rf = await refresh_token();
        if(rf){
          setTimeout(async() => {
            try{
              const d = await put_competition_unilever_points_of_sale(props?.id, dat);
              console.log(d);
              props.alertas("Registro actualizado con éxito", true);
              props.setmodalT(false);
              dialog.close();
              props.handlesubmit();
            }catch(err){
              console.log(err);
              props?.setmodalT(false);
              props.alertas("Error al actualizar", false);
            }
          }, 1500);
        }
      }catch(err){
        console.log(err);
        props?.setmodalT(false);
      }
    }
  }

  useEffect(() => {
    handleroute();
  }, [])

  return (
    <div>
      <div className='form-concurs-updates-inputs'>
        <div className='route-list-contests'>
          <TextField
              select
              label="Ruta"
              value={route}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setroute(event.target.value)}  
            >
              {
                dataroute?.map((item, index)=>(
                  <option key={index} value={item?.route}>{item?.route}</option>
                ))
              }
            </TextField>
        </div>
        {
          data.map((item, index)=>(
            <div className='grid-concurse-items-edit' key={index}>
              <div className=''>
                <TextField
                  label="Ticket"
                  value={item?.ticket}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => handleupdatename(event.target.value, index)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className=''>
                <TextField
                  label="Monto"
                  value={item?.amount}
                  error={item?.error}
                  sx={{ m: 1.5, width: "100%" }}
                  helperText={item?.error ? "El monto debe ser mayor" : ""}
                  onChange={(event) => handleupdateamount(event.target.value, index)}
                  onBlur={(event)=>validate(event.target.value, index)}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>

            </div>
          ))
        }
      </div>
      <div className='buttonactionsdetailstickets'>
        <Box className="button-action-job-opp">
            <Button
              variant="contained"
              style={{"backgroundColor":"#1976d2"}}
              sx={{ m: 1.5, width: "25ch" }}
              onClick={()=>{handleupdate()}}
            >ACTUALIZAR
            </Button>
            <Button
              style={{"backgroundColor":"white", "color":"#1976d2"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  )
}
