import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../../imgComponents/save.png";
import pdf from "../../../../imgComponents/pdf.png";
import { OPERATIONS_APPLICANTS } from "./operations";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const download = async( ) =>{
        const url = props?.row?.original?.resume_url;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('No se pudo descargar el archivo');
            }

            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'CV.pdf'; 
            link.click();
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    }
    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATIONS_APPLICANTS 
                            operation={"EDIT"}  
                            row={props?.row} 
                            handlesubmit={props?.handlesubmit} 
                            alertas={props?.alertas} 
                            setmodalT={props.setmodalT}
                        />,
                        {
                            className: "custom-modal-2",
                            title: "DETALLES DE SOLICITUD",
                            showCloseIcon: true,
                        }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Detalles de Solicitud"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles de Solicitud
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    download();
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={pdf}
                        title="Descargar CV"
                        className=""
                    ></img>
                </ListItemIcon>
                Descargar CV
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
