import axios from "axios";
import config from "../../config";
const urlcc = `${config?.baseURLClC}`;

//PAY_CONDITIONS

export const get_pay_conditions = async (enable:boolean) => {
  const url = `${urlcc}/od/pay-condition?enable=${enable}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const post_pay_conditions = async (data:{}) => {
  const url = `${urlcc}/od/pay-condition`;
  try {
    const response = await axios.post(
      url, data,
      {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const put_pay_conditions = async (data:{},id: number) => {
  const url = `${urlcc}/od/pay-condition/${id}`;
  try {
    const response = await axios.put(
      url, data,
      {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const delete_pay_conditions = async (id:number) => {
  const url = `${urlcc}/od/pay-condition/${id}`;
  try {
    const response = await axios.delete(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};