export const inf_pay_condition = [
    {
        label: "Días de crédito",
        accessor: "term",
        sm: 30,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
    },

    {
        label: "",
        accessor: "enable",
        sm: 30,
        dfl: true,
        type: "select",
        data_select: [{id:1,name:"SI", value:true},{id:2,name:"NO", value:false}],
        name_select: "Habilitado",
    },
];



// export const inf_pay_condition = [
//     {
//         label: "Días de crédito",
//         accessor: "term",
//         sm: 12,
//         dfl: 0,
//         type: "number",
//         data_select: [],
//         name_select: "",
//     },
//     {
//         label: "Días de crédito",
//         accessor: "term_select",
//         sm: 12,
//         dfl: "prueba 1",
//         type: "select",
//         data_select: [{id:1,name:"prueba 1", value:"prueba 1"},{id:1,name:"prueba 2", value:"prueba 2"}],
//         name_select: "Selecciona Algo",
//     },

//     {
//         label: "Fecha Pago",
//         accessor: "date_paid",
//         sm: 12,
//         dfl: "",
//         type: "date",
//         data_select: [],
//         name_select: "",
//     },
// ];



