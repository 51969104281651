import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import estados from "../../dataComponets/state.json";
import { Checkbox } from "semantic-ui-react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import Dir from "../../imgComponents/direccion.png"
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_businesspartners_list, get_clasification, get_requirements, get_route_list, get_sectors, post_points_of_sale } from "../../services/businesspartners/businesspartners";

function CreatePointOfSale(props) {

  //requerimentos
  const [datareq, setdatareq] = useState([]);
  const [routedata, setroutedata] = useState([]);
  const [businesdata, setbusinesdata] = useState([]);
  const [sectordata, setsectordata] = useState([]);
  const [sectordatafilter, setsectordatafilter] = useState([]);
  const [clasificaciones, setclasificaciones] = useState([]);
  const [value, setValue] = useState(0);
  const [subcanales, setsubcanales] = useState([]);
  const [subcanalesf, setsubcanalesf] = useState([]);
  const dialog = useDialog();

  //variables del formulario
  const [codigo, setcodigo] = useState(0); //obligatorio
  const [nombre, setnombre] = useState(null); //obligatorio  //si
  const [codigoU, setcodigou] = useState(null); //si
  const [gdeuda, setgdeuda] = useState(true); //si
  const [limcredito, setlimcredito] = useState(0.00); //si
  const [saldo, setsaldo] = useState(0.00); //si
  const [nota, setnota] = useState(null);  //si
  const [lat, setlat] = useState(null); //obligatorio //si
  const [lng, setlng] = useState(null); //obligatorio //si
  const [numtienda, setnumtienda] = useState(null); //obligatorio //si
  const [folioesp, setfolioesp] = useState(null); //obligatorio //si
  const [canal, setcanal] = useState(null); //obligatorio //si
  const [subcanal, setsubcanal] = useState(null); //obligatorio //si
  const [ruta, setruta] = useState(null); //obligatorio //si
  const [socioneg, setsocioneg] = useState(null); //obligatorio 
  const [condpago, setcondpago] = useState(null); //obligatorio //si
  const [listprecios, setlistprecios] = useState(null); //obligatorio//si
  const [correo, setcorreo] = useState(null); //si
  const [telefono, settelefono] = useState(null); //si
  const [calle, setcalle] = useState(null); //obligatorio //si
  const [numext, setnumext] = useState(null); //obligatorio //si
  const [numint, setnumint] = useState(null);//si
  const [colonia, setcolonia] = useState(null); //obligatorio //si
  const [cp, setcp] = useState(null); //obligatorio //si
  const [munic, setmunic] = useState(null); //obligatorio //si
  const [estado, setestado] = useState(null); //obligatorio//si
  const [local, setlocal] = useState(null); //obligatorio//si
  const [pais, setpais] = useState("MÉXICO"); //obligatorio//si
  const [refer, setrefer] = useState(null); //si
  const [tipdir, settipdir] = useState("ENTREGA");//si 
  const [sucursal, setsucursal] = useState("MATRIZ");//si
  const [visit, setvisit] = useState(null); //obligatorio //si
  const [frecvisit, setfrecvisit] = useState(null); //obligatorio //si
  const [clasificacion, setclasificacion] = useState(null); //si
  //para eliminar o actualizar la relacion con el punto de venta con el sector dirigirse al modulo de sectores
  const [sector, setsector] = useState(null);
  const [ordensector, setordensector] = useState(null);
  const [bandera, setbandera] = useState(true);

  useEffect(() => {
    handleSubmit();
    handleroute();
    handlebusines();
    handlesectors();
    handleclasificacion();
  }, []);



  function Spinner() {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }

  const handleSubmit = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_requirements();
            setdatareq(d === null ? [] : d.data);
            setsubcanales(d === null ? [] : d.data.subchannels);
            setsubcanalesf(d === null ? [] : d.data.subchannels);
            setcodigo((d === null ? [] : d.data.code).toString());
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };
  const handleroute = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_route_list();
            setroutedata(d === null ? [] : d);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  const handlebusines = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_businesspartners_list();
            //setbusinesdata(d === null ? [] : d.data);
            const fil = d?.data?.businessPartnerList?.filter(item=>item.business_partner_type === "CLIENTE" || item.business_partner_type === "AMBOS");
            console.log(fil);
            setbusinesdata(fil === null ? [] : fil); 
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log(error);
    }
  }
  const handlesectors = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_sectors();
            setsectordata(d === null ? [] : d.data.sectors);
            setsectordatafilter(d === null ? [] : d.data.sectors);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  const handleclasificacion = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_clasification();
            setclasificaciones(d === null ? [] : d.data);
          } catch (err) {
            console.log(err);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log(error);
    }
  }
  const addpointsale = async () => {

    //verificacion de datos obligatorios para crear un punto de venta 
    if (nombre === null || lat === null || lng === null || correo === null || telefono === null || folioesp === null || canal === null
      || subcanal === null || ruta === null || socioneg === null || condpago === null || listprecios === null
      || numext === null || calle === null || colonia === null || cp === null || munic === null || estado === null
      || local === null || visit === null || frecvisit === null) {
      props.alertas("Parámetros faltantes", false);
    } else {
      props.setmodalT(true);
      await refresh_token();
      setbandera(false);

      var data = {
        "code": codigo,
        "editable": enable === "true" ? true : false,
        "name": nombre,
        "outerCode": codigoU,
        "generatesDebt": gdeuda === "true" ? true : (gdeuda === "false" ? false : true),
        "creditLimit": limcredito,
        "balance": saldo,
        "note": nota,
        "latitude": parseFloat(lat),
        "longitude": parseFloat(lng),
        "numberOxxo": numtienda,
        "idSpecialNumberFolio": parseInt(folioesp),
        "idChannel": parseInt(canal),
        "idSubchannel": parseInt(subcanal),
        "idRoute": parseInt(ruta),
        "idBusinessPartner": parseInt(socioneg),
        "idConditionPay": parseInt(condpago),
        "idPriceList": parseInt(listprecios),
        "email": correo,
        "phone": telefono,
        "frequency": frecvisit,
        "visitDays": visit,
        "classification": clasificacion,
        "calle": calle,
        "numExt": numext,
        "numInt": numint,
        "colonia": colonia,
        "codigoPostal": cp,
        "municipio": munic,
        "localidad": local,
        "estado": estado,
        "pais": pais,
        "referencia": refer,
        "sucursal": sucursal,
        "tipoEntrega": tipdir,
        "idSector": sector===0 || sector==="0" || sector===null ? null : sector,
        "orderSector": ordensector === "" ? null : (ordensector === null ? null : ordensector)
      }

      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const dt = await post_points_of_sale(data);
              console.log(dt);
              props.alertas("Punto de Venta agregado con éxito", true);
              props.setmodalT(false);
              dialog.close();
              props.handleSubmit();
            } catch (err) {
              catchErrorModul("Puntos de Venta", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
              if (err.response.status === 500) {
                props.alertas("No se pudo crear el punto de venta", false);
                props.setmodalT(false);
                setbandera(true);
              } else {
                props.alertas(err.response.data.errorMessage);
                props.setmodalT(false);
                setbandera(true);
              }
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    }
  }


  const [formper, setformper] = useState(true);
  const [formdirec, setformdirec] = useState(false);
  const [enable, setenable] = useState(false);

  const Personales = () => {
    setformper(true);
    setformdirec(false);
  }
  const Direccion = () => {

    const filt = businesdata?.filter(item=>item.id === socioneg);
    console.log(filt)
    if(filt.length === 0){
    }else{
      if(filt[0]?.partner_type === "EXTERNO" || filt[0]?.partner_type === "UNILEVER"){
        console.log("entra if de cliente type")
        if(codigoU?.trim() === "" || codigoU === null){
          console.log("entra en el if de vacio")
          setvunilever(true);
          props?.alertas("Al ser un cliente Externo o Unilever debe llenar el campo de 'Código Unilever'", false);
          return null;
        }
      }
    }
    
    nombre === null ? setvnombre(true) : setvnombre(false);
    condpago === null ? setvcondpago(true) : setvcondpago(false);
    correo === null ? setvcorreo(true) : setvcorreo(false);
    telefono === null ? setvtelefono(true) : (telefono.length < 10 ? setvtelefono(true) : setvtelefono(false));
    visit === null ? setvdiasvisit(true) : setvdiasvisit(false);
    frecvisit === null ? setvfrec(true) : setvfrec(false);
    folioesp === null ? setvfolio(true) : setvfolio(false);
    canal === null ? setvcanal(true) : setvcanal(false);
    subcanal === null ? setvsubcanal(true) : setvsubcanal(false);
    ruta === null ? setvruta(true) : setvruta(false);
    socioneg === null ? setvsocio(true) : setvsocio(false);
    listprecios === null ? setvlistp(true) : setvlistp(false);
    clasificacion === null ? setvclasificacion(true) : setvclasificacion(false);

    if (nombre === null || correo === null || telefono === null || condpago === null || visit === null || frecvisit === null ||
      folioesp === null || canal === null || subcanal === null || ruta === null || socioneg === null || listprecios === null || clasificacion === null) {
      props.alertas("Parámetros faltantes", false);
    } else {
      setformper(false);
      setformdirec(true);
    }
  }
  const enviarparametros = () => {

    lat === null ? setvlat(true) : setvlat(false);
    lng === null ? setvlng(true) : setvlng(false);
    calle === null ? setvcalle(true) : setvcalle(false);
    numext === null ? setvnumext(true) : setvnumext(false);
    colonia === null ? setvcolonia(true) : setvcolonia(false);
    local === null ? setvlocalidad(true) : setvlocalidad(false);
    munic === null ? setvmunicipio(true) : setvmunicipio(false);
    cp === null ? setvcp(true) : setvcp(false);
    estado === null ? setvestado(true) : setvestado(false);

    if (lat === null || lng === null || calle === null || numext === null || colonia === null || local === null ||
      munic === null || cp === null || estado === null) {
      props.alertas("Parámetros faltantes", false);
    } else {
      addpointsale();
    }
  }


  const handleChange = () => {
    if (enable === true) {
      setenable(false);
    } else {
      setenable(true);
    }
  };

  const handleOnSearch = () => {
    setsocioneg(0);
  };
  const handleOnHover = () => { };
  const handleOnSelect5 = (item) => {
    setsocioneg(item.id);
    setvsocio(false);
  };
  const handleOnFocus5 = () => {
    //console.log("Focused");
    setvsocio(false);
  };
  const handleOnClear = () => {
    //console.log("Cleared");
  };

  //verificar datos de punto de venta
  const [vnombre, setvnombre] = useState(false);
  const [vcondpago, setvcondpago] = useState(false);
  const [vdiasvisit, setvdiasvisit] = useState(false);
  const [vfrec, setvfrec] = useState(false);
  const [vlistp, setvlistp] = useState(false);
  const [vfolio, setvfolio] = useState(false);
  const [vcanal, setvcanal] = useState(false);
  const [vsubcanal, setvsubcanal] = useState(false);
  const [vruta, setvruta] = useState(false);
  const [vsocio, setvsocio] = useState(false);
  const [vcorreo, setvcorreo] = useState(false);
  const [vtelefono, setvtelefono] = useState(false);
  const [vclasificion, setvclasificacion] = useState(false);
  //verificacion datos de direccion
  const [vlat, setvlat] = useState(false);
  const [vlng, setvlng] = useState(false);
  const [vcalle, setvcalle] = useState(false);
  const [vnumext, setvnumext] = useState(false);
  const [vcolonia, setvcolonia] = useState(false);
  const [vlocalidad, setvlocalidad] = useState(false);
  const [vmunicipio, setvmunicipio] = useState(false);
  const [vcp, setvcp] = useState(false);
  const [vestado, setvestado] = useState(false);
  const [vunilever, setvunilever] = useState(false);


  //obtener direccion mediante latitud y longitud
  const handleClick = async () => {

    if (bandera === true) {
      if (lat === null || lng === null) {
        props?.alertas("Error en campos de latitud y longitud", false);
      } else {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc`);
        const data = await response.json();
        //console.log(data)
        llenado(data?.results[0])
      }
    } else {
      //console.log("no entra")
    }

  }

  function llenado(m) {

    if(m?.address_components){
      if (m?.address_components?.length === 7) {
        setnumext((m.address_components[0].long_name).toUpperCase());
        setcalle((m.address_components[1].long_name).toUpperCase());
        setcolonia((m.address_components[2].long_name).toUpperCase());
        setmunic((m.address_components[3].long_name).toUpperCase());
        setcp((m.address_components[6].long_name).toUpperCase());
        setestado((m.address_components[4].long_name).toUpperCase());
      }
      if (m?.address_components?.length === 4) {
        setmunic((m.address_components[1].long_name).toUpperCase());
        setestado((m.address_components[2].long_name).toUpperCase());
      }

    }else{
      props?.alertas("No se pudo encontrar la dirección", false);
    }

    
  }

  const handleCorreo = (event) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)+$/;
    const newValue = (event.target.value).split(",");
    var isValid = true;
    for (var i = 0; i < newValue.length; i++) {
      if (!emailRegex.test(newValue[i]?.trim())) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      setcorreo(event.target.value);
      setvcorreo(false);
    } else {
      setvcorreo(true);
    }
  }

  useEffect(() => {
    const filt = businesdata?.filter(item=>item.id === socioneg);
    if(filt.length === 0){
      
    }else{
      if(filt[0]?.partner_type === "EXTERNO" || filt[0]?.partner_type === "UNILEVER"){
        if(codigoU?.trim() === "" || codigoU === null){
          setvunilever(true);
        }
      }
    }
  }, [socioneg])
  

  return (
    <div style={{"textAlign":"center"}}>
      <div className="formulariopointofsale">
        <Box>
          <br />
          <div className="pestañas">

            <div className="navegacioncompany">
              <Box sx={{ margin: "0 auto", width: 400 }}>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                >
                  <BottomNavigationAction style={formper === false ? { "backgroundColor": "#E7E7E7" } : {}} label="Información General" icon={<PersonIcon onClick={Personales} />} onClick={Personales} />
                  <BottomNavigationAction style={formdirec === false ? { "backgroundColor": "#E7E7E7" } : {}} label="Dirección" icon={<RoomIcon onClick={Direccion} />} onClick={Direccion} />

                </BottomNavigation>
              </Box>
            </div>
          </div>
          <br />
        </Box>


        <div
          style={formper === true ? { "display": "block" } : { "display": "none" }}
        >
          <div className="grid01">
            <div className="item1">
              {
                enable === true ?
                  <TextField
                    label="Código"
                    required
                    disabled={!bandera}
                    value={codigo}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setcodigo(event.target.value)}
                    InputLabelProps={{ shrink: true }}
                  >
                  </TextField> :
                  <TextField
                    label="Código"
                    value={codigo}
                    disabled
                    sx={{ m: 1.5, width: "100%" }}
                  ></TextField>

              }
            </div>
            <div className="itemch">
              <Checkbox
                checked={enable}
                disabled={!bandera}
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
                style={{ "paddingTop": "30px" }}
              />

            </div>



            <div className="item2">
              <TextField
                label="Nombre"
                required
                disabled={!bandera}
                value={nombre}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setnombre(event.target.value.toUpperCase())}
                style={
                  vnombre === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvnombre(false)}
                onBlur={
                  nombre == null ? () => setvnombre(true) : () => setvnombre(false)
                }
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vnombre === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item3">

              <TextField
                label="Código Unilever"
                disabled={!bandera}
                value={codigoU}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => {
                  setcodigou(event.target.value.toUpperCase())  
                  const val = props?.data?.filter(item=>item.outer_code === event?.target?.value);
                  if(val.length>0){
                    props?.alertas("Ya existe el código Unilever registrado en otro Punto de Venta", false);
                  }
                }}
                InputLabelProps={{ shrink: true }}
                style={vunilever === true ? {
                    "border": "2px solid red", "borderRadius": "5px"
                  } : {}
                }
                onSelect={() => setvunilever(false)}
              ></TextField>
              <p className="p_alert" style={vunilever === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>
            </div>
          </div>
          <div className="grid02">
            <div className="item4">
              <TextField
                label="Número de tienda"
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                value={numtienda}
                onChange={(event) => setnumtienda(event.target.value.toUpperCase())}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="item5">
              <TextField
                label="Nota"
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                value={nota}
                onChange={(event) => setnota(event.target.value.toUpperCase())}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>

          <div className="grid02">
            <div className="item6">
              <TextField
                label="Correo Electrónico"
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                required
                value={correo}
                onChange={(event) => setcorreo(event.target.value)}
                style={vcorreo === true ? {
                  "border": "2px solid red", "borderRadius": "5px"
                } : {}
                }
                onSelect={() => setvcorreo(false)}
                onBlur={handleCorreo}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vcorreo === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>
            </div>
            <div className="item7">
              <TextField
                label="Teléfono(s)"
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                type={"number"}
                required
                value={telefono}
                onChange={(event) => settelefono(event.target.value.slice(0, 10))}
                style={vtelefono === true ? {
                  "border": "2px solid red", "borderRadius": "5px"
                } : {}
                }
                onSelect={() => setvtelefono(false)}
                onBlur={
                  telefono == null ? () => setvtelefono(true) : (telefono.length < 10 ? () => setvtelefono(true) : () => setvtelefono(false))
                }
                title="Ingrese su número de telefono sin guiones"

                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vtelefono === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
          </div>

          <div className="grid03">
            <div className="item8">
              <TextField
                select
                label="Genera Deuda"
                disabled={!bandera}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setgdeuda(event.target.value)}
              >
                <option key={1} value={true} selected >
                  {"Si (Es Cliente Directo)"}
                </option>
                <option key={2} value={false} >
                  {"No (Es Cliente Unilever)"}
                </option>
              </TextField>
            </div>
            <div className="item9">
              <TextField
                label="Límite de Crédito"
                disabled={!bandera}
                type={"number"}
                value={0.00}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlimcredito(event.target.value)}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="item10">
              <TextField
                label="Saldo"
                disabled={!bandera}
                value={0.00}
                type={"number"}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setsaldo(event.target.value)}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="item11">
              <TextField
                select
                required
                disabled={!bandera}
                label="Condición de Pago"
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                style={
                  vcondpago === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvcondpago(false)}
                onBlur={
                  condpago == null ? () => setvcondpago(true) : () => setvcondpago(false)
                }
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setcondpago(event.target.value)}
              >
                <option key={0} value={0} selected >
                  {"Seleccione Condición de Pago"}
                </option>
                {
                  datareq?.payConditions?.map((item) => (
                    <option key={item.id} value={item.id} >
                      {item.description}
                    </option>
                  ))
                }
              </TextField>
              <p className="p_alert" style={vcondpago === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>

          </div>
          <div className="grid03pos">

            <div className="item12">
              <div className="inputbusinessearch">
                <ReactSearchAutocomplete
                  items={businesdata}
                  fuseOptions={{ keys: ["code", "business_partner"] }}
                  resultStringKeyName="business_partner"
                  formatResult={(item) => (
                    <span>{item.code} - {item.business_partner}</span>
                  )}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect5}
                  onFocus={handleOnFocus5}
                  onClear={handleOnClear}
                  styling={{
                    height: "40px",
                    border: "1px solid gray",
                    borderRadius: "4px",
                    color: "black",
                    iconColor: "black",
                    lineColor: "black",
                    placeholderColor: "black",
                    zIndex: 10,
                  }}
                  showIcon={true}
                  placeholder="Buscar Socio de Negocio"
                />
                <p className="p_alerta" style={vsocio === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

              </div>

            </div>
            <div className="item13">
              <TextField
                select
                required
                disabled={!bandera}
                label="Días de Visita"
                defaultValue={""}
                style={
                  vdiasvisit === true ? { "border": "2px solid red", "border-radius": "5px" } : {}
                }
                onSelect={() => setvdiasvisit(false)}
                onBlur={
                  visit == null ? () => setvdiasvisit(true) : () => setvdiasvisit(false)
                }
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setvisit(event.target.value)}
              >
                <option key={0} value={0} selected >
                  {"Seleccione Día"}
                </option>
                <option key={1} value={"LUNES"}>LUNES</option>
                <option key={2} value={"MARTES"}>MARTES</option>
                <option key={3} value={"MIERCOLES"}>MIERCOLES</option>
                <option key={4} value={"JUEVES"}>JUEVES</option>
                <option key={5} value={"VIERNES"}>VIERNES</option>
                <option key={6} value={"SABADO"}>SABADO</option>
                <option key={7} value={"DOMINGO"}>DOMINGO</option>
              </TextField>
              <p className="p_alert" style={vdiasvisit === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item14">
              <TextField
                select
                required
                disabled={!bandera}
                label="Frecuencia de Visita"
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setfrecvisit(event.target.value)}
                style={vfrec === true ? {
                  "border": "2px solid red", "border-radius": "5px"
                } : {}
                }
                onSelect={() => setvfrec(false)}
                onBlur={
                  frecvisit == null ? () => setvfrec(true) : () => setvfrec(false)
                }
              >
                <option key={0} value={0} selected >
                  {"Seleccione Frecuencia"}
                </option>
                <option key={1} value={"SEMANAL"}>SEMANAL</option>
                <option key={2} value={"QUICENAL(1-3)"}>QUINCENAL(1-3)</option>
                <option key={3} value={"QUICENAL(2-4)"}>QUINCENAL(2-4)</option>

              </TextField>
              <p className="p_alert" style={vfrec === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>
            </div>
          </div>
          <div className="grid04">
            <div className="item15">
              <TextField
                select
                required
                disabled={!bandera}
                label="Lista de Precios"
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlistprecios(event.target.value)}
                style={vlistp === true ? {
                  "border": "2px solid red", "borderRadius": "5px"
                } : {}
                }
                onSelect={() => setvlistp(false)}
                onBlur={
                  listprecios == null ? () => setvlistp(true) : () => setvlistp(false)
                }
              >
                <option key={0} value={0} selected >
                  {"Seleccione Lista de Precios"}
                </option>
                {
                  datareq?.priceLists?.map((item) => (
                    <option key={item.id} value={item.id} >
                      {item.name}
                    </option>
                  ))
                }
              </TextField>
              <p className="p_alert" style={vlistp === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item16">
              <TextField
                select
                required
                label="Folios Especiales"
                disabled={!bandera}
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setfolioesp(event.target.value)}
                style={vfolio === true ? {
                  "border": "2px solid red", "borderRadius": "5px"
                } : {}
                }
                onSelect={() => setvfolio(false)}
                onBlur={
                  folioesp == null ? () => setvfolio(true) : () => setvfolio(false)
                }
              >
                <option key={0} value={0} selected >
                  {"Seleccione Folio"}
                </option>
                {
                  datareq?.specialFolios?.map((item) => (
                    <option key={item.id} value={item.id} >
                      {item.folio_name}
                    </option>
                  ))
                }
              </TextField>
              <p className="p_alert" style={vfolio === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item17">
              <TextField
                select
                required
                disabled={!bandera}
                label="Canal"
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setcanal(event.target.value)
                  && (event.target.value === "0") ? "" : setsubcanales(subcanalesf.filter(item => item.id_channel_fk == event.target.value))
                }
                style={vcanal === true ? {
                  "border": "2px solid red", "border-radius": "5px"
                } : {}
                }
                onSelect={() => setvcanal(false)}
                onBlur={
                  canal == null ? () => setvcanal(true) : () => setvcanal(false)
                }
              >
                <option key={0} value={0} selected >
                  {"Seleccione Canal"}
                </option>
                {
                  datareq?.channels?.map((item) => (
                    <option key={item.id} value={item.id} >
                      {item.description}
                    </option>
                  ))
                }
              </TextField>
              <p className="p_alert" style={vcanal === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item18">
              <TextField
                select
                required
                label="Subcanal"
                disabled={!bandera}
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setsubcanal(event.target.value)}
                style={vsubcanal === true ? {
                  "border": "2px solid red", "border-radius": "5px"
                } : {}
                }
                onSelect={(event) => setvsubcanal(false)}
                onBlur={
                  subcanal == null ? (event) => setvsubcanal(true) : (event) => setvsubcanal(false)
                }
              >
                <option key={0} value={0} selected >
                  {"Seleccione Subcanal"}
                </option>
                {
                  subcanales.map((item) => (
                    <option key={item.id} value={item.id} >
                      {item.description}
                    </option>
                  ))
                }
              </TextField>
              <p className="p_alert" style={vsubcanal === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
          </div>
          <div className="grid05">
            <div className="item19">
              <TextField
                select
                required
                label="Ruta"
                disabled={!bandera}
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setruta(event.target.value) &&
                  (event.target.value === "100") ?
                  console.log("no entra") : setsectordata(sectordatafilter.filter(item => item.id_route_fk == event.target.value))}
                style={vruta === true ? {
                  "border": "2px solid red", "borderRadius": "5px"
                } : {}
                }
                onSelect={() => setvruta(false)}
                onBlur={
                  ruta == null ? () => setvruta(true) : () => setvruta(false)
                }

              >
                <option key={0} value={0} selected >
                  {"Seleccione Ruta"}
                </option>
                {
                  routedata?.data?.parametersList?.map((item) => (
                    <option key={item.idRoute} value={item.idRoute}>
                      {item.route}
                    </option>
                  ))
                }
              </TextField>
              <p className="p_alert" style={vruta === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item20">
              <TextField
                select
                label="Sector"
                disabled={!bandera}
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setsector(event.target.value)}
              >
                <option key={0} value={0} selected >
                  {"Seleccione Sector"}
                </option>
                {
                  sectordata?.map((item) => (
                    <option key={item.id} value={item.id} >
                      {item.number_sector + " - " + item.name_sector}
                    </option>
                  ))
                }
              </TextField>
            </div>
            <div className="item21">
              <TextField
                label="Orden del sector"
                type={"number"}
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setordensector(event.target.value)}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="item21b">
              <TextField
                select
                label="Clasificacion"
                disabled={!bandera}
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setclasificacion(event.target.value)}
                style={vclasificion === true ? {
                  "border": "2px solid red", "borderRadius": "5px"
                } : {}
                }
                onSelect={() => setvclasificacion(false)}
                onBlur={
                  clasificacion == null ? () => setvclasificacion(true) : () => setvclasificacion(false)
                }
              >
                <option key={0} value={0} selected >
                  {"Seleccione clasificación"}
                </option>
                {
                  clasificaciones?.map((item) => (
                    <option key={item.id} value={item.clasification}>
                      {item.clasification}
                    </option>
                  ))
                }

              </TextField>
              <p className="p_alert" style={vclasificion === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>

          </div>
          <br></br>
          <Box>
            <Button
              variant="contained"
              style={{ "backgroundColor": "green" }}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={Direccion}
            >
              Siguiente
            </Button>
          </Box>
        </div>

        <div
          style={formdirec === true ? { "display": "block" } : { "display": "none" }}
        >




          {/* <button onClick={handleClick}>Obtener Dirección</button> */}

          <div className="gridd01">
            <div className="item22">
              <TextField
                label="Latitud"
                disabled={!bandera}
                value={lat}
                required
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlat(event.target.value)}
                style={
                  vlat === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvlat(false)}
                onBlur={
                  lat === null ? () => setvlat(true) : () => setvlat(false)
                }

                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vlat === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item23">
              <TextField
                label="Longitud"
                disabled={!bandera}
                required
                value={lng}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlng(event.target.value)}
                style={
                  vlng === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvlng(false)}
                onBlur={
                  lng == null ? () => setvlng(true) : () => setvlng(false)
                }

                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vlng === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>

            <div className="item23bb">
              <img id="crearsector" src={Dir} onClick={handleClick} title="Buscar Dirección" alt=""
              ></img>
            </div>

            <div className="item24">
              <TextField
                label="Calle"
                value={calle}
                disabled={!bandera}
                required
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setcalle(event.target.value.toUpperCase())}
                style={
                  vcalle === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvcalle(false)}
                onBlur={
                  calle == null ? () => setvcalle(true) : () => setvcalle(false)
                }

                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vcalle === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>

          </div>
          <div className="gridd02">
            <div className="item25">
              <TextField
                label="Número Exterior"
                required
                disabled={!bandera}
                value={numext}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setnumext(event.target.value)}
                style={
                  vnumext === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvnumext(false)}
                onBlur={
                  numext === null ? () => setvnumext(true) : () => setvnumext(false)
                }

                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vnumext === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item26">
              <TextField
                label="Número Interior"
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setnumint(event.target.value)}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="item27">
              <TextField
                label="Colonia"
                required
                value={colonia}
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setcolonia(event.target.value.toUpperCase())}
                style={
                  vcolonia === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvcolonia(false)}
                onBlur={
                  colonia == null ? () => setvcolonia(true) : () => setvcolonia(false)
                }

                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vcolonia === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item28">
              <TextField
                label="Localidad"
                disabled={!bandera}
                value={local}
                required
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setlocal(event.target.value.toUpperCase())}
                style={
                  vlocalidad === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvlocalidad(false)}
                onBlur={
                  local == null ? () => setvlocalidad(true) : () => setvlocalidad(false)
                }
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vlocalidad === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>


          </div>
          <div className="gridd03">
            <div className="item29">
              <TextField
                label="Municipio"
                disabled={!bandera}
                value={munic}
                required
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) =>{
                  const value = event.target.value.toUpperCase();
                  if (/^[A-Z\s]*$/.test(value)) {
                    setmunic(value);
                  } 
                }}
                style={
                  vmunicipio === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvmunicipio(false)}
                onBlur={
                  munic == null ? () => setvmunicipio(true) : () => setvmunicipio(false)
                }
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vmunicipio === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item30">
              <TextField
                label="Código Postal"
                type={"number"}
                disabled={!bandera}
                value={cp}
                required
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setcp(event.target.value.slice(0, 5))}
                style={
                  vcp === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvcp(false)}
                onBlur={
                  cp == null ? () => setvcp(true) : () => setvcp(false)
                }
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <p className="p_alert" style={vcp === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item31">

              <TextField
                select
                required
                disabled={!bandera}
                label="Estado"
                value={estado}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                style={
                  vestado === true ? { "border": "2px solid red", "borderRadius": "5px" } : {}
                }
                onSelect={() => setvestado(false)}
                onBlur={
                  estado === null ? () => setvestado(true) : () => setvestado(false)
                }
                onChange={(event) => setestado(event.target.value)}
              >
                {
                  estados.map(item => (
                    <option key={item.clave} value={item.label}>
                      {item.label}
                    </option>
                  ))
                }

              </TextField>
              <p className="p_alert" style={vestado === true ? { "display": "block" } : { "display": "none" }}>Completa este campo </p>

            </div>
            <div className="item32">
              <TextField
                label="País"
                disabled={!bandera}
                value={pais}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setpais(event.target.value)}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>
          <div className="gridd04">
            <div className="item33">
              <TextField
                label="Referencia"
                disabled={!bandera}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setrefer(event.target.value.toUpperCase())}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="item34">
              <TextField
                select
                label="Sucursal"
                disabled={!bandera}
                defaultValue={""}
                InputLabelProps={{ shrink: true, }}
                SelectProps={{ native: true, }}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setsucursal(event.target.value)}
              >
                <option key={1} value={"MATRIZ"} selected >
                  {"MATRIZ"}
                </option>
                <option key={2} value={"SUCURSAL"}>
                  {"SUCURSAL"}
                </option>
              </TextField>
            </div>
          </div>

          <br></br>
          <Box>
            {
              bandera === true ?
                <Button
                  variant="contained"
                  style={{ "backgroundColor": "green" }}
                  sx={{ m: 1.5, width: "30ch" }}
                  onClick={enviarparametros}
                >
                  Agregar Punto de Venta
                </Button> :
                <Button
                  variant="contained"
                  disabled
                  style={{ "backgroundColor": "green", "height": "35px" }}
                  sx={{ m: 1.5, width: "30ch" }}
                ><Spinner /></Button>
            }

            <Button
              style={{ "backgroundColor": "red" }}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                dialog.close();
              }
              }
            >
              cancelar
            </Button>
          </Box>
        </div>


        <div>
        </div>
      </div>
    </div>
  );
}

export default CreatePointOfSale;
