// Importaciones de paquetes
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";

// Importaciones de componentes
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_pay_conditions,post_pay_conditions,put_pay_conditions } from "../../services/banck/bank";
// import SEARCH_FILTERS from "./tools/shearch_filter";
import { UPDATE_NULL_IDENTIFIERS } from "../../main_components/methods_v2/auxiliary_functions_new_method";
import { PAY_CONDITIONS, RESPONSE } from "./interface/interface";
import MATERIAL_TABLE_CONTAINER from "../../main_components/material_table/table_container";
import GENERAL_FORM from "../../main_components/material_table/general_form";
import { updated_form_data,validate_data,processed_format } from "./tools/auxiliar_funtions";
//IMG / ICONS

import CREATE from "../../imgComponents/create.png";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import { inf_pay_condition } from "./tools/inf_pay_condition";
import SEARCH_FILTERS from "./tools/search_filters";

export interface FormData {
  term: string;
  description: string;
  term_select: any;
  date_paid:any
}


const PAY_CONDITION: React.FC = () => {
  const [data, setData] = useState<PAY_CONDITIONS[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalT, setmodalT] = useState<boolean>(false);
  const [open_form, setopen_form] = useState(false);
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [formData, setformData] = useState([]);   
  const [methodDelete, setMethodDelete] = useState(false);
  const [value_select,setvalue_select] = useState(true)
  const [identifier_data, setidentifier_data] = useState  <any> (null);

  const update_identifiers = [
    { id: "string" },
    { description: "string" },
    { term: "String" },
  ];

  
  const handle_open_form = (new_value: any, isNew: boolean) => {
    setformData(new_value)
    setIsNewEntry(isNew);
    setopen_form(true);
    setMethodDelete(false)
  };

  const handle_close = () => {
    setopen_form(false);
  };

  const handle_add_new_data = () => {
    const new_value = {
      term: 1,
      description: "CRÉDITO 1 DÍA",
      enable:true,
    };
    handle_open_form(new_value, true);
  };
 



  const handle_save_data = async (finalObject: any, method: any , identifier_data:any) => {
    console.log("Datos a procesar:", finalObject);
    console.log("True / Post, False / Put, Delete:", method);

    // Mostrar modal
    setmodalT(true);
  
    try {
      switch (method) {
        case true:
          console.log("Creando objeto...");
          await post_pay_conditions(finalObject);
          console.log("Objeto creado con éxito");
          break;

        case false:
          console.log("Actualizando objeto...");
          await put_pay_conditions(finalObject,identifier_data);
          console.log("Objeto actualizado con éxito");
          break;

        default:
          console.error("Método no reconocido");
      }
    } catch (error) {
      console.error("Error al procesar los datos:", error);
    } finally {
      // Cerrar modal y realizar otras acciones comunes
      setmodalT(false);
      handle_get_pay_conditions();
      handle_close();
      console.log("Operación finalizada.");
    }
  };
  

  const handle_get_pay_conditions = async (event?: React.SyntheticEvent): Promise<void> => {
    event?.preventDefault();
    
    setIsLoading(true);
    setmodalT(true);
    setData([]);

    try {
      const rf_token: boolean = await refresh_token();
      if (rf_token) {
        setTimeout(async () => {
          try {
            const response: RESPONSE = await get_pay_conditions(value_select);
            const response_pay_conditions = UPDATE_NULL_IDENTIFIERS(response?.data,update_identifiers) as PAY_CONDITIONS[];
            setData(response_pay_conditions);

          } catch (err) {
            console.error("Error:", err);
            setData([]);
          } finally {
            setIsLoading(false);
            setmodalT(false);
          }
        }, 1000);
      }
    } catch (err) {
      console.error("Error in refresh token or other process:", err);
    }
  };


  const columns: MRT_ColumnDef<PAY_CONDITIONS>[] = useMemo(
    () => [


      {
        id: "AC.",
        header: "AC.",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        accessorFn: (d)=>{
          console.log(d.id)
          return(
            <div>
              <span onClick={()=>{
                setMethodDelete(true);
                setopen_form(true);
                setidentifier_data(d?.id)
                }}>
              <DeleteIcon className="icons_general_delete"></DeleteIcon>
              </span>

              <span onClick={()=>{
                setMethodDelete(true);
                handle_open_form(d,false)
                setidentifier_data(d?.id)
                }}>
              <SaveAsIcon className="icons_general_save"></SaveAsIcon>
              </span>

              
              {/*  */}
            </div>
          )
        },
        size: 100,
        muiTableHeaderCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },


      {
        id: "description",
        header: "description",
        accessorKey: "description",
        size: 300,
        muiTableHeaderCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "term",
        header: "term",
        accessorKey: "term",
        size: 180,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  const handleChange = (e:any) => {
    setvalue_select(e)
  };




  useEffect(() => {
    handle_get_pay_conditions();
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }} className="new_table_v2">

      <GENERAL_FORM
        open={open_form}
        onClose={handle_close}
        data_form={inf_pay_condition}
        onSave={handle_save_data}
        isNew={isNewEntry}
        title_create="CREAR CONDICIÓN DE PAGO"
        title_update="ACTUALIZAR CONDICIONES DE PAGOS"
        validateField={validate_data}  
        handleProcessedFormat={processed_format}  
        FormData={FormData}  
        original_data={formData}
        size={methodDelete?"30%":"380px"}
        method_delete={methodDelete}
        message_delete="¿Esta seguro de eliminar esta condición de pago?"
        updatedFormData={updated_form_data}
        identifier_data={identifier_data}
      /> 

      <MATERIAL_TABLE_CONTAINER
        columns={columns}
        data={data}
        breadcrumbs={[{ label: "CONDICIONES DE PAGO", path: null }]}
        modalT={modalT}
        isLoading={isLoading}
        search_filters={<SEARCH_FILTERS value_select={value_select} handleChange={(e)=>handleChange(e)}  handle_seach={()=>handle_get_pay_conditions}loading={false}></SEARCH_FILTERS>}
        component_header={<img style={{cursor:'pointer'}} src={CREATE} onClick={()=> handle_add_new_data()}></img>}
        file_name_export="CONDICIONES DE PAGOS"
        update_identifiers={update_identifiers}
      ></MATERIAL_TABLE_CONTAINER>

    </div>
  );
};

export default PAY_CONDITION;
