import { useEffect, useMemo, useRef, useState, Fragment } from 'react';
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomDialog } from "react-st-modal";

import { ReactSearchAutocomplete } from "react-search-autocomplete";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { pagination_v2 } from '../../../main_components/pagination/pagination_v2';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import GRAPH from "./pie_chart_with_customized_label"
function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
const SEARCH_FILTERS = (props) => {
  const [businesspartners_data, setbusinesspartners_data] = useState([]);
  const [pointsofsale_data, setpointsofsale_data] = useState([]);
  const [options, setOptions] = useState([]);
  const [shearch_data, setshearch_data] = useState(props.invoicing);
  const [shearch_old_balance_data, setshearch_old_balance_data] = useState(props.shearch_old_balance_data);

  const [restriction_data, setrestriction] = useState(props.restriction_dt);
  const [id_shearch, setid_shearch] = useState(props.id_shearch_dt);
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    setid_shearch(props.id_shearch_dt)
    setrestriction(props.restriction_dt);
  }, [props.id_shearch_dt, props.restriction_dt])
  useEffect(() => {
    setshearch_old_balance_data(props.shearch_old_balance_data);
  }, [props.shearch_old_balance_data])
  const handle_businesspartners = async (a, b) => {
    try {
      try {
        var dt = await pagination_v2(5, "aux1", null, null, null, null);
        var dta = dt.map((d) => { return { name: d.business_partner, id: d.id } });
        const uniqueIdsSet = new Set(dta.map(item => item.name));
        const uniqueArray = [...uniqueIdsSet].map(uniqueId => {
          return dta.find(item => item.name === uniqueId);
        });
        setbusinesspartners_data(uniqueArray);
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setbusinesspartners_data([]);
    }
  };

  const handle_pointsofsale = async (a, b) => {
    try {
      try {
        var dt = await pagination_v2(5, "aux2", null, null, null, null);
        setpointsofsale_data(dt);
        var dta = dt.map((d) => { return { name: d.name, id: d.id } });
        const uniqueIdsSet = new Set(dta.map(item => item.name));
        const uniqueArray = [...uniqueIdsSet].map(uniqueId => {
          return dta.find(item => item.name === uniqueId);
        });
        setpointsofsale_data(uniqueArray);

      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setpointsofsale_data([]);
    }
  };


  useEffect(() => {
    handle_businesspartners();
    handle_pointsofsale();
  }, []);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        setOptions([...restriction_data === "SC" ? [] : restriction_data === "SN" ? businesspartners_data : pointsofsale_data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>
      <FormControl sx={{ width: 150,display:props.old_balance?"inline-block":"none" }}>
        <InputLabel id="demo-simple-select-required-label">Deudas</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={shearch_old_balance_data}
          label="Deudas "
          sx={{ height: '35px' }}
          onChange={(e) => props.setshearch_old_balance_data(e.target.value)}
        >
          <MenuItem value={0}>PENDIENTE</MenuItem>
          <MenuItem value={1}>PAGADO</MenuItem>

        </Select>
      </FormControl>
      <FormControl sx={{ width: 120 }}>
        <InputLabel id="demo-simple-select-required-label">Busqueda*</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={shearch_data}
          sx={{ height: '35px' }}
          label="Busqueda "
          onChange={(e) => { setshearch_data(e.target.value); props.setinvoicing(e.target.value) }}
        >
          <MenuItem value={true}>FACTURA</MenuItem>
          <MenuItem value={false}>TICKET</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: 120 }}>
        <InputLabel id="demo-simple-select-required-label">Criterio</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={restriction_data}
          label="Criterio "
          sx={{ height: '35px' }}
          onChange={(e) => setrestriction(e.target.value)}
        >
          <MenuItem value={"SN"}>SOCIO DE NEGOCIOS</MenuItem>
          <MenuItem value={"PDV"}>PUNTO DE VENTA</MenuItem>
          <MenuItem value={"SC"}>S/N</MenuItem>

        </Select>
      </FormControl>
      
      <FormControl sx={{ width: 200, height: 20 }} >
        <Autocomplete
          id="asynchronous-demo"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(event, value) => {
            if (restriction_data === "SN") {
              props.setid_businesspartners(value?.id);
              props.setid_pointsofsale(null);
              props.setid_shearch_dt(value?.name);
              props.setrestriction_dt("SN")
            } else if (restriction_data === "PDV") {
              props.setid_businesspartners(null);
              props.setid_pointsofsale(value?.id);
              props.setid_shearch_dt(value?.name);
              props.setrestriction_dt("SN")
            } else if (restriction_data === "SC") {
              props.setid_businesspartners(null);
              props.setid_pointsofsale(null);
              props.setrestriction_dt("SC")
              props.setid_shearch_dt("");
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Buscar..."}
              placeholder={id_shearch === "" ? "Buscar..." : id_shearch}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
              InputLabelProps={{ shrink: true }}

            />
          )}
        />
      </FormControl>
      

      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
            onClick={(e) => {
              if (restriction_data === "SN") {
                props.setid_businesspartners(null);
                props.setid_pointsofsale(null);
              }
              props.handle_get_sales()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
          <Button
            variant="contained"
            title='GENERAR GRÁFICA'
            sx={{ height: '35px', borderRadius: "15px", background: "orange !important", width: "50%",display:props.old_balance?"none":"inline-block"}}
            // onClick={(e) => props.handle_get_sales()}
            onClick={async () => {
              await CustomDialog(
                <GRAPH
                  red_data={props.red_data}
                  yello_data={props.yello_data_data}
                  green_data={props.green_data}
                ></GRAPH>,
                {
                  className: "modalGrap",
                  title: "Gráfica",
                  showCloseIcon: true,
                  isCanClose: false,
                }
              );
            }}
          >
            <BarChartIcon></BarChartIcon>
          </Button>
        </Box>
      </FormControl>


    </Box>
  );
};

export default SEARCH_FILTERS;
