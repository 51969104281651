import { get_job_opportunities } from '../../services/humanResources/humanResources'
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import AlertResponse from "../../main_components/alerts/alertResponse";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { OPERATIONS_JOB } from './tools/operations';
import { CustomDialog } from 'react-st-modal';
import CREATE from "../../imgComponents/create.png";
import "./jobsopp.css";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  MenuItem,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { Button} from '@mui/material';
import { SEARCH_FILTER_WEB_MOVIL } from '../../main_components/methods_v2/search_filter_web_movil';
import SearchIcon from '@mui/icons-material/Search';
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';

export const JOB_OPPORTUNITIES = () => {
 
    const [data, setData] = useState([]);
    const notificationSystemRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");
    const [modalT, setmodalT] = useState(false);
    const [enable, setenable] = useState(false);
   
    useEffect(() => {
      handlesubmit();
    }, [])

    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
    };
    
  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
    
    const handlesubmit = async() =>{
        try{
            const reft = await refresh_token();
            if(reft){
                try{
                    setData([]);
                    setIsLoading(true);
                    setmodalT(true);
                    const d = await get_job_opportunities(null, enable);
                    console.log(d);
                    setData(d?.data);
                    setIsLoading(false);
                    setmodalT(false);
                }catch(err){    
                    console.log(err);
                    setIsLoading(false);
                    setmodalT(false);
                }
            }
        }catch(err){
            console.log(err);
        }
    }

    
    
  const columns = useMemo(
    () => [
      {
        id: "title",
        header: "Título de Vacante",
        accessorKey: "title",
      },
      {
        id: "department",
        header: "Departamento",
        accessorKey: "department"
      },
      {
        id: "employment_type",
        accessorKey: "employment_type",
        header: "Tipo de Empleo"
      },
      {
        id: "salary_range",
        accessorKey: "salary_range",
        header: "Rango Salarial"
      },
      {
        id: "contact_info",
        accessorKey: "contact_info",
        header: "Información de Contacto"
      },
      {
        id: "application_deadline",
        accessorKey: "application_deadline",
        header: "Fecha Límite de Postulación"
      },
      {
        id: "enable",
        accessorKey: "enable",
        header: "Estado",
        accessorFn:(d)=>{
            return d?.enable===true?"Habilitado": "Deshabilitado"
        }
      },
      {
        id: "requests",
        header: "Solicitudes",
        accessorKey: "requests",
        accessorFn: (d)=>{
          return d?.requests ? d?.requests?.length : 0 
        },
        muiTableBodyCellProps:{
          align: "center"
        }
      }
    ],
    []
  );

  const update_identifiers =
[
  { title: 'string' },
  { department: 'string' },
  { employment_type: 'string' },
  { salary_range: 'string' },
  { contact_info: 'string' },
  { application_deadline: 'string'},
  { enable: 'string'}
];



const filters_custom = (
    <div className="custom-year-search">
      <FormControl required sx={{ width: 250 }}>
      <InputLabel id="demo-simple-select-required-label" style={{marginLeft: "12px", marginTop: "10px"}}>Estado</InputLabel>
      <Select
        labelId="demo-simple-select-required-label"
        id="demo-simple-select-required"
        value={enable}
        sx={{ height: '35px', m: 1.5}}
        label="Estado"
        onChange={(e) => setenable(e.target.value)}
      >
        <MenuItem key={0} value={true}>Habilitados/Deshabilitados</MenuItem>
        <MenuItem key={1} value={false}>Habilitados</MenuItem>
      </Select>
    </FormControl>
    <FormControl required sx={{ width: "auto" }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => handlesubmit()}
        ><SearchIcon></SearchIcon>
        </Button>
      </FormControl> 
    </div>
  );
    
const [minimenu, setminimenu]=useState(false);
const [openmini, setopenmini]=useState(false);

 useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
 
    return (
    <div style={{width:"95%", margin: "0 auto", position: "relative"}}>
        <BREADCRUMBS niveles={
            [
                { label: "CONSULTAR VACANTES", path: null },
            ]
        }
            cards_dashboard={true}
            total_rows={total_rows !== "" ? total_rows : 0}
            filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
            visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
            total={data?.length}
        ></BREADCRUMBS>
         <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={""}
            modalGeneral={false}
            modalT={modalT}
          ></MODAL_TABLE>
           <SEARCH_FILTER_WEB_MOVIL
            minimenu={minimenu} 
            setminimenu={setminimenu}
            openmini={openmini} 
            setopenmini={setopenmini}
            filterMovComponent={filters_custom}
            renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL>
        
        <div className='new_table_v2'>
        <   MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                        title="Registrar Vacante"
                        src={CREATE}
                        className=""
                        onClick={async () => {
                          const result = await CustomDialog(
                            <OPERATIONS_JOB
                              operation={"CREATE"}
                              setmodalT={setmodalT}
                              handlesubmit={handlesubmit}
                              data={data}
                              alertas={alertas}
                            />,
                            {
                              className: "custom-modal-2",
                              title: "Registrar Vacante",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                        }}
                      ></img>
                    </div>
                  ),
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => {
                if(minimenu===false){
                  return(
                  <>
                    {filters_custom}
                  </>
                  )
                }
              }}
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  row={row}
                  handlesubmit={handlesubmit}
                  refresh_token={refresh_token}
                  alertas={alertas}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Vacantes de Trabajo"}
                  update_identifiers={update_identifiers}
                  key={index}
                />
              )
              }
            />
           
        </div>
        

    </div>
  )
}
