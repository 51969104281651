import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/lupa.png";
import { DETAILS_SALES } from "./Details";
import { CustomDialog } from "react-st-modal";
import { RETURNS } from './returns';

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <DETAILS_SALES 
                            row={props?.row}
                        />,
                    {
                        className: "custom-modal-3",
                        title: "Detalles de Venta",
                        showCloseIcon: true,
                        isCanClose: false,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Detalles de Venta"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles de Venta
            </MenuItem>
            {
                props?.row?.original?.details_returns?.length>0?
                <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <RETURNS
                            row={props?.row}
                        />,
                    {
                        className: "custom-modal-3",
                        title: "Devoluciones / Bonificaciones",
                        showCloseIcon: true,
                        isCanClose: false,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Devoluciones / Bonificaciones"
                        className=""
                    ></img>
                </ListItemIcon>
                Devoluciones / Bonificaciones
            </MenuItem>:<></>
            }    
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
