import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@mui/material";
import GENERAL_FORM_MODULE from "../../../main_components/material_table/general_form_module";
import { useEffect, useMemo, useRef, useState } from 'react';
import { inf_waybill } from "./tools/inf_waybill";

export default function WAYBILL() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalT, setmodalT] = useState<boolean>(false);
    const [open_form, setopen_form] = useState(false);
    const [isNewEntry, setIsNewEntry] = useState(false);
    const [formData, setformData] = useState([]);   
    const [methodDelete, setMethodDelete] = useState(false);

    const [identifier_data, setidentifier_data] = useState  <any> (null);

    const update_identifiers = [
            { id: "string" },
        { description: "string" },
        { term: "String" },
    ];

    
    const handle_open_form = (new_value: any, isNew: boolean) => {
        setformData(new_value)
        setIsNewEntry(isNew);
        setopen_form(true);
        setMethodDelete(false)
    };

    const handle_close = () => {
        setopen_form(false);
    };


const handle_save_data = async (finalObject: any, method: any , identifier_data:any) => {
    console.log("Datos a procesar:", finalObject);
    console.log("True / Post, False / Put, Delete:", method);

      // Mostrar modal
    setmodalT(true);
    
    try {
        switch (method) {
            case true:
            console.log("Creando objeto...");
            // await post_pay_conditions(finalObject);
            console.log("Objeto creado con éxito");
            break;
    
            case false:
            console.log("Actualizando objeto...");
            // await put_pay_conditions(finalObject,identifier_data);
            console.log("Objeto actualizado con éxito");
            break;

            case "delete":
            console.log("Eliminando objeto...");
            // await delete_pay_conditions(identifier_data);
            console.log("Objeto eliminado con éxito");
            break;
    
            default:
            console.error("Método no reconocido");
        }
    } catch (error) {
        console.error("Error al procesar los datos:", error);
    } finally {
        // Cerrar modal y realizar otras acciones comunes
        setmodalT(false);
        handle_get_pay_conditions();
        handle_close();
        console.log("Operación finalizada.");
    }
};




const updated_form_data = (name: any, value: any,formData: any) => {
    console.log(!isNaN(Number(value)))
    console.log(name)
    console.log(value)

    const update_form = {
        ...formData,
        [name]: !isNaN(Number(value)) ? Number(value) : value, 
        description: name === 'term' ? (Number(value) === 0 ? 'Contado' : `CRÉDITO ${Number(value)} DÍA${Number(value) > 1 ? 'S' : ''}`) : formData.description,
    };
    return update_form;
  };

  const validateField = (name: string, value: any) => {
    var error = '';

    switch (name) {
        case 'term':
            if (value === undefined || value === null) {
                error = 'El campo es obligatorio';
            } else if (value < 0) {                     
                error = 'El valor debe ser un número positivo';
            }
            
            break;
        // Puedes agregar más casos de validación aquí
    }

    return error;
};


const handleProcessedFormat = (formData: any) => {
  const dataToSubmit = { ...formData };

  Object.keys(dataToSubmit).forEach((key) => {
    switch (key) {
      case 'date_paid':
        // Formatear la fecha a "YYYY-MM-DD"
        // dataToSubmit[key] = dayjs(dataToSubmit[key]).format('YYYY-MM-DD');
        break;

      case 'description':
        // Convertir la descripción a mayúsculas
        dataToSubmit[key] = dataToSubmit[key].toUpperCase();
        break;

      // Agrega más casos según las necesidades de tu formulario
      default:
        // No hacer nada si no es un campo que necesita procesamiento especial
        break;
    }
  });

  // Retornar el objeto modificado
  return dataToSubmit;
};

const handle_get_pay_conditions = async (event?: React.SyntheticEvent): Promise<void> => {

    };
    return (
        <Box sx={{ maxWidth: 1200, margin: "auto", p: 2 }}>
            <GENERAL_FORM_MODULE
                open={open_form}
                onClose={handle_close}
                data_form={inf_waybill}
                onSave={handle_save_data}
                isNew={isNewEntry}
                title_create="CREAR CONDICIÓN DE PAGO"
                title_update="ACTUALIZAR CONDICIONES DE PAGOS"
                validateField={validateField}  // Pasa la función correspondiente
                handleProcessedFormat={handleProcessedFormat}  // Función para manejar los cambios de formatos en caso de requerirlos
                FormData={FormData}  // Datos actuales del formulario
                original_data={formData}
                size={methodDelete?"30%":"380px"}
                method_delete={methodDelete}
                message_delete="¿Esta seguro de eliminar esta condición de pago?"
                updatedFormData={updated_form_data}
                identifier_data={identifier_data}
            
            >
                
            </GENERAL_FORM_MODULE>
            
        </Box>
    );
}
