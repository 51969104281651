import axios from "axios";
import config from "../../config";

const urlbp = `${config?.baseURLHR}`;

///////////////////// C O N D U C T O R E S ////////////////////

export const get_drivers = async () => {
  const url = `${urlbp}/od/drivers`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};  
export const delete_drivers = async (id) => {
  const url = `${urlbp}/od/drivers/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};  

export const post_drivers = async (data) => {
  const url = `${urlbp}/od/drivers`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};  

export const put_drivers = async (id, data) => {
  const url = `${urlbp}/od/drivers/${id}`;
  try {
    const response = await axios.put(url,data,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};  



/////////////////////// E M P L E A D O S ////////////////////////////

//consultar todos los empleados
export const get_employess = async () => {
  const url = `${urlbp}/od/employees`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//consyltar direccion de un empleado
export const get_employess_address = async (id) => {
  const url = `${urlbp}/od/employees/${id}/address`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//ELIMINAR EMPLEADO
export const delete_employess = async (id) => {
  const url = `${urlbp}/od/employees/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//Crear EMPLEADO
export const post_employess = async (data) => {
  const url = `${urlbp}/od/employees`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//actualizar EMPLEADO
export const put_employess = async (id, data) => {
  const url = `${urlbp}/od/employees/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


///////////////////////OPORTUNIDADES DE TRABAJO ////////////////////////////
export const get_job_opportunities = async (id, enable) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development`;
  try {
    const response = await axios.get(url, {
      params:{
        includesDisabled: enable
        
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_job_opportunities = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development`;
  try {
    const response = await axios.post(url,data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_job_opportunities = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development`;
  try {
    const response = await axios.put(url,data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_applicants = async (id) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/application`;
  try {
    const response = await axios.get(url, {
      params:{
        opportunity_id: id
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




export const put_applicants = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/application`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_application = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/application`;
  try {
    const response = await axios.post(url, data,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const upload_file = async (data) => {
  const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/document`;
  try {
    const response = await axios.post(url, data,{
      headers: {
      //   Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};