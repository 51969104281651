import React from 'react'
import { Button, FormControl, InputLabel, Select } from '@mui/material';
import {Autocomplete, Checkbox, ListItemText, MenuItem, TextField} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';

export const SEARCH_FILTERS = (props) => {

  const reset = () =>{
    props?.setmarkers(props?.markers2);
  }

  return (
    <div className='grid-real-sale-inputs'>
        <div className="">
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
                <DatePicker
                    sx={{ m: 1.5, width: "100%"}}
                    className='text-field-disabled'                       
                    label="Fecha"
                    value={props?.fecha}
                    onChange={(newValue) => props?.setfecha(newValue)}
                    locale="es"
                    format="DD/MM/YYYY"
                />
            </LocalizationProvider>
        </div>
        <div className=''>
            <FormControl required sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-required-label" style={{marginTop: "12px", marginLeft: "12px"}}>Ruta</InputLabel>
            <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={props?.idroute}
                fullWidth
                sx={{ height: '35px', m: 1.5}}
                label="Ruta"
                onChange={(e) => {
                    props?.setidroute(e.target.value);
                    const d = props?.dataosect?.filter(item => item.route == e.target.value?.toString());
                    props?.setdatasector(d);
                    props?.setidsec(0);
                  }}
            >
                {
                    props?.routedata?.data?.parametersList?.map((item, index)=>(
                    <MenuItem key={index} value={item?.route}>{item?.route}</MenuItem>
                   )) 
                }
            </Select>
        </FormControl>
        
        </div>
        <div className=''>
        <FormControl required sx={{ width: "100%" }}>
          <Button variant="contained"
            sx={{ height: '35px', m: 1.5 }}
            onClick={() => {
                props?.handleSubmit();
            }}
            className='button-sumary-hover'
          >
          <SearchIcon  titleAccess='Buscar'/>
          </Button>
        </FormControl> 
        </div>
        <div className=''>
        <FormControl required sx={{ width: "100%" }}>
          <Button variant="contained"
            sx={{ height: '35px', m: 1.5 }}
            onClick={() => {
                reset();
            }}
            className='button-close-filter'
          >
          <FilterAltOffIcon titleAccess='Quitar Filtros'></FilterAltOffIcon>
          </Button>
        </FormControl> 
        </div>
        <div className=''>
            <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-required-label" style={{marginTop: "12px", marginLeft: "12px"}}>Sector</InputLabel>
            <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={props?.idsec}
                fullWidth
                sx={{ height: '35px', m: 1.5}}
                label="Ruta"
                onChange={(e) => props?.setidsec(e.target.value)}
            >
                <MenuItem key={0} value={0}>Seleccione Sector</MenuItem>
                {
                    props?.datasector?.map((item, index)=>(
                    <MenuItem key={index} value={item?.id}>{item?.number_sector} - {item.name_sector}</MenuItem>
                   )) 
                }
            </Select>
        </FormControl>
        
        </div>
        <div className=''>
            <FormControl required sx={{ width: "100%" }}>
            <Button variant="contained"
                sx={{ height: '35px', m: 1.5 }}
                onClick={() => {
                    props?.handlesubsect();
                }}
                className='button-show_points_not_visited'
            >
            <DomainDisabledIcon titleAccess='Puntos de Venta sin Visitar'></DomainDisabledIcon>
            </Button>
            </FormControl> 
        </div>
        <div className='total-real-sales-timereal'>
            <p>Total Ventas {props?.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</p>
        </div>
    </div>
  )
}
