//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import TableContainer from "../../main_components/Table/TableContainer";
import { detele_discount, get_discounts } from "../../services/businesspartners/businesspartners";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../componentSociosNegocios/componentSectors/estilo.css";
import "../../components_cfdi/modal.css";
import "./discount.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import OPERATION_DISCOUNT from "./tools/op_discount";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const DISCOUNTS_V2 = () => {
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const notificationSystemRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");

  const update_identifiers =
    [
      { tiponamePointSaletype: 'string' },
      { codePointSale: 'string' },
      { namePointSale: 'String' },
      { business_partner: 'string' },
      { tag: 'string' },
      { discount: 'string' },
      { discount_order: 'string' },
      { enable: 'string' }
    ];

  function alertas(msj, status) {

    if (status == true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'tr',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'tr',
        autoDismiss: 60,
      });
    }
  }


  //proceso de consultar todos los descuentos
  const handleSubmit = async (query_filters_flag, query_filters_data) => {


    if (entrada === 0) {
      setmenssage("Buscando Descuentos...");
      setmodalT(true);
      entrada = entrada + 1;
    } else {
      setmodalT(true);
    }
    setData([]);
    setopen(true);
    setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            if (query_filters_flag) {
              const d = await get_discounts();
              setquery_filters(query_filters_data);
            } else {
              var prueba = await get_discounts();
              var ndt = update_null_identifiers(prueba?.data?.discounts, update_identifiers);
              setData(ndt);
              setopen(false);
              setmodalGeneral(false);
              setmodalT(false);
              setIsLoading(false);
            }
          } catch (err) {
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (error) {
      console.log(error);
    }
  };

  //Funcion de alertas personalizadas
  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }


  const columns = useMemo(
    () => [
      {
        id: "tiponamePointSaletype",
        header: "Tipo de Descuento",
        accessorKey: 'tiponamePointSaletype',
        accessorFn: (d) => {
          return d.namePointSale === "" ?
            "Socio de Negocios" : "Punto de Venta"
        },
        muiTableBodyCellProps: {
          align: 'justify',
        },
        size: 150,
      },
      {
        id: "codePointSale",
        header: "Código de Punto de Venta",
        accessorKey: "codePointSale",
        muiTableBodyCellProps: {
          align: 'center'
        },
        size: 250,
      },
      {
        id: 'namePointSale',
        header: "Punto de Venta",
        accessorKey: 'namePointSale',
        accessorFn: (d) => {
          return d.namePointSale === "" ? "NO APLICA" : d.namePointSale
        },
        size: 150,
      },
      {
        id: 'business_partner',
        header: "Socio de Negocios",
        accessorKey: 'business_partner',
        accessorFn: (d) => {
          return d.business_partner === "" ? "NO APLICA" : d.business_partner
        },
        size: 150,
      },
      {
        id: 'tag',
        header: "Etiqueta",
        accessorKey: "tag",
        muiTableBodyCellProps: {
          align: 'left'
        },
        size: 150,
      },
      {
        id: 'discount',
        header: "Descuento %",
        accessorKey: "discount",
        size: 150,
      },
      {
        id: 'discount_order',
        header: "Orden",
        accessorKey: "discount_order",
        size: 150,
      },
      {
        id: 'enable',
        header: "Disponible",
        accessorKey: 'enable',
        accessorFn: (d) => {
          return d.enable === true ? "Disponible" : "No Disponible"
        },
        size: 150,
      },

    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])


  return (
    <>
      <div className="">
        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
          open={true}
          message={menssage}
          modalGeneral={modalGeneral}
          modalT={modalT}
        ></MODAL_TABLE>
        <BREADCRUMBS niveles={
          [
            { label: "CONSULTAR DESCUENTOS", path: null },
          ]
        }
          cards_dashboard={true}
          total_rows={total_rows !== "" ? total_rows : 0}
          filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
          visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
          total={data?.length}
        ></BREADCRUMBS>
        <div className="new_table_v2">
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            onStateChange={handleStateChange}
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <img
                      title="Registrar Descuento"
                      src={CREATE}
                      style={{ "cursor": "pointer" }}
                      className=""
                      onClick={async () => {
                        const result = await CustomDialog(
                          <OPERATION_DISCOUNT
                            operation={"CREATE"}
                            setmodalT={setmodalT}
                            handleSubmit={handleSubmit}
                            alertas={alertas}
                          />,
                          {
                            className: "custom-modal",
                            title: "Registrar Descuento",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                    ></img>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                setmenssage={setmenssage}
                setmodalGeneral={setmodalGeneral}
                row={row}
                handleSubmit={handleSubmit}
                setreconsult={setreconsult}
                refresh_token={refresh_token}
                alertas={alertas}
              />
            ]}
            renderToolbarInternalActions={({ table, index }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Consultar Descuentos"}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
                query_filters={query_filters}
                query_filters_dt={query_filters_dt}
                handle_get_consult={handleSubmit}
                key={index}
                handle_success_filter={handle_success_filter}
                share_filter={true}
                total_rows={total_rows !== "" ? total_rows : 0}
              />
            )
            }
          />
        </div>
      </div>
    </>
  );
}

export default DISCOUNTS_V2;
