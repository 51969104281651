import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TableChartIcon from '@mui/icons-material/TableChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {Button} from '@mui/material';
import {Checkbox, ListItemText, MenuItem, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { UPLOAD_CSV } from './uploadcsv';
import { CustomDialog } from 'react-st-modal';
import dat from "./type.json";

export const SEACRH_FILTER = (props) => {
  const actualizarcsv = async()=>{
    await CustomDialog(
      <div>
        <UPLOAD_CSV  
          datapoints={props?.datapoints}
          join_points={props?.join_points}
          setprocesstwo={props?.setprocesstwo}
          processTwo={props?.processTwo}
        />
      </div>,
      {
        className: "custom-modal-upload-csv",
        title: "Cargar Puntos de Venta",
        showCloseIcon: true,
        isCanClose: false,
      })
  }
  
  return (
    <div className="custom-menu-report-route-89">
          <FormControl required sx={{width: "auto"}}>
            <TextField
              label="Tamaño"
              value={props?.size}
              type={"number"}
              required
              sx={{ width: "100%" }}
              onChange={(event) => {
                const value = event.target.value;
                if (value === '' || (/^[1-9]\d*$/).test(value)) {
                  props?.setsize(value);
                }
              }}
              inputProps={{ min: 1, pattern: "[0-9]*" }}
              InputLabelProps={{ shrink: true }}
          ></TextField>
          </FormControl>
          <FormControl required sx={{width: "auto"}}>
            <TextField
              label="Días laborables"
              value={props?.works_days}
              type={"number"}
              required
              sx={{ width: "100%" }}
              onChange={(event) => {
                const value = event.target.value;
                if (value === '' || (value >= 1 && value <= 7)) {
                  props?.setworks_days(value);
                }
              }}
              inputProps={{ min: 1, max: 7, pattern: "[1-7]*" }}
              InputLabelProps={{ shrink: true }}
          ></TextField>
          </FormControl>
          <FormControl required sx={{width: "auto"}}>
            <TextField
              label="Orden Agrupamiento"
              value={props?.cardinal}
              required
              sx={{ width: "100%" }}
              onChange={(event) => {
                const input = event.target.value.toLowerCase();
                const validInput = input.replace(/[^nseo]/g, '');
                if (validInput.length <= 4) {
                  props?.setcardinal(validInput); 
                }
              }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 4 }}
          ></TextField>
          </FormControl>
      <FormControl required sx={{ width: "auto" }}>
          <InputLabel id="demo-simple-select-required-label">Referencia Fricongelados</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={props?.points_fn_flag}
            sx={{ height: '35px'}}
            label="Referencia Fricongelados"
            onChange={(e) => props?.setpoints_fn_flag(e.target.value)}
          >
            <MenuItem key={0} value={true}>SI</MenuItem>
            <MenuItem key={1} value={false}>NO</MenuItem>
          </Select>
      </FormControl>
      <FormControl required sx={{ width: "auto" }}>
          <InputLabel id="demo-simple-select-required-label">Tipo</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            multiple
            value={props?.type_pos}
            sx={{ height: '35px' }}
            label="Rutas"
            onChange={(e) => {
              const selectedValues = e.target.value;
              if (selectedValues.includes("TODAS")) {
                props?.settype_pos(["TODAS"]);
              } 
              else {
                props?.settype_pos(selectedValues.filter(value => value !== "TODAS"));
              }
            }}renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : selected}
        >
            <MenuItem key={0} value={"TODAS"}>
              <Checkbox checked={props?.type_pos?.includes("TODAS")} />
              <ListItemText primary="TODAS" />
            </MenuItem>
            {
              dat?.map((item, index) => (
                <MenuItem key={index + 1} value={item?.value} disabled={props?.type_pos?.includes("TODAS")}>
                  <Checkbox checked={props?.type_pos?.includes(item?.value)} />
                  <ListItemText primary={item?.value} />
                </MenuItem>
              ))
            }
          </Select>
      </FormControl> 

      <FormControl required sx={{ width: "auto" }}>
          <InputLabel id="demo-simple-select-required-label">Rutas</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            multiple
            value={props?.routes}
            sx={{ height: '35px' }}
            label="Rutas"
            onChange={(e) => {
              const selectedValues = e.target.value;
              if (selectedValues.includes("TODAS")) {
                props?.setroutes(["TODAS"]);
              } 
              else {
                props?.setroutes(selectedValues.filter(value => value !== "TODAS"));
              }
            }}renderValue={(selected) => { 
              return Array.isArray(selected) 
              ? selected
                  .map(id => {
                    const route = props.dataroute.find(route => route.idRoute === id);
                    return route ? route.route : id;
                  })
                  .join(', ')
              : selected;
            }}
        >
            <MenuItem key={0} value={"TODAS"}>
              <Checkbox checked={props?.routes.includes("TODAS")} />
              <ListItemText primary="TODAS" />
            </MenuItem>
            {
              props?.dataroute?.map((item, index) => (
                <MenuItem key={index + 1} value={item?.idRoute} disabled={props?.routes.includes("TODAS")}>
                  <Checkbox checked={props?.routes.includes(item?.idRoute)} />
                  <ListItemText primary={item?.route}/>
                </MenuItem>
              ))
            }
          </Select>
      </FormControl>       
      <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained"
            sx={{ height: '35px' }}
            onClick={(e) => props?.handlesubmit()}
          >
           <SearchIcon titleAccess='Buscar'/>
          </Button>
        </FormControl> 
        <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained" onClick={()=>{
            props?.setshowmap(!props?.showmap);
            props?.setshowsumm(false);
          }}
            sx={{ height: '35px', backgroundColor: "purple"}}
            className='button-showmapa-hover'
          >
            {
              props?.showmap===true?
              <TableChartIcon titleAccess="Tabla"></TableChartIcon>:
              <FmdGoodIcon titleAccess="Mapa"></FmdGoodIcon>
            }
          </Button>
        </FormControl> 
        <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained"
            sx={{ height: '35px' }}
            onClick={(e) => {
              props?.setdata(props?.dataux);
              props?.join_points(props?.dataux, true);
              props?.setlistselect([]);
            }}
            className='button-filternon-hover'
          >
           <FilterAltOffIcon titleAccess='Quitar Filtros'></FilterAltOffIcon>
          </Button>
        </FormControl> 
        <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained"
            sx={{ height: '35px' }}
            onClick={(e) => {
              props?.resetmap();
              props?.setlistselect([]);
            }}
            className='button-reset-hover'
          >
           <RotateLeftIcon titleAccess='Resetear Mapa'></RotateLeftIcon>
          </Button>
        </FormControl> 

        <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained"
            sx={{ height: '35px' }}
            onClick={(e) => props?.save_route()}
            className='button-save-hover'
            disabled={props?.processtwo}
          >
           <AccountTreeIcon titleAccess='Subdivir'></AccountTreeIcon>
          </Button>
        </FormControl> 
        <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained"
            sx={{ height: '35px' }}
            onClick={(e) => {
              props?.setshowsumm(true);
              props?.setshowmap(false);
            }}
            className='button-sumary-hover'
          >
           <MonetizationOnIcon titleAccess='Sumaria'></MonetizationOnIcon>
          </Button>
        </FormControl> 
        <FormControl required sx={{ width: "auto" }}>
          <Button variant="contained"
            sx={{ height: '35px' }}
            onClick={(e) => {
              actualizarcsv();  
            }}
            className='button-upload-csv-hover'
          >
           <UploadFileIcon titleAccess='Subir CSV'></UploadFileIcon>
          </Button>
        </FormControl> 
    </div>
  )
}
