import { useEffect, useMemo, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { jsPDF } from 'jspdf';
import {
  Box,
  MenuItem,
  IconButton,
} from '@mui/material';
import { CustomDialog } from "react-st-modal";
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import ADD_QR_V2 from "../../../../imgComponents/iconos/add_qr_v2.png";
import CREATE from "../../../../imgComponents/create.png";
import ICON_QR from "../../../../imgComponents/icons/qr_icon.png";
import Set_bussines_p from '../../tools/set_bussines_p';
import UPDATE_BUSSINES_P from '../../../../form_business_partner/update_bussines_p.jsx';

const SPECIAL_ACTIONS = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handle_click = (event) => { setAnchorEl(event.currentTarget) };

  const handle_close = () => { setAnchorEl(null) };



  return (
    <Box sx={{ display: 'flex', gap: '0.1rem', alignItems: 'flex-end' }}>

      <img
        src={ADD_QR_V2}
        className="icon_add_qr"
        title='Crear/Actualizar Proveedor'
        onClick={handle_click}
        key={"add_qr"}
      >
      </img>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handle_close}
        TransitionComponent={Fade}
      >
        <MenuItem
          key={"update_bp"}
          onClick={async () => {
            handle_close();

            const result = await CustomDialog(
              <UPDATE_BUSSINES_P/>,
              {
                className: "custom-modal-2",
                title: "Actualizar Proveedor",
                showCloseIcon: true,
                isCanClose: false,
              }
            );
            // eslint-disable-next-line no-lone-blocks
            if (result) {
              props.handle_business_partner();
            }
          }}
        ><img
          src={ICON_QR}
          className="icon_add_qr"


        >
          </img>ACTUALIZAR PROVEEDOR POR QR</MenuItem>

        <MenuItem
          key={"create_bp"}
          onClick={async () => {
            handle_close();

            const result = await CustomDialog(
              <Set_bussines_p
                actualiza={false} 
                method={"CREATE"}
                modul={"PROVEEDOR"}
                supplierdata={true}
                code={""}
                business_partner={""}
                tax_regime={""}
                name={""}
                first_last_name={""}
                second_last_name={""}
                balance={0}
                credit_days={0}
                business_partner_type={"PROVEEDOR"}
                capital_regime={""}
                partner_type={""}
                person_type={""}
                cfdi_use={""}
                phone={""}
                metodo_pago={""}
                paymethod={"01"}
                allow_invoicing={""}
                street={""}
                outdoor_number={""}
                inside_number={""}
                neighborhood={""}
                zip_code={""}
                city={""}
                state={""}
                email={""}
                country={"MÉXICO"}
                reference={""}
                address_type={""}
                subsidiary={""}
                tax_residence={""}
                rfc={""}
                num_reg_id_trib={""}
                town={""}
                partner_type_data={"PROPIO"}
                uso_cfdi_data={"G01"}
                state_data={"VERACRUZ DE IGNACIO DE LA LLAVE"}
                address_type_data={"FISCAL"}
                tax_regime_data={"601"}
                subsidiary_data={"MATRIZ"}
                metodo_pago_data={"PUE"}
                account_data={""}
                id_accounting_account_fk={null}

                ></Set_bussines_p>,
              {
                className: "custom-modal-2",
                title: "Crear Proveedor",
                showCloseIcon: true,
                isCanClose: false,
              }

            );
            // eslint-disable-next-line no-lone-blocks
            if (result) {
              props.handle_business_partner();
            }
          }}
        ><img
          src={CREATE}
          className="icon_add_qr"
        >
          </img>CREAR PROVEEDOR</MenuItem>




      </Menu>



    </Box >

  );
}
export default SPECIAL_ACTIONS;
