import React,{useState,useEffect} from 'react';
import { Card,Button, Grid, TextField, MenuItem, InputLabel,Box,styled,IconButton,Typography, } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { ValidationTextField } from '../lable/labe';
import { Props } from './interface/interface';
import CircularProgress from '@mui/material/CircularProgress'; 
import CloseIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
// Estilos personalizados (sin cambios)




const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      width: 'auto',
      height: '60px',
      '& fieldset': {
        borderColor: '#e0e0e0',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: '#6200ee',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6200ee',
      },
    },
    '& .MuiInputLabel-root': {
      color: 'black',
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '4px',
      transform: 'translate(12px, -13px) scale(1)',
      background:'white'
    },
    '& .MuiInputBase-input': {
      padding: '15px 14px',
      '&::placeholder': {
        color: '#bdbdbd',
        opacity: 1,
      },
    },
  }));
  

  const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        height: '55px',
        '& fieldset': {
            borderColor: '#e0e0e0',
        },
        '&:hover fieldset': {
            borderColor: '#6200ee',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6200ee',
        },
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(14px, 20px) scale(1)',
    },
    '& .MuiSelect-select': {
        padding: '17px 14px',
        fontSize: '20px', // Tamaño de la fuente ajustado aquí
    },
    // Ajustando también los items del menú para que coincidan
    '& .MuiMenuItem-root': {
        fontSize: '36px', // Asegúrate de que esto sea legible y acorde a tu diseño
    }
}));


const StyledDatePicker = styled(DatePicker)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        height: '55px',
        '& fieldset': {
            borderColor: '#e0e0e0',
        },
        '&:hover fieldset': {
            borderColor: '#6200ee',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6200ee',
        },
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(14px, 20px) scale(1)',
    },
    '& .MuiInputBase-input': {
        padding: '17px 14px',
    },
});

const StyledButton = styled(Button)({
    width: '100%',
    height: '55px',
    textTransform: 'none',
    padding: '0px 16px',
    fontSize:"20px"
});


const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    color: 'black',        // Color negro
    fontWeight: 'bold',    // Fuente en negrita
    fontSize: '22px',      // Tamaño de fuente 14px
    background:'white'
   

}));

// El resto del código permanece igual.



const GENERAL_FORM_MODULE: React.FC<Props> = (props) => {
    const { open, onClose, isNew, title_create, title_update, data_form, FormData,original_data,size,method_delete,message_delete,identifier_data, handleProcessedFormat, onSave, validateField,updatedFormData, } = props;
    const [formData, setFormData] = useState<typeof FormData>(original_data);
    const [loading, setLoading] = useState(false); // Simulación de carga

    const [errors, setErrors] = useState<Record<string, string>>({});


    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | Dayjs | null,
        accessor?: string
    ) => {
        if (dayjs.isDayjs(e) || e === null) {
          // Caso para el DatePicker
            const name = accessor as string;
            const value = e ? e.format('YYYY-MM-DD') : ''; // Puedes ajustar el formato si lo necesitas
            setFormData(updatedFormData(name, value, formData));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        } else {
          // Caso para los demás eventos
            const { name, value } = e.target;
            setFormData(updatedFormData(name, value, formData));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    const handleSubmit = () => {
        let validationErrors: Record<string, string> = {};
    
        if (formData && typeof formData === 'object') {
            Object.keys(formData).forEach(key => {
                const error = validateField(key, Number(formData[key]));
                if (error) {
                    validationErrors[key] = error;
                }
            });
        }
    
        if (Object.keys(validationErrors).length > 0) {
            console.log(validationErrors);
            setErrors(validationErrors);
        } else {
            const processedData = handleProcessedFormat(formData);

            console.log(processedData);
            console.log(isNew);
            console.log(identifier_data);

            onSave(processedData, isNew, identifier_data);
        }
    };

    const handle_delete = () => {
            onSave({}, 'delete', identifier_data);
    };


    useEffect(() => {
        if (open) {
            setFormData(original_data);
        }
    }, [open, original_data]);

    return (


        <Card sx={{ maxWidth: 1200, margin: "auto",}}>
            <Typography variant="h4" gutterBottom>
                Carta Porte
            </Typography>
            
            <Box sx={{width:'95%', margin: '0 auto',marginBottom:'30px'}}>
            <Grid container spacing={1} columns={30}>
                        {data_form.map((field) => (
                            <Grid item xs={30} sm={field.sm} style={{ paddingTop: "22px" }} key={field.accessor}>
                                {field.type === "date" ? 
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StyledDatePicker
                                            label={field.label}
                                            value={formData?.[field.accessor]}
                                            onChange={(newValue) => {
                                                // Verificar que newValue sea de tipo Dayjs o null
                                                if (dayjs.isDayjs(newValue) || newValue === null) {
                                                    handleChange(newValue, field.accessor);
                                                }
                                            }}
                                            format="DD/MM/YYYY" 
                                            slotProps={{
                                                textField: {
                                                fullWidth: true,
                                                error: !!errors?.[field.accessor],
                                                helperText: errors?.[field.accessor],
                                                }
                                            }}
                                            />
                                    </LocalizationProvider>
                                    : field.type === "select" ?
                                        <FormControl sx={{ width: '100%' }}>
                                        <StyledInputLabel id="demo-simple-select-required-label">{field.name_select}</StyledInputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={formData?.[field.accessor]}
                                            name={field.accessor}
                                            onChange={(e) => handleChange(e)} 
                                            input={<StyledSelect />}
                                            >
                                            {loading && (
                                                <MenuItem disabled>
                                                    <CircularProgress size={20} thickness={4} />
                                                </MenuItem>
                                            )}
                                            {!loading && field.data_select.map((select_option: any, index: number) => (
                                                <MenuItem key={`${select_option.id}-${index}`} value={select_option.value}>
                                                    {select_option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
            
                                    : field.type === "autocomplete" ?
                                        <div></div> 
                                    :
                                    field.type === "checkbox" ?
                                        <div></div> 
                                    :
                                        field.type === "button" ?
                                        <StyledButton variant="contained" onClick={()=>{}} sx={{ backgroundColor: '#1976d2' }}>
                                            {field?.label}
                                        </StyledButton>
                                    :
                                    <StyledTextField
                                        label={field.label}
                                        required
                                        placeholder={field.label}
                                        fullWidth
                                        key={field.accessor}
                                        variant="outlined"
                                        name={field.accessor}
                                        value={formData?.[field.accessor]||field.dfl}
                                        type={field.type || "text"}
                                        onChange={(e) => handleChange(e)} 
                                        error={!!errors?.[field.accessor]}
                                        helperText={errors?.[field.accessor]}
                                        // multiline
                                        // maxRows={4}
                                        // variant="filled"
                                    />
                                }
                            </Grid>
                        ))}
                    </Grid>
                    </Box>
                    </Card>

                    

    );
};

export default GENERAL_FORM_MODULE;


