import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomDialog } from "react-st-modal";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { pagination_v2 } from '../../../main_components/pagination/pagination_v2';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import GRAPH from "./pie_chart_with_customized_label"
import { get_routes } from '../../../services/cashclosing/cashclosing';
import { get_types_of_sales } from '../../../services/configuration/configuration';
import { ValidationTextField } from '../../../main_components/lable/labe';
function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
const SEARCH_FILTERS = (props) => {
  const [businesspartners_data, setbusinesspartners_data] = useState([]);
  const [pointsofsale_data, setpointsofsale_data] = useState([]);
  const [routes, setroutes] = useState([]);
  const [type_sales_data, settype_sales_data] = useState([]);
  const [options, setOptions] = useState([]);
  const [restriction_data, setrestriction] = useState(props.restriction_dt);
  const [route_select, setroute_select] = useState(props.name_route);
  const [typer_sale_select, settyper_sale_select] = useState(props.id_sale_type_fk);
  const [id_shearch, setid_shearch] = useState(props.id_shearch_dt);
  const [returns, setreturns] = useState(props.sale_returns);
  const [open, setOpen] = useState(false);

  const loading = open && options?.length === 0;


  useEffect(() => {
    setid_shearch(props.id_shearch_dt)
    setrestriction(props.restriction_dt);
    setreturns(props.sale_returns)
  }, [props.id_shearch_dt, props.restriction_dt,props.sale_returns])


  const handle_businesspartners = async (a, b) => {
    try {
      try {
        var dt = await pagination_v2(5, "aux1", null, null, null, null);
        var dta = dt.map((d) => { return { name: d.business_partner, id: d.id } });
        const uniqueIdsSet = new Set(dta.map(item => item.name));
        const uniqueArray = [...uniqueIdsSet].map(uniqueId => {
          return dta.find(item => item.name === uniqueId);
        });
        setbusinesspartners_data(uniqueArray);
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setbusinesspartners_data([]);
    }
  };

  const handle_pointsofsale = async (a, b) => {
    try {
      try {
        var dt = await pagination_v2(5, "aux2", null, null, null, null);
        setpointsofsale_data(dt);
        var dta = dt.map((d) => { return { name: d.name, id: d.id } });
        const uniqueIdsSet = new Set(dta.map(item => item.name));
        const uniqueArray = [...uniqueIdsSet].map(uniqueId => {
          return dta.find(item => item.name === uniqueId);
        });
        setpointsofsale_data(uniqueArray);

      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setpointsofsale_data([]);
    }
  };

  const handle_get_routes = async (a, b) => {
    try {
      try {
        const dt = await get_routes();
        const data = dt.data;
        var dtAdd = {
          route: "TODAS",
        };
        data.push(dtAdd);
        console.log(data.map(d => { return { route: d.route } }));
        setroutes(data.map(d => { return { route: d.route } }));

      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      setroutes([]);
    }
  };

  const handle_get_types_of_sales = async (a, b) => {
    try {
      try {
        const dt = await get_types_of_sales();
        const data = dt.data.sale_types?.map((d) => { return { id: d.id, type: d.type } });
        var dtAdd = {
          id: "TODAS",
          type: "TODAS",
        };
        data.push(dtAdd);
        settype_sales_data(data.filter((d) => d.type !== "DEVOLUCION" && d.type !== "BONIFICACION"));

      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (err) {
      settype_sales_data([]);
    }
  };

  useEffect(() => {
    handle_get_routes();
    handle_get_types_of_sales();
    handle_businesspartners();
    handle_pointsofsale();
  }, []);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        setOptions([...restriction_data === "SC" ? [] : restriction_data === "SN" ? businesspartners_data : pointsofsale_data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Inicial"
          value={props.fi}
          format='DD/MM/YYYY'
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Final"
          format='DD/MM/YYYY'
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>
      <FormControl sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Ruta</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={route_select}
          label="Ruta"
          sx={{ height: '35px' }}
          onChange={(e) => {
            setroute_select(e.target.value);
            props.setname_route(e.target.value)
          }}
        >
          {routes.map((route) => (
            <MenuItem key={route.route} value={route.route}>
              {route.route}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Tipo de Venta</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          label="Tipo de Venta"
          value={typer_sale_select}
          sx={{ height: '35px' }}
          onChange={(e) => {
            settyper_sale_select(e.target.value);
            props.setid_sale_type_fk(e.target.value);

          }}
        >
          {type_sales_data.map((sale) => (
            <MenuItem key={sale.id} value={sale.id}>
              {sale.type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Criterio</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          label="Criterio"
          value={restriction_data}
          sx={{ height: '35px' }}
          onChange={(e) => {
            setrestriction(e.target.value);
            if (e.target.value === "SC") {
              props.setid_shearch_dt("");
              props.setid_businesspartners(null);
              props.setid_pointsofsale(null);
              props.setrestriction_dt("SC")
              props.setname_freezer(null)
              props.setname_ticket("")
            }
          }}
        >
          <MenuItem value={"SN"}>SOCIO DE NEGOCIOS</MenuItem>
          <MenuItem value={"PDV"}>PUNTO DE VENTA</MenuItem>
          <MenuItem value={"CG"}>CONGELADOR</MenuItem>
          <MenuItem value={"TK"}>TICKET</MenuItem>
          <MenuItem value={"SC"}>S/C</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: 200, display: restriction_data === "CG" ? "inline-block" : "none" }}>
        <ValidationTextField
          label="Número Económico"
          placeholder="Número Económico"
          variant="outlined"
          id="validation-outlined-input"
          sx={{ m: .3, width: "100%" }}
          name="town"
          InputLabelProps={{
            shrink: true,
          }}
          value={id_shearch}
          onChange={(event) => {
            props.setid_businesspartners(null);
            props.setid_pointsofsale(null);
            props.setid_shearch_dt(event?.target?.value);
            props.setrestriction_dt("CG")
            props.setname_freezer(event?.target?.value)
            props.setname_ticket("")
          }} />
      </FormControl>
      <FormControl sx={{ width: 200, display: restriction_data === "TK" ? "inline-block" : "none" }}>
        <ValidationTextField
          label="Ticket"
          placeholder="Ticket"
          variant="outlined"
          id="validation-outlined-input"
          sx={{ m: .3, width: "100%" }}
          name="town"
          InputLabelProps={{
            shrink: true,
          }}
          value={id_shearch}
          onChange={(event) => {
            props.setid_businesspartners(null);
            props.setid_pointsofsale(null);
            props.setid_shearch_dt(event?.target?.value);
            props.setrestriction_dt("TK")
            props.setname_freezer(null)
            props.setname_ticket(event?.target?.value)
          }} />
      </FormControl>
      <FormControl sx={{ width: 200, height: 25, display: restriction_data === "SC" ? "none" : restriction_data === "CG" ? "none" : restriction_data === "TK" ? "none" : "inline-block" }} >
        <Autocomplete
          id="asynchronous-demo"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(event, value) => {
            if (restriction_data === "SN") {
              props.setid_businesspartners(value?.id);
              props.setid_pointsofsale(null);
              props.setid_shearch_dt(value?.name);
              props.setrestriction_dt("SN")
              props.setname_freezer(null)
              props.setname_ticket("")
            } else if (restriction_data === "PDV") {
              props.setid_businesspartners(null);
              props.setid_pointsofsale(value?.id);
              props.setid_shearch_dt(value?.name);
              props.setrestriction_dt("PDV")
              props.setname_freezer(null)
              props.setname_ticket("")
            } else if (restriction_data === "SC") {
              props.setid_businesspartners(null);
              props.setid_pointsofsale(null);
              props.setrestriction_dt("SC")
              props.setid_shearch_dt("");
              props.setname_freezer(null)
              props.setname_ticket("")
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Buscar..."}
              placeholder={id_shearch === "" ? "Buscar..." : id_shearch}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
              InputLabelProps={{ shrink: true }}

            />
          )}
        />
      </FormControl>

      <FormControl sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Devolución</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={returns}
          label="Devolución"
          sx={{ height: '35px' }}
          onChange={(e) => {
            setreturns(e.target.value)
            props.setsale_returns(e.target.value);
          }}
        >
          <MenuItem value={1}>SI</MenuItem>
          <MenuItem value={0}>NO</MenuItem>
        </Select>
      </FormControl>
      
      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
            onClick={(e) => {
              props.handle_get_sales()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>


    </Box>
  );
};

export default SEARCH_FILTERS;
