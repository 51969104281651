//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import Rutas from "../../dataDahsboard/routes.json";
import { refresh_token } from "../../main_components/tokens/tokenrefresh.jsx";

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
//IMAGENES/ICONOS
import { no_sales } from "../../services/reports/dashboard";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "../sales/tools/render_row_action_menuItems";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardNoSale_v2 = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");

  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const [selectRutas, setselectRutas] = useState("all");

  
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const[clien, setclien]=useState([]);
  const[conv, serconv]=useState([]);
  const[sinv, setsinv]=useState([]);
  const[cong, setcong]=useState([]);


  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando...");
    setData([]);
    
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await no_sales(fi, ff, selectRutas);
            const data = d=== null? [] : d.data;
            const data2 = (data.filter(dash=>dash.customer!=="Grand Total"));
            
            setclien(data2?.map(obj=>obj.customers).filter(d => d !== null));
            serconv(data2?.map(obj=>obj.withsale).filter(d => d !== null));
            setsinv(data2?.map(obj=>obj.withoutsale).filter(d => d !== null));
            setcong(data2?.map(obj=>obj.freezers).filter(d => d !== null));
            setData(data2);
            setOpen(false);
            setMenssage("");
          }catch(err){
            console.log("error", err);
            setData([]);
            setOpen(false);
            setMenssage("");
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };


  const columns = useMemo(
    () => [
      { 
        id:"customer",
        header: "Clasificación",
        accessorKey: "customer",
        muiTableBodyCellProps: {
          align: 'right',
        },
        size: 300,
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "customers",
        header: "Clientes",
        size: 220,
        accessorKey:"customers",
        muiTableBodyCellProps: {
           align: 'right',
         },
         
         Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.customers)
          return (
            <div>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(clien)}</div>
            </div>
          )
        }, 
      },
      {
        id: "withsale",
        header: "Con venta",
        accessorKey: "withsale",
        size: 220,
        muiTableBodyCellProps: {
          align: 'right',
        },
        
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.withsale)
          return (
            <div>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(conv)}</div>
            </div>
          )
        }, 
      },
      {
        id: "withoutsale",
        header: "Sin venta",
        size: 220,
        accessorKey: "withoutsale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.withoutsale)
          return (
            <div>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(sinv)}</div>
            </div>
          )
        },
      },
      { 
        id: "freezers",
        header: "Congeladores",
        accessorKey: "freezers",
        size: 200,
        muiTableBodyCellProps: {
          align: 'right',
        },

         Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.freezers)
          return (
            <div>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(cong)}</div>
            </div>
          )
        },
      },
      
    ],
    [clien, conv, sinv, cong]
  );

  const update_identifiers =
  [
    { customer: 'string' },
    { customers: 'number' },
    { withsale: 'number' },
    { withoutsale: 'number' },
    { freezers: 'number' }
  ];

  return (
    <div >
      <div>
      <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
        </Modal>
      </div>

      
      

      <div className="new_table_v2">
        
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 80
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderTopToolbarCustomActions={({ }) => (
<div className="gridproductonewdash">
        <div className="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-3 color-5 topSelect">
          <TextField
            select
            label="Rutas"
            value={selectRutas}
            sx={{ width: "100%" }}
            onChange={handleRutas}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {Rutas.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </TextField>
        </div>

        <div className="expen-4 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>
        )}
         renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={false}
              setmenssage={""}
              setmodalGeneral={false}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              datei={fi}
              datef={ff}
              route={selectRutas}
              nosale={true}
            />
          ]}
        renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Puntos de Venta sin Venta"}
             update_identifiers={update_identifiers}
           />
         )}
      />
      </div>
    </div>
  );
};

export default NewDashboardNoSale_v2;
