import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import { OPERATION_COMPETITION } from "./operation";
import { DETAILS_TICKETS } from "./details_tickets";

const RENDER_ROW_ACTION_MENU_ITEMS_DETAILS = (props) => {
    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <DETAILS_TICKETS
                            row={props?.row} 
                            handlesubmit={props?.handlesubmit} 
                            alertas={props?.alertas} 
                            setmodalT={props.setmodalT}
                            id={props?.id}
                        />,
                    {
                        className: "custom-modal",
                        title: "DETALLES DE TICKETS",
                        showCloseIcon: true,
                    });} 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Detalles de Tickets"
                        className=""
                    ></img>
                </ListItemIcon>
                Detalles de Tickets
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS_DETAILS;
